import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';

@Component({
  selector: 'app-ntm-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.css']
})

export class AlertConfirmComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  mensagem: any;
  status: any;
  color: any;

  alert_type_1: any = false;
  alert_type_2: any = false;

  dataToDelete: any;
  dialogMessage: string;
  constructor(
    public dialogRef: MatDialogRef<AlertConfirmComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
  }

  ngOnInit() {
    if(this.alert_type_2){
      setTimeout(() => {
        this.dialogRef.close();
      }, 3000);
    }
  }

  onDialogClose = () => {
    this.dialogRef.close();
  }
}
