<app-modal-header [hasClose]='true' [title]="'CADASTRAR LOCAL'" (handleClose)="close()"></app-modal-header>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading" style="width: inherit;"></mat-progress-bar>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="schoolLocationForm" (ngSubmit)="addExamLocation()" style="padding: 0;">

    <div id="text" class="primary-text">
      Preencha o local de prova e clique em CADASTRAR.
    </div>

    <div class="flex">
      <mat-form-field class="half-input">
        <input type="text" formControlName="description" placeholder="Local de Prova" aria-label="Local de Prova" matInput required>
      </mat-form-field>
    </div>

  </form>

  <mat-card style="margin-bottom: 20px;">

    <mat-card-subtitle>
      Locais de prova
    </mat-card-subtitle>

    <table class="table-place">
      <thead class="t-head-place">
        <th width="80%" style="text-align: left;">Local</th>
        <th width="20%">Ação</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of arrPlace">
          <td style="text-align: left;">{{item.description}}</td>
          <td><mat-icon (click)="removePlace(item.id)" class="pointer">delete</mat-icon></td>
        </tr>
      </tbody>
    </table>

  </mat-card>

</mat-dialog-content>

<div class="modal-footer">
  <!-- <button mat-raised-button mat-dialog-close type="submit" id="cancel">Cancelar</button> -->
  <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" [disabled]="!schoolLocationForm.valid" (click)="addExamLocation()" class="recover-btn"> Cadastrar </button>
</div>


