import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-clone-matrix',
  templateUrl: './clone-matrix.component.html',
  styleUrls: ['./clone-matrix.component.css']
})
export class CloneMatrixComponent implements OnInit {

  public cloneMatrixForm: UntypedFormGroup;
  public applicationArray = [];
  public courseArray = [];

  constructor(
    public dialogRef: MatDialogRef<CloneMatrixComponent>,
    private _crud: CrudService,
    @Inject(MAT_DIALOG_DATA) public matrix: any,
    private toastr: ToastrService
  ) {
    this.cloneMatrixForm = new UntypedFormGroup({
      'application_id': new UntypedFormControl(null, [Validators.required]),
      'course_id': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    this.listApplications();
    this.listCourses();
  }

  close = () => {
    this.dialogRef.close();
  }

  listApplications = () => {
    this._crud.get('public/events?order=initialDate,desc').then(res => {
      this.applicationArray = res['data'];
    });
  }

  listCourses = () => {
    this._crud.get('public/courses?order=description,asc&noRelationship=true').then(res => {
      this.courseArray = res['data'];
    });
  }

  changeApplications = (param) => {
    let application = this.applicationArray.find(el => el.id == param.value);
    this.courseArray = application.courses.map((el) => { return el.course; });
  }

  changeCourses = (param) => {
    let course = this.courseArray.find(el => el.id == param.value);
    this.applicationArray = course.events.map((el) => { return el.event; });
  }

  clone(){
    let param = Object.assign({}, this.cloneMatrixForm.value, { matrix_id: this.matrix.id });
    this._crud.post('import-matrices/clone', param).then(res => {
      this.dialogRef.close(res);
    });
  }

}
