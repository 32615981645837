<div id="close">
  <div class="modal-toolbar-system-name">
    <img class="logo-size" src="/assets/img/logos/logo-sap-interna.png">
  </div>
  <a (click)="close()" *ngIf="hasClose">
    <mat-icon class="icon-pattern">close</mat-icon>
  </a>
</div>

<h4 mat-dialog-title class="modal-toolbar-title primary" *ngIf="title">{{title}}</h4>
