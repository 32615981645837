<app-modal-header [hasClose]='true' [title]="'Lista de Destinatários da Mensagem'" (handleClose)="onDialogClose()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <div class="table-scrolable">
    <table mat-table [dataSource]="dataSource" class="">

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Grupo/Usuário </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="read_at">
        <th mat-header-cell *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let element"> {{element.read_at | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="icon-pattern" *ngIf="element.read_at" matTooltip="Lida">drafts</mat-icon>
          <mat-icon class="icon-pattern" *ngIf="!element.read_at" matTooltip="Não Lida">email</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</mat-dialog-content>
