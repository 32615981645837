
<mat-drawer-container class="example-container" hasBackdrop="false">
  <mat-drawer #drawer [opened]="options.opened" mode="side">
    <!-- Trecho mantido apenas para definir espaçamento -->
    <!-- Sem ele, o novo SIDEMENU fica menor (Ajustar depois) -->
    <mat-nav-list>
      <div class="top-bar primary">
      </div>
      <mat-card class="flex" *ngIf="options.userCard">
        <div class="profile-options-list">
          <p class="user-name"></p>
          <p class="user-title" *ngFor="let profile of user.access_profiles; let i = index;"></p>
        </div>
      </mat-card>

      <div id="menu-sidenav" *ngIf="paramsToMenuSidenav && openClose">
        <ntm-menu-sidenav [params]="paramsToMenuSidenav" [openClose]="openClose" (eventToggle)="$event"></ntm-menu-sidenav>
      </div>
    </mat-nav-list>
    <!-- Trecho mantido apenas para definir espaçamento -->
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar class="primary header-toolbar">
      <div id="header-content">
        <div>
          <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon class="sandwich-icon">menu</mat-icon>
          </button>
          <h1 class="app-name"></h1>
        </div>

        <div class="logout-option">
          <a class="back-admin" *ngIf="bkp_token" (click)="backToAdmin()">
            Voltar ao Admin
          </a>

          <mat-divider></mat-divider>
          <h2 *ngIf="nomeAmbiente">Ambiente: {{nomeAmbiente}}</h2>
          <span class="application-label" *ngIf="selectedApplication">Aplicação:&nbsp;</span>
          <mat-chip-list *ngIf="selectedApplication" title="Aplicação ativa">
            <mat-chip color="accent" class="pointer application-description" selected [matMenuTriggerFor]="menu" [ngStyle]="{'background-color': selectedApplication.hexadecimalColor}">
              {{selectedApplication.description}}
              <mat-icon class="" *ngIf="selectedApplication.is_expired" matTooltip="Aplicação encerrada">event_busy</mat-icon>
              <mat-icon class="" *ngIf="!selectedApplication.is_expired && applicationIsRunning(selectedApplication.initialDate)" matTooltip="Aplicação em andamento">event_available</mat-icon>
              <mat-icon class="" *ngIf="!selectedApplication.is_expired && !applicationIsRunning(selectedApplication.initialDate)" matTooltip="Aplicação ainda não iniciada">event</mat-icon>
            </mat-chip>
          </mat-chip-list>

          <mat-menu #menu="matMenu">
            <button class="application-list-item" mat-menu-item *ngFor="let application of applicationsArray" (click)="onChangeApplication(application);">
              <p class="application-badge" [ngStyle]="{'background-color': application.hexadecimalColor}"></p>
              <p class="application_description">
                <mat-icon class="warning" *ngIf="application.is_expired" title="Aplicação encerrada">event_busy</mat-icon>
                <mat-icon class="success" *ngIf="!application.is_expired && applicationIsRunning(application.initialDate)" title="Aplicação em andamento">event_available</mat-icon>
                <mat-icon class="upcoming" *ngIf="!application.is_expired && !applicationIsRunning(application.initialDate)" title="Aplicação ainda não iniciada">event</mat-icon>
                {{application.description}}
              </p>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>

    <div class="loading-route" *ngIf="loadingRoute">
      <mat-spinner class="spinner" diameter="50"></mat-spinner>
      <p>Carregando página...</p>
    </div>
    <router-outlet *ngIf="!loadingRoute"></router-outlet>

  </mat-drawer-content>
</mat-drawer-container>
<app-support-card></app-support-card>
