
import { Component, OnInit, Inject, ViewEncapsulation  } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from '@env/environment';

import { CdkDragEnd } from '@angular/cdk/drag-drop';

/**
 * Services
 */
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';

export interface Gender {
  value: string;
  viewValue: string;
}

export interface Detail {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-cover-sheet',
  templateUrl: './cover-sheet.component.html',
  styleUrls: ['./cover-sheet.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class CoverSheetComponent implements OnInit {

  public coverSheetForm: UntypedFormGroup;
  public id: any;
  public showApplyBtn: any = false; ;

  public contentStyle: any;
  public contentTopPos: any = -2;
  public contentLeftPos: any = -2;

  public newContentTopPos: any = -2;
  public newContentLeftPos: any = -2;

  public coverSheetUrl: any;
  public coverSheetData: any;
  public coverSheetArt: any;
  public coverSheetHTML: any;
  public coverSheetPath: any;
  public editorParams: any = {plugins: 'link', selector: 'textarea', content_style: 'body {background: red}'};

  constructor(
    private _fb: UntypedFormBuilder,
    private _crud: CrudService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CoverSheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnInit() {
    this.initcoverSheetForm();

    if (this.coverSheetHTML){
      this.coverSheetData = {description: this.sanitizer.bypassSecurityTrustHtml(this.coverSheetHTML)};
      this.setData();
    } else {
      this.getCoverSheetData();
    }

    this.initContentStyle();

    this.newContentTopPos = this.contentTopPos;
    this.newContentLeftPos = this.contentLeftPos;

  }

  initcoverSheetForm(){
    this.coverSheetForm = new UntypedFormGroup({
      'description': new UntypedFormControl(null, [ValidateRequired])
    });
  }

  initContentStyle(){
    this.contentStyle = {'top': this.contentTopPos + "px", 'left': this.contentLeftPos + "px"};
  }

  getCoverSheetData = () => {
    if (this.id){
      this._crud.get('cover-sheet/' + this.id).then(res => {
        this.coverSheetData = res;
        this.coverSheetData['description'] = {description: this.sanitizer.bypassSecurityTrustHtml(this.coverSheetData['description'])};

        var image: any = new Image();

        image.src = environment.urlToCoverSheet + res['filename'];
        this.coverSheetArt = image;


        this.editorParams = {plugins: 'link', selector: 'textarea', content_style: 'body {background: url(' + this.coverSheetArt['src'] + ')}'};


        this.contentTopPos = res['position_top'] - 2;
        this.contentLeftPos = res['position_left'] - 2;

        this.initContentStyle();

        this.setData();
      });
    }
  }

  initInvitationForm(res){

  }

  close = () => {
    this.dialogRef.close({'topPos': this.newContentTopPos, 'leftPos': this.newContentLeftPos});
  }

  convertDate(date){
    var br_date = date;
    br_date = br_date.split('/');
    var us_date = br_date.reverse().join('-');
    return us_date
  }

  convertDateToBR(date){
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date
  }

  oncoverSheetSubmit = () => {

  }

  setData(){
    if (typeof this.coverSheetArt == 'string'){
      var image: any = new Image();
      image.src = environment.urlToCoverSheet + this.coverSheetArt;
      this.coverSheetArt = image;
      this.editorParams = {plugins: 'link', selector: 'textarea', content_style: 'body {background: url(' + this.coverSheetArt + ')}'};
    }else if (typeof this.coverSheetArt == 'object' && typeof this.coverSheetArt['src'] == 'string'){

      var image: any = new Image();
      image.src = this.coverSheetArt['src'];
      this.coverSheetArt = image;
      this.editorParams = {plugins: 'link', selector: 'textarea', content_style: 'body {background: url(' + this.coverSheetArt['src'] + ')}'};
    }else{
      if (this.coverSheetArt != undefined){

        var image: any = new Image();
        var file: File = this.coverSheetArt;

        var myReader: FileReader = new FileReader();
        var that = this;
        myReader.onloadend = function (loadEvent: any) {
          image.src = loadEvent.target.result;
        };

        this.coverSheetArt = image;

        myReader.readAsDataURL(file[0]);
      }
    }
  }

  dragEnded(event: CdkDragEnd) {
    let bruteData = this.getTranslate3d(event.source.element.nativeElement);

    let leftPos = +bruteData[0].replace('px', '');
    let topPos = +bruteData[1].replace('px', '');

    this.newContentTopPos = this.contentTopPos + topPos;
    this.newContentLeftPos = this.contentLeftPos + leftPos;

    this.initContentStyle();
  }

  getTranslate3d (el) {
    var values = el.style.transform.split(/\w+\(|\);?/);
    if (!values[1] || !values[1].length) {
        return [];
    }
    return values[1].split(/,\s?/g);
  }
}
