<app-modal-header [hasClose]='true' [title]="'REFAZER AMOSTRA'" (handleClose)="close()"></app-modal-header>

<form [formGroup]="auditForm" #formDirective="ngForm" (ngSubmit)="onAuditSubmit()">
  <mat-form-field>
    <input matInput placeholder="Mensagem para o Avaliador" required formControlName="msg">
  </mat-form-field>

  <span class="audit-option">
    <p>Você deseja incluir na amostra escolas faltantes?</p>
    <mat-radio-group formControlName="includeSchools">
      <mat-radio-button value="1">Sim</mat-radio-button>
      <mat-radio-button value="0">Não</mat-radio-button>
    </mat-radio-group>
  </span><br>

  <span class="audit-option">
    <p>Você deseja substituir as tabulações que ainda não atenderam à auditoria?</p>
    <mat-radio-group formControlName="replaceTabs">
      <mat-radio-button value="1">Sim</mat-radio-button>
      <mat-radio-button value="0">Não</mat-radio-button>
    </mat-radio-group>
  </span>
</form>

<div class="modal-footer">
  <button *ngIf="!isLoading" mat-raised-button class="confirm-btn primary" color="accent" type="submit"
    (click)="onAuditSubmit()"
    [disabled]="(this.auditForm.value.includeSchools == false && this.auditForm.value.replaceTabs == false) || isLoading || this.auditForm.invalid">
    <i>Confirmar</i>
  </button>
  <button *ngIf="!isLoading" mat-raised-button mat-dialog-close type="button" [disabled]="isLoading"
    (click)="close()">Cancelar</button>
  <mat-spinner class="login-spinner center" *ngIf="isLoading" diameter="30"></mat-spinner>
</div>
