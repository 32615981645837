<app-modal-header [hasClose]='true' [title]="dialogTitle" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

    <div class="dialog-content">
        <p [innerHtml]="dialogMessage"></p>
    </div>

</mat-dialog-content>

<div class="modal-footer">
    <button class="primary-action-btn" mat-raised-button color="accent" type="submit"
    (click) = "confirm()">{{confirmText}}</button>
    <button mat-raised-button mat-dialog-close type="button">{{cancelText}}</button>
</div>
