
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/**
 * Services
 */
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { FeedService } from '@services/laravel/feed.service';
import { ToastrService } from 'ngx-toastr';
import { AccessProfile } from '@shared/enums/access-profile.enum';

@Component({
  selector: 'app-add-profile-modal',
  templateUrl: './add-profile-modal.component.html',
  styleUrls: ['./add-profile-modal.component.css']
})

export class AddProfileModalComponent implements OnInit {
  public profileForm: UntypedFormGroup;
  public loading = true;
  public loadingList = false;
  public profileArray: any;
  public profile: any;
  public event_id: any;
  public coursesArray: any = [];
  public schoolArray: any = [];
  public mySchoolControl = new UntypedFormControl('');
  filteredSchoolArray: Observable<any[]>;


  constructor(
    private _crud: CrudService,
    public _snackbar: MatSnackBar,
    private toastr: ToastrService,
    private feedService: FeedService,
    public dialogRef: MatDialogRef<AddProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.newForm();

    this._crud.get('school/homologated/' + this.data).then((res: any) => {
      this.schoolArray = res.data || res;

      this.filteredSchoolArray = this.mySchoolControl.valueChanges
        .pipe(
          startWith<string | any>(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.description;
            return name ? this._filter(name) : this.schoolArray.slice()
          }),
        );

      this._crud.get('public/my-profiles-for-invitation/' + this.event_id).then((res: any) => {
        this.loading = false;
        this.profileArray = res;

      }).catch((err: any) => {
        this.toastr.error(err.message, 'Erro!');
      });;

    }).catch((err: any) => {
      this.toastr.error(err.message, 'Erro!');
    });;
  }

  validControl() {
    this.profileForm.get('school_id').markAsTouched();
    this.profileForm.get('school_id').setValue(this.mySchoolControl.value.description);
    this.profileForm.get('course_id').reset();
    this.filterCourse(this.mySchoolControl.value.id);
  }

  displayFnSchool(school?: School) {
    return school && school.description ? school.description : '';
  }

  public _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.schoolArray.filter(school => school.description.toLowerCase().includes(filterValue));
  }

  newForm() {
    this.profileForm = new UntypedFormGroup({
      'access_profile_id': new UntypedFormControl(null, [ValidateRequired]),
      'school_id': new UntypedFormControl({ value: null, disabled: true }, [ValidateRequired]),
      'course_id': new UntypedFormControl({ value: null, disabled: true }, [ValidateRequired])
    });
  }

  close = () => {
    this.dialogRef.close();
  }

  addProfile() {

    let access_profiles: any = {
      profile_id: this.profile.invite_profile_id,
      description: this.profile.description
    };

    // let school = this.schoolArray.find(el => +el.id == +this.profileForm.value.school_id );
    let school = this.schoolArray.find(el => +el.id == +this.mySchoolControl?.value?.id);
    if (school) {
      access_profiles = {
        ...access_profiles,
        school_id: school.id,
        schoolName: school.description
      }
    }

    let course = this.coursesArray.find(el => +el.id == +this.profileForm.value.course_id);
    if (course) {
      access_profiles = {
        ...access_profiles,
        course_id: course.id,
        courseName: course.description
      }
    }

    this.dialogRef.close(access_profiles);
  }

  verifyRequeriments(event) {
    this.mySchoolControl.reset();
    this.profileForm.get('school_id').setValue(null);
    this.profileForm.get('course_id').setValue(null);

    this.profile = this.profileArray.find(el => +el.invite_profile_id == +event.value);

    console.log('verifyRequeriments', this.profile);
    
    if (+this.profile.require_school) {
      this.profileForm.controls['school_id'].enable();
      this.profileForm.controls['school_id'].setValidators([ValidateRequired]);
      this.profileForm.controls['school_id'].updateValueAndValidity();
    } else {
      this.profileForm.controls['school_id'].disable();
      this.profileForm.controls['school_id'].setValidators(null);
      this.profileForm.controls['school_id'].updateValueAndValidity();
    }

    if (+this.profile.require_course === 1) {
      if (+this.profile.require_course && +this.profile.require_school === 0) {
        this.loadingList = true;
        this._crud.get('public/course-event?where=event_id,' + this.event_id).then((res: any) => {
          this.coursesArray = res.data.map(el => { return el.course }).sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
          this.profileForm.controls['course_id'].enable();
          this.loadingList = false;
        }).catch((err: any) => {
          this.loadingList = false;
          this.toastr.error(err.message, 'Erro!');
        });

      }
      this.profileForm.controls['course_id'].setValidators([ValidateRequired]);
      this.profileForm.controls['course_id'].updateValueAndValidity();
    } else {
      console.log('require_course false');
      this.profileForm.controls['course_id'].disable();
      this.profileForm.controls['course_id'].setValidators(null);
      this.profileForm.controls['course_id'].updateValueAndValidity();
    }
  }

  filterCourse(id) {
    if (+this.profile.require_course) {
      this.loadingList = true;
      this._crud.get('public/coursesSchool/school/' + id).then((res: any) => {
        this.profileForm.controls['course_id'].enable();
        this.coursesArray = res.data || res;
        this.loadingList = false;
      }).catch((err: any) => {
        this.loadingList = false;
        this.toastr.error(err.message, 'Erro!');
      });
    }
  }

  public showCourseInput(): boolean {
    const profileId = this.profileForm.get('access_profile_id').value;
    const schoolId = this.profileForm.get('school_id')?.value;
    const isProfileRelevant = [AccessProfile.EVALUATOR, AccessProfile.DEVELOPER].includes(profileId);
    switch (profileId) {
      case 5:
        return isProfileRelevant && this.isCoursesArrayPopulated() && schoolId;
      case 6:
        return isProfileRelevant && this.isCoursesArrayPopulated();
      default:
        return false;
    }
  }
  
  public showCourseInputErrorMessage(): boolean {
    const profileId = this.profileForm.get('access_profile_id').value;
    const schoolId = this.profileForm.get('school_id')?.value;
    const isProfileRelevant = [AccessProfile.EVALUATOR, AccessProfile.DEVELOPER].includes(profileId);
    switch (profileId) {
      case 5:
        return isProfileRelevant && !this.isCoursesArrayPopulated() && schoolId;
      case 6:
        return isProfileRelevant && !this.isCoursesArrayPopulated();
      default:
        return false;
    }
  }

  private isCoursesArrayPopulated(): boolean {
    return this.coursesArray && this.coursesArray.length > 0;
  }
  
  public showSchoolInput(): boolean {
    const profileId = this.profileForm.get('access_profile_id').value;
    const isProfileRelevant = [AccessProfile.EVALUATION_COORDINATOR, AccessProfile.EVALUATOR].includes(profileId);
    return isProfileRelevant && this.isSchoolArrayPopulated();
  }

  public showSchoolInputErrorMessage(): boolean {
    const profileId = this.profileForm.get('access_profile_id').value;
    const isProfileRelevant = [AccessProfile.EVALUATION_COORDINATOR, AccessProfile.EVALUATOR].includes(profileId);
    return isProfileRelevant && !this.isSchoolArrayPopulated();
  }

  private isSchoolArrayPopulated(): boolean {
    return this.schoolArray && this.schoolArray.length > 0;
  }

}


export interface School {
  id?: number;
  description?: string;
}
