<!-- <div id="close">
  <a (click)="close()">
    <mat-icon class="mat-18">close</mat-icon>
  </a>
</div> -->

<mat-dialog-content class="content-dialog">

  <form [formGroup]="pickImageForm">
    <div class="file-upload">
      <input id="custom-input" type="file" formControlName="avatar_path" name="avatar-path" (change)="fileChangeListener($event)" required>
    </div>
    <div class="profile-spot" onclick="document.getElementById('custom-input').click();">
        <img id="profile-image" [src]="image_data_preview.src"><br>
    </div>
    <img-cropper style="display: none" #cropper [image]="image_data" [settings]="cropperSettings"></img-cropper>
  </form>

</mat-dialog-content>

<div class="modal-footer">

  <button mat-raised-button color="basic" id="cancel" onclick="document.getElementById('custom-input').click();" >Procurar</button>
  <button mat-raised-button id="save-avatar-button" color="primary" (click)="onSave()" [disabled]="!validForm" >Salvar</button>

</div>
