import { CrudService } from '@services/laravel/crud.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.css']
})
export class ViewMediaComponent implements OnInit {
  private player;
  public counter;
  public timer: any;
  public timeSPent = [];
  public attendance: any;
  public arr: any = [10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100];

  constructor(
    public dialogRef: MatDialogRef<ViewMediaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private crud: CrudService,
  ) {
  }

  ngOnInit() {
    this.data = JSON.parse(JSON.stringify(this.data));
  }

  getEmbeddedLink(link) {
    return link.replace('watch?v=', 'embed/') + '?controls=0&autoplay=1';
  }

  youtube_parser(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  }

  onVideoChange(p) {
    if (p.data === 1) {
      if (!this.timeSPent.length) {
        for (var i = 0, l = parseInt(this.player.getDuration()); i < l; i++) this.timeSPent.push(false);
      }
      this.timer = setInterval(() => this.record(), 1000);
    } else {
      clearInterval(this.timer);
    }
  }

  playerReady(e) {
    this.player = e;
    this.player.playVideo();
  }

  record() {
    this.timeSPent[parseInt(this.player.getCurrentTime())] = true;
    this.savePercentage();
  }

  savePercentage() {
    let percent: number = 0;

    for (var i = 0, l = this.timeSPent.length; i < l; i++) {
      if (this.timeSPent[i]) percent++;
    }

    let duration: number = this.player.getDuration();
    let currentTime: number = this.player.getCurrentTime();
    percent = Math.ceil((currentTime * 100) / duration);
    percent = percent > 95 ? 100 : percent;

    if (this.arr.includes(percent)) {
      this.crud.post('medias/attendance', {
        "media_id": this.data.media_id,
        "user_id": JSON.parse(sessionStorage.getItem('user_id')),
        "time": Math.round(this.player.getDuration()),
        "percent": percent
      }).then(() => {
        let index = this.arr.indexOf(percent);
        this.arr.splice(index, 1);
      });
    }
  }

}
