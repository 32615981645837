import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[focusFirstInvalidField]'
})
export class FocusFirstInvalidFieldDirective {

  constructor(private el: ElementRef) { }

  @HostListener('submit')
  onFormSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('input.ng-invalid, mat-select.ng-invalid');

    if (invalidElements.length > 0) {
      invalidElements[0].focus();
    }
  }
}
