import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.css']
})
export class ModalHeaderComponent implements OnInit {
  @Input() title;
  @Input() hasClose = false;
  @Output() handleClose = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.handleClose.emit('true');
  }
}
