<app-modal-header [title]="title"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <p class="message primary-text">{{mensagem}}</p>
  <mat-spinner diameter="50"></mat-spinner>

</mat-dialog-content>

<div class="modal-footer" *ngIf="showBtn">

  <button type="button" mat-raised-button color="accent" (click)="onDialogClose()">
    Cancelar Importação
  </button>

</div>
