import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ntm-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.css']
})

export class DeleteConfirmComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  dataToDelete: any;
  dialogTitle = 'Desativar Registro';
  dialogMessage: string;
  resend: boolean;
  confirm: boolean;
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    if (!this.data.dialogMessage) {
      this.dialogMessage = 'Tem certeza que deseja apagar?';
    } else {
      this.dialogMessage = this.data.dialogMessage;
    }

    if (!this.data.dialogTitle) {
      this.dialogTitle = 'Desativar Registro';
    } else {
      this.dialogTitle = this.data.dialogTitle;
    }

    if (this.data.resend) {
      this.resend = this.data.resend;
    }

    if (this.data.confirm) {
      this.confirm = this.data.confirm;
    }
  }

  confirmAction() {
    if (!this.resend && !this.confirm) { // Excluir
      for (let lim = this.data.paramToDelete.length, i = 0; i < lim; i++) {
        this.crud.delete(this.data.routeToApi + '/' + this.data.paramToDelete[i])
          .then(res => {
            this.toastr.success('Item excluído com sucesso.', 'Sucesso!');
            this.router.navigate([this.data.routeAfterDelete]);
          });
      }

      this.dialogRef.close(true);
    } else if (this.resend) { // Reenviar
      this.crud.get(this.data.routeToApi)
      .then(res => {
        this.router.navigate([this.data.routeAfterDelete]);
        this.toastr.success('Reenviado com sucesso');
      });
      this.dialogRef.close(true);
    } else if (this.confirm) {
      this.dialogRef.close(true);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
