<app-modal-header [hasClose]='true' [title]="'IMPORTAR MANUAL DO AVALIADOR'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">
  <form [formGroup]="importEvaluatorManualForm" *ngIf="!isLoading">
    <div class="mg1 flex">
      <mat-form-field class="half-input matrix-select">
        <mat-select matSelect formControlName="source_event_id" placeholder="Aplicação"
          (selectionChange)="listCourses()">
          <mat-option *ngFor="let application of applicationArray" [value]="application?.id">
            {{application?.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="half-input matrix-select">
        <mat-select matSelect formControlName="course_id" placeholder="Curso" (selectionChange)="listEvaluatorManuals()"
          [disabled]="!importEvaluatorManualForm.get('source_event_id').value">
          <mat-option *ngFor="let course of courseArray" [value]="course?.course_id">
            {{course?.course?.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isLoading && evaluatorManualArray.length > 0">
      <p class="primary-text align-left">MANUAIS ENCONTRADOS:</p>
      <table mat-table [dataSource]="evaluatorManualArray" class="mat-elevation-z8 data-table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> * </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox #evaluatorManualCheckbox [value]="element.id"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="preview">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Visualizar </th>
          <td mat-cell *matCellDef="let element">
            <a mat-button mat-icon-button class="delete-evidence-btn" (click)="previewEvaluatorManual(element)">
              <mat-icon mat-list-icon>zoom_in</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="particularity">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Particularidade </th>
          <td mat-cell *matCellDef="let element"> {{element.particularity}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Curso </th>
          <td mat-cell *matCellDef="let element"> {{element.course.description}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </form>

  <div *ngIf="isLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="accent" [disabled]="!importEvaluatorManualForm.valid" (click)="submitForm()">Importar</button>
</div>
