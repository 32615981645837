<app-modal-header [title]="'Termo de Compromisso'"></app-modal-header>

<mat-dialog-content class="content-dialog" #termContent (scroll)="scrollHandler($event)">
  <app-loading *ngIf="loading"></app-loading>
  <p id="term-content" *ngIf="!loading" innerHtml="{{term?.value}}"></p>
</mat-dialog-content>

<div class="modal-footer">
  <i class="primary-text">Atualizado em: {{term?.updated_at | date: 'dd/MM/yyyy hh:mm'}}</i>
  <button id="acceptBtn" type="button" mat-raised-button color="accent" [disabled]="blockBtn" (click)="acceptTerm();">
    Aceito
  </button>
</div>
