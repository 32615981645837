import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ValidateRequired } from '@shared/validators/required.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';

@Component({
  selector: 'app-re-audit-modal',
  templateUrl: './re-audit-modal.component.html',
  styleUrls: ['./re-audit-modal.component.css']
})
export class ReAuditModalComponent implements OnInit {
  public isLoading;
  public auditForm: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<ReAuditModalComponent>,
    private _crud: CrudService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.auditForm = new UntypedFormGroup({
      'id': new UntypedFormControl(null, [ValidateRequired]),
      'msg': new UntypedFormControl(null, [ValidateRequired]),
      'includeSchools': new UntypedFormControl(null, [ValidateRequired]),
      'replaceTabs': new UntypedFormControl(null, [ValidateRequired]),
      'replaceMsg': new UntypedFormControl(true)
    });
    if (this.data.id) {
      this.auditForm.get('id').setValue(this.data.id);
    }

    if (this.data.msg) {
      this.auditForm.get('msg').setValue(this.data.msg);
    }
  }

  onAuditSubmit() {
    this.isLoading = true;
    const obj = {
      'msg': this.auditForm.value.msg,
      'includeSchools': this.auditForm.value.includeSchools == 1 ? true : false,
      'replaceTabs': this.auditForm.value.replaceTabs == 1 ? true : false,
      'replaceMsg': this.auditForm.value.replaceMsg
    };
    this._crud.put('auditing-sample/remake-sample/' + this.auditForm.value.id, obj).then(res => {
      this.isLoading = false;
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close();
  }

}
