<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content *ngIf="!loading" class="content-dialog">

  <!-- INFO ALUNO -->
  <mat-card style="text-align: left;" class="m-b20">

    <div class="div-info">
      <mat-icon style="margin-right: 5px;">business</mat-icon> <span> {{data.school_name}} ({{data.classroom}})</span>
    </div>
    <div class="div-info">
      <mat-icon style="margin-right: 5px;">school</mat-icon> <span> {{data.course_name}} ({{data.class_name}})</span>
    </div>
    <div class="div-info">
      <mat-icon style="margin-right: 5px;">person</mat-icon> <span> {{data.cpf_number}} </span> - <span>
        {{data.name}}
      </span>
    </div>
    <div class="div-info">
      <div class="div-flex">
        <div style="margin-right: 10px;">
          <mat-icon style="margin-right: 5px;" matTooltip="Responsável pela Avaliação">event_note</mat-icon>
          <span matTooltip="Responsável pela Avaliação">
            {{data.appointment_timestamp | date: 'dd/MM/yyyy'}} {{data.appointment_timestamp | date: 'HH:mm:ss'}}
          </span>
        </div>
        <div>
          <mat-icon style="margin-right: 5px;" matTooltip="Responsável pela Avaliação">person_pin</mat-icon>
          <span matTooltip="Responsável pela Avaliação"> {{data.evaluator_name}} </span>
        </div>
      </div>
    </div>

    <!-- CANCELAR -->
    <div class="content-cancel">
      <button mat-raised-button color="warn" (click)="cancel()">Cancelar Exame</button>
    </div>

  </mat-card>

  <!-- INCONFORMIDADES -->
  <mat-expansion-panel #inconformidades class="m-b20" (opened)="markAsRead()">
    <mat-expansion-panel-header>

      <mat-panel-title style="align-items: center;">
        <span style="margin-right: 15px;"> INCONFORMIDADE(s) </span>
        <mat-chip color="warn" selected *ngIf="nonComplianceOpen.length">{{nonComplianceOpen.length}}</mat-chip>
      </mat-panel-title>

      <mat-panel-description style="align-items: center;">
        Inconsistência no processo de resultados
      </mat-panel-description>

    </mat-expansion-panel-header>

    <div style="max-height: 150px; overflow: auto;">

      <table style="width: 100%;" id="tableInconformidades">
        <thead>
          <tr class="t-head-non-compliance">
            <th width="15" style="max-width: 15%; width: 15%;">Data/Hora</th>
            <!-- <th width="10">Motivo</th> -->
            <th width="55" style="max-width: 55%; width: 55%;">Observação</th>
            <th width="10" style="max-width: 10%; width: 10%;">Em Aberto</th>
            <th width="20" style="max-width: 20%; width: 20%;">Autor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nonCompliance" class="t-body-non-compliance"
            [class.highlight]="needToReadNonCompliance">
            <td style="max-width: 15%; width: 15%;">{{item.created_at | date: 'dd/MM/yyyy'}}
              {{item.created_at | date: 'hh:mm:ss'}}</td>
            <!-- <td>{{item.exam_status_name}}</td> -->
            <td style="max-width: 55%; width: 55%;">{{item.description}}</td>
            <td style="max-width: 10%; width: 10%;">{{item.is_open ? 'Sim' : 'Não'}}</td>
            <td stbuttoyle="max-width: 20%; width: 20%;">{{item.author.name}}</td>
          </tr>
        </tbody>
      </table>

    </div>

  </mat-expansion-panel>

  <!-- ABAS DAS EVIDENCIAS -->
  <div style="width: 100%;">

    <mat-tab-group [dynamicHeight]="false">
      <mat-tab label="EVIDÊNCIAS POR PERFIL">

        <div class="m-b20">
          <form [formGroup]="listForm" style="padding: 0;">
            <table class="view-matrix">

              <div formArrayName="evidences" style="padding: 0;">
                <tbody>

                  <ng-container *ngFor="let item of result.evidences; let i = index">

                    <tr *ngIf="item.competency_unit_description" class="tr-uc">
                      <td colspan="3" class="td-unit">
                        <p class="unit"> UNIDADE: {{item.competency_unit_code}} - {{item.competency_unit_description}}
                        </p>
                      </td>
                    </tr>

                    <tr *ngIf="item.competency_element_description">
                      <td colspan="3" class="td-ele">
                        <p class="ele"> ELEMENTO: {{item.competency_element_code}} -
                          {{item.competency_element_description}}
                        </p>
                      </td>
                    </tr>

                    <tr *ngIf="item.performance_standards_description">
                      <td colspan="3" class="td-standard">
                        <p class="standard"> PADRÃO DE DESEMPENHO: {{item.performance_standards_code}} -
                          {{item.performance_standards_description}} </p>
                      </td>
                    </tr>

                    <tr [formGroupName]="i" [ngClass]="[item.correct == 1 ? 'done' : '', item.correct == 0 ? 'close' : '']">

                      <td class="td-evidence" style="min-width: 50%; width: 50%;">
                        <mat-icon *ngIf="item.correct == 1" class="done">done</mat-icon>
                        <mat-icon *ngIf="item.correct == 0" class="close">close</mat-icon>
                        <p class="evidence"> {{item.description}} </p>
                      </td>

                      <td class="td-evidence">

                        <mat-radio-group
                          formControlName="correct"
                          [(ngModel)]="item.correct"
                          [disabled]="data.result_confirmation == 1"
                          (change)="revalidateEvidence(item, i)"
                          required>
                          <mat-radio-button [value]="1" [checked]="item.correct == 1">Sim</mat-radio-button>
                          <mat-radio-button [value]="0" [checked]="item.correct == 0">Não</mat-radio-button>
                        </mat-radio-group>

                      </td>

                      <td class="td-evidence">
                        <input matInput placeholder="Justificativa"
                          *ngIf="listForm?.value?.evidences[i]?.correct == 0"
                          [readonly]="data.result_confirmation == 1"
                          [required]="item.correct == 0 && listForm.value.evidences[i].correct == 0"
                          class="input-jus"
                          type="text"
                          minlength="3"
                          formControlName="reason"
                          [(ngModel)]="item.reason"
                          (change)="revalidateEvidence(item, i)"
                          >
                      </td>

                    </tr>

                  </ng-container>


                </tbody>
              </div>

            </table>
          </form>
        </div>

      </mat-tab>

      <mat-tab label="EVIDÊNCIAS POR ATIVIDADES">

        <div style="padding: 5px 1px;">

          <ng-container *ngFor="let item of result.activity">

            <mat-expansion-panel [expanded]="true" class="m-b20">
              <mat-expansion-panel-header>
                {{item.description}}
              </mat-expansion-panel-header>

              <table style="width: 100%;">
                <thead>
                  <tr class="t-head-non-compliance">
                    <th width="2"></th>
                    <th width="48" style="text-align: left">EVIDÊNCIAS OBSERVÁVEIS</th>
                    <th width="20"></th>
                    <!-- <th width="10">NÃO</th> -->
                    <th width="30"></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let evidence of item.evidences">
                    <tr [ngClass]="[evidence.correct == 1 ? 'done' : '', evidence.correct == 0 ? 'close' : '']">
                      <td class="td-evidence" style="position: relative">
                        <mat-icon style="position: static" *ngIf="evidence.correct == 1" class="done">done</mat-icon>
                        <mat-icon style="position: static" *ngIf="evidence.correct == 0" class="close">close</mat-icon>
                      </td>
                      <td class="td-evidence" style="text-align: left; min-width: 50%; width: 50%;">
                        {{evidence.description}}
                      </td>
                      <td class="td-evidence">
                        <mat-radio-group [(ngModel)]="evidence.correct" [ngModelOptions]="{standalone: true}"
                          [disabled]="data.result_confirmation == 1">
                          <mat-radio-button [value]="1" [checked]="evidence.correct === 1">SIM</mat-radio-button>
                          <mat-radio-button [value]="0" [checked]="evidence.correct === 0">NÃO</mat-radio-button>
                        </mat-radio-group>
                      </td>
                      <td class="td-evidence">
                        <input placeholder="Justificativa" class="input-jus" value="{{evidence.reason}}"
                          *ngIf="evidence.correct == 0"
                          [readonly]="evidence.correct == 1 || evidence.correct == null || data.result_confirmation == 1"
                          [required]="evidence.correct == 0" [(ngModel)]="evidence.reason">
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

            </mat-expansion-panel>

          </ng-container>

        </div>
      </mat-tab>
    </mat-tab-group>

  </div>

  <!-- ANEXOS -->
  <mat-card style="text-align: left;" class="m-b20">

    <mat-card-subtitle> ANEXO(S) </mat-card-subtitle>

    <div *ngIf="attachments && !loadingCompressing" style="margin-bottom: 16px;">
      <div *ngFor="let att of attachments">
        <mat-icon class="pointer" (click)="removeFile(att)">delete</mat-icon> <span class="attachment-link"
          (click)="download(att)">{{att.description}} - {{att.filename}} </span>
      </div>
    </div>

    <div *ngIf="loadingAttachment || loadingCompressing" class="center-loading compressing-loading">
      <p *ngIf="loadingCompressing">Esta operação pode demorar um pouco, por favor aguarde.</p>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="half-input-attachment-button" *ngIf="!loadingAttachment && !loadingCompressing">
      <mat-form-field style="width: 13em;">

        <input type="text" matInput id="file-input" required style="border: initial !important;" class="select_file"
          placeholder="Descrição do Anexo" [(ngModel)]="attachmentDescription" />

      </mat-form-field>

      <button mat-raised-button [disabled]="attachmentDescription.length == 0" (click)="uploadFile.click()"
        color="primary" matTooltip="{{fileFormats}}" matTooltipPosition="right" class="upload-btn">

        <mat-icon style="transform: rotate(180deg);">get_app</mat-icon> Adicionar Anexo

      </button>

      <input type="file" id="file-input" class="select_file" (change)="onUploadMedia($event)"
        accept="{{fileFormats}}" style="display:none;" #uploadFile />

      <button id="submit-attachment" (click)="saveAttachment()" color="accent" mat-raised-button class="upload-btn primary"
        *ngIf="!offerCompression" [disabled]="!hasFile || loadingAttachment">
        Incluir
      </button>

      <button id="submit-attachment" (click)="compressAttachment()" color="accent" mat-raised-button class="upload-btn primary"
        *ngIf="offerCompression" [disabled]="!offerCompression || loadingAttachment">
        Comprimir
      </button>
      <i *ngIf="offerCompression" class="file-size-warning"><br> *O arquivo escolhido é muito grande. Clique em "Comprimir" para tentar reduzir seu tamanho.</i>

    </div>
  </mat-card>

  <!-- HOMOLOGAÇÃO DE RESULTADOS -->
  <mat-card style="text-align: left;" class="m-b20" *ngIf="data.action != 3">

    <mat-card-subtitle> {{titleCard}} </mat-card-subtitle>

    <form [formGroup]="listForm" style="padding: 0;">

      <div class="m-b20">Você deseja homologar os dados tabulados? *</div>

      <mat-radio-group formControlName="confirmation" *ngIf="data.action == 4" (click)="change($event)">
        <mat-radio-button [value]="0">Não</mat-radio-button>
        <mat-radio-button [value]="1">Sim</mat-radio-button>
      </mat-radio-group>

      <mat-radio-group formControlName="confirmation" *ngIf="data.action == 5" (change)="change($event)">
        <mat-radio-button [value]="0">Não, devolver ao avaliador</mat-radio-button>
        <mat-radio-button [value]="1">Não, devolver ao coordenador</mat-radio-button>
        <mat-radio-button [value]="2">Sim</mat-radio-button>
      </mat-radio-group>

      <mat-form-field>
        <textarea matInput placeholder="Inconformidade" *ngIf="data.action == 4" formControlName="description" rows="5"
          [required]="listForm.value.confirmation == 0" maxlength="191"></textarea>
        <textarea matInput placeholder="Inconformidade" *ngIf="data.action == 5" formControlName="description" rows="5"
          [required]="listForm.value.confirmation != 2" maxlength="191"></textarea>
          <mat-hint>Limite de caracteres: {{listForm.value.description ? listForm.value.description.length : '0'}}/191</mat-hint>
      </mat-form-field>
    </form>
  </mat-card>

</mat-dialog-content>

<div class="modal-footer" *ngIf="!loading">
  <span *ngIf="needToReadNonCompliance">
    <button id="nonComplianceBtn" class="delete-btn" mat-raised-button color="warn" type="button"
      (click)="focusNonCompliance()">Você possui inconformidades que devem ser verificadas antes de confirmar os
      dados.</button>
  </span>
  <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button>
  <button id="applyBtn" class="primary-action-btn" *ngIf="data.action == 3"
    mat-raised-button type="submit" (click)="resultTabulate()">Confirmar tabulação</button>
  <mat-chip *ngIf="(needToReadNonCompliance || listForm.invalid) && pendingInputs > 0">
    <mat-icon>warning</mat-icon> {{ pendingInputs }} pendente(s)
  </mat-chip>
  <mat-chip *ngIf="attachments?.length == 0">
    <mat-icon>warning</mat-icon> Sem anexo(s)
  </mat-chip>
  <button id="applyBtn"
    class="primary-action-btn"
    *ngIf="data.action == 4"
    [disabled]="canRegionalConfirm() === false"
    mat-raised-button
    type="submit"
    (click)="resultRegionalConfirm(false)"
    matTooltip="Confirmar revisão da tabulação">Confirmar</button>
  <button id="applyBtn" class="primary-action-btn" *ngIf="data.action == 5"
    [disabled]="needToReadNonCompliance || listForm.invalid"
    mat-raised-button type="submit" (click)="nationalConfirm()">Confirmar</button>
</div>

<div *ngIf="loading" class="center-loading">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
