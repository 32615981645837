import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

/**
 * Services
 */
import { AuthenticationService } from '@services/laravel/authentication.service';
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '@shared/components/forgot-password/forgot-password.component';
import { CrudService } from '@services/laravel/crud.service';
import { RegisterComponent } from '../register/register.component';
import { ValidateRequired } from '@shared/validators/required.validator';
import { AssociationUserSocialComponent } from '@main/components/association-user-social/association-user-social.component';

import { environment } from '@env/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public disabled: boolean;
  obj: any;
  public loginForm: FormGroup;
  public mask: any;
  public paramToSearch: any;
  public blockLoginBtn: any = false;
  public blockGoogleLoginBtn: any = false;
  public nomeAmbiente: any = '';

  constructor(
    private _auth: AuthenticationService,
    private _router: Router,
    public _snackbar: MatSnackBar,
    private dialog: MatDialog,
    private _crud: CrudService,
    private _route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.nomeAmbiente = !environment.production ? environment.name : '';
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params) {
        this.paramToSearch = params['token'];
        if (this.paramToSearch) {
          this.onInvitationRegister(this.paramToSearch.replace(':', ''));
        }
      }
    });
    this.disabled = false;

    this.mask = {
      cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
      date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      zip: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
      phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cell_phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    };

    const url = this._router.url;
    const urlArray = url.split('/');
    this._route.params
      .subscribe(params => {
        if (params['id'] && urlArray[1] + '/' + urlArray[2] === 'password/refresh') {
          this._crud.get(
            'password?token=' + urlArray[3] + '&email=' + urlArray[4]
          )
            .then(res => {
              this.obj = {
                'email': res['obj']['data'],
                'token': urlArray[3]
              };

              this.onRecoverPassword();
            }, rej => {
              this.toastr.error('Link de recuperação inválido.', 'Erro!');
              this._router.navigate(['/']);
            });
        }
        if (params['id'] && urlArray[1] === 'register') {
          this._crud.get(
            'invitation/' + urlArray[2]
          ).then(resp => {
            this.obj = resp['obj'];
            this.onRegister();
          }, rej => {
            this.toastr.error('Convite Inválido.', 'Erro!');
            // this._snackbar.open('Convite Inválido.', '', {
            //   duration: 4000,
            //   panelClass: ['error-snackbar']
            // });
          });
        }
      });

    this.loginForm = new FormGroup({
      cpf: new FormControl<string>('', [ValidateRequired]),
      password: new FormControl<string>('', [ValidateRequired]),
    });
  }

  onLoginSubmit = () => {
    if (this.loginForm.invalid) {
      this.toastr.warning('Preencha o CPF e SENHA para continuar', 'Dados incompletos');
      return;
    }
    this.blockLoginBtn = true;

    let params;
    params = {
      login: this.loginForm.get('cpf'),
      password: this.loginForm.get('password'),
      loginMode: 'emailAndPassword',
      navigateTo: '/main'
    };

    this._auth
      .login(params)
      .then(res => {
        this.blockLoginBtn = false;
        const string = JSON.stringify(res);
        const json = JSON.parse(string);

        if (json.cod === 200) {
          this.toastr.success(json.message, 'Bem-vindo!');
          this._router.navigate(['/main/select-application']);
        } else {
          this.toastr.error(json.message, 'Erro ' + json.cod + ':');
        }
      }).catch(rej => {
        this.blockLoginBtn = false;
        setTimeout(() => {
          this.disabled = false;
        }, 3000);
      });
  }

  onRegister = () => {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '1000px',
      height: 'auto',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      const array = [];
      const string = '';
      window.location.reload();
    });
  }

  onInvitationRegister = (token) => {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '80%',
      height: 'auto',
      data: { token },
      disableClose: true
    });
    dialogRef.componentInstance.token = token;

    dialogRef.afterClosed().subscribe(result => {
      const array = [];
      const string = '';
    });
  }

  onForgotPassword = () => {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '520px',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      const array = [];
      const string = '';
      window.location.reload();
    });
  }

  onRecoverPassword = () => {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: '900px',
      height: 'auto',
      data: this.obj,
      disableClose: true
    });
  }

  openModalAssociation(data) {
    const dialogRef = this.dialog.open(AssociationUserSocialComponent, {
      width: '600px',
      height: 'auto',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
