import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { FeedService } from '@services/laravel/feed.service';
import { ToastrService } from 'ngx-toastr';
import { MatrixService } from '@services/matrix.service';

export interface Event {
  value: string;
  viewValue: string;
}

export interface User {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-import-matrices',
  templateUrl: './import-matrices.component.html',
  styleUrls: ['./import-matrices.component.css']
})
export class ImportMatricesComponent implements OnInit {
  public matrix;
  public loading = false;
  public importing = false;
  public applicationArray: any;
  public courseArray: any;
  public matrixArray: any = [];
  public cancel = false;
  public importMatrixForm: UntypedFormGroup;
  public searchMatrixForm: UntypedFormGroup;
  public searchTimer: any;
  public data = [];


  constructor(
    public dialogRef: MatDialogRef<ImportMatricesComponent>,
    private _crud: CrudService,
    private feedService: FeedService,
    private service: MatrixService,
    private toastr: ToastrService
  ) {
    this.importMatrixForm = new FormGroup({
      'matrix_id': new FormControl(null, [Validators.required]),
      'application_id': new FormControl<string>(null, [Validators.required]),
      'course_id': new FormControl<string>(null, [Validators.required])
    });

    this.searchMatrixForm = new UntypedFormGroup({
      'courses.description': new UntypedFormControl(null),
      'matrices.version': new UntypedFormControl(null),
      'itineraries.version': new UntypedFormControl(null)
    })

    this._crud.get('import-matrices/imported').then((res: any) => {
      console.log('import-matrices', res);

      this.data = res.map((el) => { return el.id_imported });

    });
  }

  ngOnInit() {
    this.importMatrixForm.get('application_id').disable();
    this.importMatrixForm.get('course_id').disable();
    this.loadMatrices();
    this.listApplications();
    this.feedService.listValidCourses().then((res: any) => {
      console.log(res);

      this.courseArray = res;
    });
  }

  loadMatrices(){
    clearTimeout(this.searchTimer);
    this.loading = true;
    let param = {
      "fields": this.searchMatrixForm.value,
      "orderBy": {
        "courses.description": "asc",
        "matrices.version": "desc",
        "itineraries.version": "desc"
      }
    };
    this.searchTimer = setTimeout(_ => {

      this.service.post(
        'matrices-with-itinerarie',
        param
      ).then((res: any) => {
        this.matrixArray = res;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        if(!err.status) return this.toastr.error('Sem conexão com serviço do SisBia.');
        return this.toastr.error(err.message);
      });

    }, 300);
  }

  listApplications = () => {
    this._crud.get('public/events?order=initialDate,desc').then(res => {
      console.log(res);

      this.applicationArray = res['data'];
    });
  }

  changeMatrix = () => {
    this.importMatrixForm.get('application_id').enable();
    this.importMatrixForm.get('application_id').reset();
  }

  changeApplications = (param) => {
    let application = this.applicationArray.find(el => el.id == param.value);
    this.courseArray = application.courses.map((el) => { return el.course; });

    this.importMatrixForm.get('course_id').enable();
    this.importMatrixForm.get('course_id').reset();
  }

  changeCourses = (param) => {
    let course = this.courseArray.find(el => el.id == param.value);
    this.applicationArray = course.events.map((el) => { return el.event; });
  }

  close = () => {
    this.dialogRef.close();
  }

  importMatrix = () => {
    this.importing = true;

    this.service.get('matrices/no-tree/' + this.importMatrixForm.value.matrix_id)
    .then((matrix: any) => {
      matrix.event_id = this.importMatrixForm.value.application_id;
      matrix['course_id'] = this.importMatrixForm.value.course_id;

      this._crud.post('import-matrices', matrix)
      .then((res: any) => {

        if (this.cancel) {
          this._crud.delete('import-matrices/' + res.matrix).then(res => {
            this.dialogRef.close();
            this.importing = false;
            this.toastr.warning('Importação cancelada.', 'Atenção!');
          });
        }else{
          this.dialogRef.close();
          this.toastr.success(res.message, 'Sucesso!');
        }

      }).catch((err: any) => {
        this.importing = false;
      });
    });
  }

  cancelImport = () => {
    this.cancel = true;
  }
}
