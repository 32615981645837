<app-modal-header [hasClose]='true' [title]="'ASSOCIAR APLICAÇÕES'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="importUsersForm">
    <div id="text" class="primary-text">
      Busque a(s) aplicação(ões) que deseja associar e clique em SELECIONAR.
    </div>

    <div class="flex">
      <mat-form-field class="half-input">
        <input type="text" [formControl]="myControl" formControlName="user" placeholder="Aplicações" aria-label="Usuário" matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" matSelect placeholder="Escolha o Usuário">
          <mat-option *ngFor="let application of filteredOptions | async" [value]="application">
            {{application.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button type="button" mat-raised-button color="primary" class="far-left select-user-btn secondary primary-text" (click)="addUser()">
          Selecionar
      </button>
    </div>

    <p class="associated-profiles-title primary-text">Aplicações Selecionadas:</p>

    <ul class="application-itens">
      <li class="application-item" *ngFor="let application of associatedEvents">
        <span class="remove-item primary-text" (click)="removeAplication(application)">
          <mat-icon>delete</mat-icon>
        </span>
        {{application.description}}
      </li>
    </ul>


  </form>

</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="accent" [disabled]="!validForm" (click)="associateEvents()">
    Adicionar Aplicações
  </button>
</div>
