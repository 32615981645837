export enum ExamStatus {
  GERADO = 1,
  CANCELADO = 2,
  REALIZADO = 3,
  CONFIRMADO_DR = 4,
  CONFIRMADO_DN = 5,
  RECUSADO_DR_PARA_AVALIADOR = 6,
  RECUSADO_DN_PARA_AVALIADOR = 7,
  RECUSADO_DN_PARA_DR = 8,
}
