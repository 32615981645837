<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">
  <form [formGroup]="importItensPreparationCenterForm">
    <div class="mg1 flex">
      <mat-form-field class="half-input matrix-select">
        <mat-select matSelect formControlName="source_event_id" placeholder="Aplicação" (selectionChange)="makeList()">
          <mat-option *ngFor="let application of applicationArray" [value]="application.id">
            {{application.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div *ngIf="isLoading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div *ngIf="!isLoading && itemsArray.length > 0">
      <p class="primary-text align-left">{{subtitle}}</p>
      <table mat-table [dataSource]="itemsArray" class="mat-elevation-z8 data-table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> * </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox #itemsCheckbox [value]="element.id"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Descrição </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Título </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </form>
</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="accent"
    [disabled]="!importItensPreparationCenterForm.valid" (click)="submitForm()">Importar</button>
</div>
