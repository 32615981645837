<app-modal-header [hasClose]='true' [title]="'AUTOCADASTRO'" (handleClose)="close()"></app-modal-header>

<div *ngIf="isLoading">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<mat-dialog-content class="content-dialog">

  <div *ngIf="!isLoading">
  <form class="not-apply-padding" [formGroup]="registerForm" *ngIf="!ifExists">
    <div class="register-form-content">
      <div id="profile-image-select" class="profile-image-select">
        <img id="profile-image" (click)="onPickImage()" [src]="preview_image_data.image"><br>

        <a id="pick-image-link" class="pick-photo" (click)="onPickImage()">Escolher Foto</a>
        <mat-error style="margin-left: 3.3rem;" *ngIf="registerForm.get('avatar_path').errors">
          {{registerForm.get('avatar_path').errors['message']}}</mat-error>
      </div>

      <div class="form-fields">
        <div class="form-pattern">
          <mat-form-field appearance="outline" class="user-name">
            <mat-label>Nome completo</mat-label>
            <input matInput formControlName="name" name="name" type="text" placeholder="Nome completo" required>
            <mat-error *ngIf="registerForm.get('name').errors && registerForm.get('name').touched">
              {{registerForm.get('name').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-form-field appearance="outline" class="half-input">
            <mat-label>Sexo</mat-label>
            <mat-select matSelect formControlName="gender" name="gender" placeholder="Sexo" required>
              <mat-option *ngFor="let gender of genders" [value]="gender.value">
                {{gender.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registerForm.get('gender').errors && registerForm.get('gender').touched">
              {{registerForm.get('gender').errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="half-input">
            <mat-label>Data de nascimento</mat-label>
            <input [textMask]="{mask: mask.date}" matInput formControlName="birth_date" name="birth-date" type="text"
              placeholder="Data de Nascimento" required>
            <mat-error *ngIf="registerForm.get('birth_date').errors && registerForm.get('birth_date').touched">
              {{registerForm.get('birth_date').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-form-field appearance="outline" class="half-input">
            <mat-label>CPF</mat-label>
            <input [textMask]="{mask: mask.cpf}" matInput formControlName="cpf_number" name="cpf-number" type="text"
              placeholder="CPF" required>
            <mat-error *ngIf="registerForm.get('cpf_number').errors && registerForm.get('cpf_number').touched">
              {{registerForm.get('cpf_number').errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="half-input">
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="email" name="email" type="text" placeholder="E-mail" required>
            <mat-error *ngIf="registerForm.get('email').errors && registerForm.get('email').touched">
              {{registerForm.get('email').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-form-field appearance="outline" class="half-input">
            <mat-label>Telefone fixo</mat-label>
            <input [textMask]="{mask: mask.phone}" matInput formControlName="home_phone" name="home-phone" type="text"
              placeholder="Telefone fixo" required>
            <mat-error *ngIf="registerForm.get('home_phone').errors && registerForm.get('home_phone').touched">
              {{registerForm.get('home_phone').errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="half-input">
            <mat-label>Telefone celular</mat-label>
            <input [textMask]="{mask: mask.cell_phone}" matInput formControlName="cell_phone" type="text"
              placeholder="Telefone celular">
            <mat-error *ngIf="registerForm.get('cell_phone').errors && registerForm.get('cell_phone').touched">
              {{registerForm.get('cell_phone').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-form-field appearance="outline" class="half-input">
            <mat-label>E-mail Adicional</mat-label>
            <input matInput formControlName="additional_email" type="text" placeholder="E-mail Adicional">
            <mat-error
              *ngIf="registerForm.get('additional_email').errors && registerForm.get('additional_email').touched">
              {{registerForm.get('additional_email').errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="half-input">
            <mat-label>DR</mat-label>
            <mat-select matSelect formControlName="regional" name="regional" placeholder="DR" required>
              <mat-option *ngFor="let dr of drsArray" [value]="dr.id">
                {{dr.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registerForm.get('regional').errors && registerForm.get('regional').touched">
              {{registerForm.get('regional').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-label></mat-label>
          <mat-form-field appearance="outline" class="half-input">
            <input matInput formControlName="password" name="original-password" type="password" placeholder="Senha"
              required>
            <mat-error *ngIf="registerForm.get('password').errors && registerForm.get('password').touched">
              {{registerForm.get('password').errors['message']}}</mat-error>
          </mat-form-field>


          <mat-label></mat-label>
          <mat-form-field appearance="outline" class="half-input">
            <input matInput formControlName="repeatPassword" name="repeat-password" type="password"
              placeholder="Confirmar senha" required>
            <mat-error *ngIf="registerForm.get('repeatPassword').errors && registerForm.get('repeatPassword').touched">
              {{registerForm.get('repeatPassword').errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div>
          <div *ngIf="!isContinuosIntegration()">
            <re-captcha class="captcha" language="pt-BR" (captchaResponse)="handleCorrectCaptcha($event)" (captchaExpired)="recaptchaExpired()"
              site_key="6Le7EXMUAAAAAEvNpc6qtRMbccxWB2rTGIvPytoz"></re-captcha>
          </div>
          <div class="box-clear"></div>
        </div>
      </div>
    </div>
  </form>
  </div>
</mat-dialog-content>

<div class="modal-footer" *ngIf="!isLoading">

  <button id="applyBtn" class="add-btn primary-action-btn" mat-raised-button *ngIf="!ifExists" color="primary" type="button" id="self-registration-submit-button" (click)="onRegisterSubmit()" [disabled]="!ifExists && !registerForm.valid">
    Registrar
  </button>

  <p class="message" *ngIf="ifExists">{{ message }}</p>

</div>
