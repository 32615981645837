
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

/**
 * Services
 */
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';

export interface Gender {
  value: string;
  viewValue: string;
}

export interface Detail {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-evaluator-manual-preview',
  templateUrl: './evaluator-manual-preview.component.html',
  styleUrls: ['./evaluator-manual-preview.component.css']
})

export class EvaluatorManualPreviewComponent implements OnInit {
  public evaluatorManualForm: UntypedFormGroup;
  public id: any;

  public evaluatorManualUrl: any;
  public evaluatorManualData: any;
  public evaluatorManualArt: any;
  public evaluatorManualHTML: any;

  constructor(
    private _fb: UntypedFormBuilder,
    private _crud: CrudService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EvaluatorManualPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.initevaluatorManualForm();

    if(this.evaluatorManualHTML){
      this.evaluatorManualData = {description: this.evaluatorManualHTML};
    }else{
      this.getevaluatorManualData();
    }

    if(typeof this.evaluatorManualArt == 'string'){
      var image:any = new Image();
      image.src = this.evaluatorManualUrl + this.evaluatorManualArt;
      this.evaluatorManualArt = image;
    }else{
      var image:any = new Image();
      var file:File = this.evaluatorManualArt;

      var myReader:FileReader = new FileReader();
      var that = this;
      myReader.onloadend = function (loadEvent:any) {
        image.src = loadEvent.target.result;
      };

      this.evaluatorManualArt = image;

      myReader.readAsDataURL(file[0]);
    }
  }

  initevaluatorManualForm(){
    this.evaluatorManualForm = new UntypedFormGroup({
      'description': new UntypedFormControl(null, [ValidateRequired])
    });
  }

  getevaluatorManualData = () => {
    this._crud.get('evaluators-manuals/'+this.id).then(res => {
      this.evaluatorManualData = res['obj'];
      var image:any = new Image();
      image.src = "http://api.sap.dev.al.senai.br/storage/evaluator-manual/" + res['obj']['filename'];
      this.evaluatorManualArt = image;
    });
  }

  initInvitationForm(res){

  }

  close = () => {
    this.dialogRef.close();
  }

  convertDate(date){
    var br_date = date;
    br_date = br_date.split('/');
    var us_date = br_date.reverse().join('-');
    return us_date
  }

  convertDateToBR(date){
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date
  }

  onevaluatorManualSubmit = () => {

  }
}
