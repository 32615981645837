import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';
import { ValidateRequired } from '@shared/validators/required.validator';

interface Student {
  course_id: number;
  appointment_id: number;
  exams_generated_id: number;
}
interface Exam {
  id: number;
  name: string;
  cpf_number: string;
  exam_particularity: string;
  course_id: number;
}

interface Data {
  student: Student;
  itens: Exam[];
  action: number;
  lote: any;
}
@Component({
  selector: 'app-exam-generated',
  templateUrl: './exam-generated.component.html',
  styleUrls: ['./exam-generated.component.css']
})
export class ExamGeneratedComponent implements OnInit {

  public title = 'GERAR';
  public btn = 'Gerar';
  public evaluatorManual = [];
  public exams = [];
  public examGeneratedForm: UntypedFormGroup;
  public loading = false;
  public erro = false;

  constructor(
    public dialogRef: MatDialogRef<ExamGeneratedComponent>,
    private crud: CrudService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {

    this.examGeneratedForm = new UntypedFormGroup({
      'ids': new UntypedFormControl([]),
      'course_id': new UntypedFormControl(this.data.student.course_id),
      'event_id': new UntypedFormControl(JSON.parse(sessionStorage.getItem('application')).id),
      'exam_id': new UntypedFormControl(null, [ValidateRequired]),
      'evaluator_manual_id': new UntypedFormControl(null, [ValidateRequired]),
      'reason': new UntypedFormControl(null, [ValidateRequired]),
      'appointment_id': new UntypedFormControl(this.data.student.appointment_id),
      'exams_generated_id': new UntypedFormControl(this.data.student.exams_generated_id)
    });

    // let course = this.data.student.course_id;
    const course = this.data.student.course_id || this.data.itens[0].course_id;

    this.crud.get('public/exams/with-context?where[]=is_active,1&where[]=course_id,' + course + '&noRelationship=true&show=id,particularity&order=particularity,asc&noPaginate=true')
      .then((res: any) => {
        this.exams = res.data || res;
      });

    this.crud.get('public/evaluator-manual?where[]=is_active,1&where[]=course_id,' + course + '&order=particularity,asc&noPaginate=true')
      .then((res: any) => {
        this.evaluatorManual = res.data || res;
        this.uniqueValue('evaluator_manual_id', this.evaluatorManual);
      });
  }

  ngOnInit() {

    if (this.data.action == 1) {
      this.examGeneratedForm.get('reason').setValidators([]);
      this.examGeneratedForm.get('reason').updateValueAndValidity();
    }

    if (this.data.action == 2) {
      this.title = 'REGERAR';
      this.btn = 'Regerar';
    }

  }

  close() {
    this.dialogRef.close();
  }

  examGenerated(): void {
    this.loading = true;
    if (this.data.lote) {
      this.loteExamGenerated(0);
      return;
    }

    const routeExamGeneration = 'exam-generated';
    // if (this.data.action == 2 && this.data.itens[0].exam_id == this.examGeneratedForm.get('exam_id').value) {
    //   routeExamGeneration += '/renew';
    // }

    this.crud.post(routeExamGeneration, this.examGeneratedForm.value)
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
        this.loading = false;
      }).catch((err: any) => {
        this.loading = false;
      });
  }

  getExam = (item, count) => {

    const exam = {
      course_id: item.course_id,
      event_id: this.examGeneratedForm.value.event_id,
      exam_id: this.examGeneratedForm.value.exam_id,
      evaluator_manual_id: this.examGeneratedForm.value.evaluator_manual_id,
      exams_generated_id: this.examGeneratedForm.value.exams_generated_id,
      appointment_id: item.appointment_id
    };

    return this.crud.post('exam-generated', exam).then(res => {
      this.data.itens.splice(this.data.itens.findIndex(el => el.id == item.id), 1);
      this.loteExamGenerated(count);
    }).catch(err => {
      this.loading = false;
      this.erro = true;
      this.toastr.error(err.message, 'Erro!');
    });

  }

  loteExamGenerated(count) {

    if (this.data.itens.length > count) {
      return this.getExam(this.data.itens[count], count);
    } else {
      this.loading = false;
      this.dialogRef.close(true);
      return this.toastr.success('Operação realizada com sucesso.', 'Sucesso!');
    }

  }

  uniqueValue(attr, data) {

    const value = data[0].id;
    if (data.length == 1) {
      this.examGeneratedForm.controls[attr].setValue(value);
    }

  }
}
