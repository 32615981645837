<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="applicationProfileForm" #formDirective="ngForm">
    <div class="options">
      <div class="form-pattern flex">
        <mat-form-field appearance="outline" class="half-input">
          <mat-label>{{ loading ? 'Carregando...' : 'Aplicação' }}</mat-label>
          <mat-select matSelect formControlName="event_id" placeholder="{{ loading ? 'Carregando...' : 'Aplicação' }}" required>
            <mat-option *ngFor="let application of applicationsArray" [value]="application.id">
              {{application.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="applicationProfileForm.get('event_id').errors && applicationProfileForm.get('event_id').touched">
            {{applicationProfileForm.get('event_id').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-input">
          <mat-label>Perfis de Acesso</mat-label>
          <mat-select matSelect formControlName="access_profile_id" placeholder="Perfis de Acesso" (selectionChange)="verifyRequeriments()" required>
            <mat-option *ngFor="let profile of profileArray" [value]="profile.invite_profile_id">
              {{profile.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="applicationProfileForm.get('access_profile_id').errors && applicationProfileForm.get('access_profile_id').touched">
            {{applicationProfileForm.get('access_profile_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-pattern flex">
        <mat-form-field appearance="outline" *ngIf="showSchools">
          <mat-label>Escola</mat-label>
          <mat-select matSelect formControlName="school_id" placeholder="Escola" [disabled]="!showSchools" required (selectionChange)="filterCourse($event)">
            <mat-option *ngFor="let school of schoolArray" [value]="school.id">
              {{school.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="applicationProfileForm.get('school_id').errors && applicationProfileForm.get('school_id').touched">
            {{applicationProfileForm.get('school_id').errors['message']}}</mat-error>
        </mat-form-field>

      </div>
      <div class="form-pattern flex">
        <mat-form-field appearance="outline" *ngIf="showCourses">
          <mat-label>Curso</mat-label>
          <mat-select matSelect formControlName="course_id" placeholder="Curso" [disabled]="applicationProfileForm.get('school_id').invalid">
            <mat-option *ngFor="let course of coursesArray" [value]="course.id">
              {{course.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="applicationProfileForm.get('course_id').errors && applicationProfileForm.get('course_id').touched">
            {{applicationProfileForm.get('course_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button [disabled]="applicationProfileForm.invalid" (click)="onApply()">
    Aplicar Perfil
  </button>
  <!-- <button type="button" mat-raised-button class="cancel-btn" (click)="close()">
    Cancelar
  </button> -->
</div>
