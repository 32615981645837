import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';

@Component({
  selector: 'app-preparation-test',
  templateUrl: './preparation-test.component.html',
  styleUrls: ['./preparation-test.component.css']
})
export class PreparationTestComponent implements OnInit, OnDestroy {
  public letters = ['A', 'B', 'C', 'D', 'E'];
  public test: any;
  public testForm: any;
  public formState = 0;
  public counter: any = 0;
  public timer: any;
  public minimum_approval_percentage: any = 80;

  constructor(
    public dialogRef: MatDialogRef<PreparationTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private crud: CrudService,
    private fb: UntypedFormBuilder,
  ) {
    this.testForm = this.fb.group({
      questions: this.fb.array([])
    })
  }

  ngOnInit() {
    this.crud.get('parameters/' + 19).then(r => this.minimum_approval_percentage = r['value']);

    if (this.data.view) {

      this.test = this.data.test;

      this.renderTest();

    } else {

      this.crud.get('preparation-test/' + this.data.id).then(res => {

        this.test = res;

        this.renderTest();

        this.setTimer();

      });

    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  renderTest() {
    this.counter = this.test.duration || 0;

    this.test.questions.sort(_ => 0.5 - Math.random());

    const control = this.testForm.get('questions');

    this.test.questions.forEach(r => control.push(this.getQuestion()));
  }

  getQuestion() {
    return this.fb.group({
      selected: [''],
    });
  }

  completeTest(): any {
    this.formState = 1;
    let is_correct_count = this.test.questions.reduce((acc, x, i) => {
      let aux, index = x.alternatives.findIndex(a => a.is_correct);
      if (index >= 0) aux = this.letters[index];
      return this.testForm.value.questions[i].selected == aux ? acc = acc + 1 : acc;
    }, 0);

    document.getElementById('scrollWindow').scrollTo(0, 0);

    clearTimeout(this.timer);

    if ((is_correct_count / (this.test.questions.length) * 100) >= this.minimum_approval_percentage) {

      this.test.result = `PARABÉNS! Você acertou ${Math.floor((is_correct_count / (this.test.questions.length) * 100)) || 0}% das questões e foi aprovado nesse teste de preparação!`;

      if (!this.test.attendance)
        this.crud.post('medias/attendance', {
          "media_id": this.data.media_id,
          "action": "Completo",
          "user_id": JSON.parse(sessionStorage.getItem('user_id')),
          "time": (this.test.duration - this.counter) > 0 ? this.test.duration - this.counter : 1
        });

      return this.test.attendance = true;
    }

    this.test.result = `Você acertou ${Math.floor((is_correct_count / (this.test.questions.length) * 100)) || 0}% das questões e foi reprovado nesse teste de preparação!`;
  }

  isCorrect(a, i) {
    let aux, index = this.test.questions[i].alternatives.findIndex(a => a.is_correct);
    if (index >= 0) aux = this.letters[index];
    return a == aux;
  }

  setTimer() {
    this.timer = setTimeout(() => {

      clearTimeout(this.timer);

      this.counter -= 1;

      if (this.counter == 0) {

        clearTimeout(this.timer);

        this.completeTest();

      } else {

        this.setTimer();

      }

    }, 60000);
  }

  isSelected(letter, i, v) {
    return this.testForm.value.questions[i].selected == letter;
  }

  getMinToHms(secs) {
    let sec_num = parseInt(secs, 10)
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60
    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .join(":")
  }

  tryAgain() {
    this.dialogRef.close(true);
  }

}
