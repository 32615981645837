import { Component, OnInit, Output, EventEmitter, Inject, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { AlertConfirmComponent } from '../alert-confirm/alert-confirm.component';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-relate-course-to-application',
  templateUrl: './relate-course-to-application.component.html',
  styleUrls: ['./relate-course-to-application.component.css']
})
export class RelateCourseToApplicationComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('profileCheckbox') private profileCheckbox: QueryList<any>;

  isLoading = true;
  public applicationsArray: any;
  public courseArray: any;
  public userArray: any = [];
  msg;
  relateCourse: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<Event[]>;
  options: Event[] = [];
  application_id: any;
  selectedCourses: any;
  public finalValue: boolean = true;

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<RelateCourseToApplicationComponent>,
    private router: Router,
    private _crud: CrudService,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.listApplications();
    this.listProfiles();
    this.newForm();
  }

  loadAddedCourses = () => {
    this._crud.post('course-event/general-search', {
      "fields": {},
      "dates": {},
      "where": {
        'event_id': this.application_id
      },
      "limit": null,
      "noPaginate": true,
      "page": null
    }).then(res => {
      this.selectedCourses = res['data'];
    });
  }

  checkCourse = (course_id) => {
    let result = false;

    for(let j=0; j<this.selectedCourses.length;j++) {
      let courseId = this.selectedCourses[j].course_id;
      if (courseId == course_id) {
        result = true;
      }else{
        if(result != true){
          result = false;
        }
      }
    }

    return result;
  }

  newForm(){
    this.relateCourse = new UntypedFormGroup({
      'event_id': new UntypedFormControl(this.application_id),
      'courses': new UntypedFormControl(''),
      'deleted_at': new UntypedFormControl(null)
    });
  }

  listProfiles = () => {
    this._crud.get('public/courses?order=description,asc&noRelationship=true').then(res => {
      this.courseArray = res['data'];
      this.loadAddedCourses();
    });
  }

  listApplications = () => {
    this._crud.post('events/general-search', {
      "fields": {
        "description": null
      },
      "dates": {},
      "orderBy": {
        "description": "asc"
      },
      "where": {},
      "limit": 5,
      "page": 1
    }).then(res => {
      this.applicationsArray = res['data'];

      for(let i = 0; i < this.applicationsArray.length; i++){
        var user: Event = {id: this.applicationsArray[i].id.toString(), description:this.applicationsArray[i].description};

        this.options.push(user);
      }

      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | Event>(''),
        map(value => typeof value === 'string' ? value : value.description),
        map(name => name ? this._filter(name) : this.options.slice())
      );
    });
  }

  displayFn(user?: Event): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): Event[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0);
  }

  close = () => {
    this.dialogRef.close();
  }

  setValidationForm = () => {
    this.finalValue = true;
    if(this.courseArray){
      const profileCheckbox = this.profileCheckbox.toArray();
      for (let i = 0; i < profileCheckbox.length; i++) {
        if (profileCheckbox[i].checked && this.finalValue == true) {
          this.finalValue = false;
        }else{
        }
      }
    }

    return this.finalValue;
  }

  relateCourseSubmit = () => {
    let event = this.myControl.value;
    let courseIds = [];

    const profileCheckbox = this.profileCheckbox.toArray();
    for (let i = 0; i < profileCheckbox.length; i++) {
      if (profileCheckbox[i].checked) {
        courseIds.push(profileCheckbox[i].value.toString());
      }
    }

    this.relateCourse.get('courses').setValue(courseIds);

    this._crud.post('course-event', this.relateCourse.value)
      .then(res => {
        this.toastr.success("Cursos associados com Sucesso!", 'Sucesso!');
        this.dialogRef.close();
      });
  }

  selectAllCourses(value){
    const profileCheckbox = this.profileCheckbox.toArray();
    if(value._checked == false){
      for (let i = 0; i < profileCheckbox.length; i++) {
        profileCheckbox[i].checked = true;
      }
    }else{
      for (let i = 0; i < profileCheckbox.length; i++) {
        profileCheckbox[i].checked = false;
      }
    }
  }
}
