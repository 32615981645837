<app-modal-header [hasClose]='true' [title]="'INCLUIR DESTINATÁRIO(S)'" (handleClose)="close()"></app-modal-header>

<mat-progress-bar mode="query" *ngIf="loading" style="margin: 10px 0px;"></mat-progress-bar>

<mat-dialog-content class="content-dialog" *ngIf="!loading">

  <div class="row">
    <div>
      <mat-radio-group [(ngModel)]="type" (change)="generateForm($event)">
        <mat-radio-button checked value="1">GRUPO DE USUÁRIOS</mat-radio-button>
        <mat-radio-button value="2">INDIVIDUAL</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <form [formGroup]="form" focusFirstInvalidField (ngSubmit)="form.valid && myControl.valid && addRecipient()">

    <ng-container *ngIf="type == '1'">

      <div class="row">
        <div>
          <mat-form-field class="half-input" >
            <mat-select matSelect formControlName="profile_id" placeholder="Perfil de Acesso *" (selectionChange)="verifyRequeriments($event)">
              <mat-option *ngFor="let item of profiles" [value]="item">
                {{item.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('profile_id').errors && form.get('profile_id').touched">
              {{form.get('profile_id').errors['message']}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="form.dirty">
        <div class="row">
          <div>
            <mat-form-field class="half-input">
              <mat-select matSelect formControlName="regional_id" placeholder="Departamento Regional" (selectionChange)="filterSchool($event)">
                <mat-option value="" selected>Todos</mat-option>
                <mat-option *ngFor="let dr of drs" [value]="dr.id">
                  {{dr.description}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('regional_id').errors && form.get('regional_id').touched">
                {{form.get('regional_id').errors['message']}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div>
            <mat-form-field>
              <mat-select matSelect formControlName="school_id" placeholder="Escolas" (selectionChange)="filterCourse($event)">
                <mat-option value="" selected>Todos</mat-option>
                <mat-option *ngFor="let school of schools" [value]="school.id">
                  {{school.description}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('school_id').errors && form.get('school_id').touched">
                {{form.get('school_id').errors['message']}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div>
            <mat-form-field>
              <mat-select matSelect formControlName="course_id" placeholder="Curso">
                <mat-option value="" selected>Todos</mat-option>
                <mat-option *ngFor="let course of courses" [value]="course.id">
                  {{course.description}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('course_id').errors && form.get('course_id').touched">
                {{form.get('course_id').errors['message']}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type == '2'">
      <mat-form-field class="example-full-width">
        <input type="text" placeholder="Usuários" matInput [formControl]="myControl" [matAutocomplete]="auto" required>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="myControl.errors && myControl.touched">
          {{myControl.errors['message']}}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="modal-footer">
      <button mat-raised-button type="button" color="basic" style="margin-right: 15px;" (click)="resetForm()" [disabled]="!form.dirty">
        Redefinir
      </button>

      <button mat-raised-button color="accent" class="primary-action-btn">
        Adicionar
      </button>
    </div>

  </form>

</mat-dialog-content>
