import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatColumnDef, MatTableDataSource } from '@angular/material/table';
import { CrudService } from '@services/laravel/crud.service';
import { Router, NavigationExtras } from '@angular/router';
import { DeleteConfirmComponent } from '@shared/components/delete-confirm/delete-confirm.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { environment } from '@env/environment';

import { ListViewComponent } from '@shared/components/list-view/list-view.component';
import { MatrixSaepViewComponent } from '@main/components/matrix-saep-view/matrix-saep-view.component';
import { AssociateEventsComponent } from '@shared/components/associate-events/associate-events.component';
import { LoadingModalComponent } from '@shared/components/loading-modal/loading-modal.component';
import { SnackBarService } from '@services/snackbar.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-ntm-table-data',
  templateUrl: './ntm-table-data.component.html',
  styleUrls: ['./ntm-table-data.component.css'],
  providers: [MatPaginatorIntl]
})
export class NtmTableDataComponent implements OnInit, OnChanges {
  @ViewChild('searchKey', { static: false, read: MatInput }) searchKeyFocus: MatInput;
  @Input() params;
  @ViewChildren('myCheckbox') private myCheckboxes: QueryList<any>;
  @Input('matColumnDef') public matColumnDef: MatColumnDef;
  @Output() actionToOutput = new EventEmitter();
  public displayedColumns = [];
  public dataSource = new MatTableDataSource([]);
  public searchInput = false;
  public prepositionLabel = 'de';
  public arraySource = [];
  public itensToDeleteIds = [];
  public disableSearch = false;
  public advancedSearch = false;
  public advancedTimer: any;
  public advancedSearchForm: UntypedFormGroup;
  public notEdit = false;
  public isLoading = true;
  public isDynamicallyLoading = false;
  public orderBy = {};
  public sortBy = '';
  public sortByDesc = '';
  public page: any = 1;
  public itemsPerPage = 10;
  public hidePagination;
  public order = '';
  public lastPage: any = 1;
  public total = 0;
  public mask = {
    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    date: [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-2]/, /[0-9]/, /[0-9]/, /[0-9]/],
    zip: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,],
    cell_phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  };
  public dates = {};
  public pesquisa: any = {};
  public cont = 0;
  public filteredOptions: any = {};
  public filtereds: any = {};
  public pesquisaArray: any = [];
  public searching = false;
  public appearance: MatFormFieldAppearance = 'fill';

  statusArray: any;
  public justify = false;
  checkAllController = false;
  public userRegisterForm: UntypedFormGroup;
  public studentCourseStatusForm: UntypedFormGroup;


  constructor(
    private _crud: CrudService,
    private _paginator: MatPaginatorIntl,
    public dialog: MatDialog,
    public _snackbar: MatSnackBar,
    private router: Router,
    public snackBarService: SnackBarService,
    private toastr: ToastrService
  ) {
    this._paginator.nextPageLabel = 'Próximo';
    this._paginator.previousPageLabel = 'Anterior';
    this._paginator.itemsPerPageLabel = 'Registros por página';
    this._paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) { return `0 ${this.prepositionLabel} ${length}`; }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${this.prepositionLabel} ${length}`;
    };
  }

  ngOnInit() {
    if (this.params.list.limit) {
      this.itemsPerPage = this.params.list.limit;
    }

    if (this.params.list.hidePagination) {
      this.hidePagination = this.params.list.hidePagination;
    }

    if (this.params.list.notEdit) {
      this.notEdit = true;
    }

    if (this.params.list.permissions.indexOf('Remover') >= 0) {
      this.displayedColumns.push('id');
    }

    this.params.list.columns.map(x => this.displayedColumns.push(x.columnDef));

    if ((this.params.list.actionButton || this.params.list.edit || this.params.list.expansion || this.params.list.version || this.params.list.editColumnIcon)) {
      this.displayedColumns.push('updated_at_c');
    }

    if (this.params.list.actionButton) { this.displayedColumns.push('entrar'); }

    if (this.params.toolbar.disableSearch) { this.disableSearch = true; }
    if (this.params.toolbar.advancedSearch) { this.advancedSearch = true; }

    const object = {};
    if (this.params.toolbar.search) {
      this.params.toolbar.search.forEach(element => {
        object[element.field] = new UntypedFormControl(null);
      });
    }

    if (this.params.data && this.params.data.search.orderBy) { this.orderBy = this.params.data.search.orderBy; }

    if (this.params.list.sortBy) { this.sortBy = this.params.list.sortBy; }

    if (this.params.list.sortByDesc) { this.sortByDesc = this.params.list.sortByDesc; }

    this.advancedSearchForm = new UntypedFormGroup(object);

    this.searchAdvance();
    this.middleware();
  }

  ngOnChanges() {
    this.isLoading = true;
    this.searchAdvance();
    if (!this.params.list.permissionsOuther) {
      this.params.list.permissionsOuther = [];
    }
  }


  searchAdvance(page = true) {
    this.isDynamicallyLoading = true;
    this.itemsPerPage = (this.itemsPerPage < 10) ? 10 : this.itemsPerPage;
    if (page) { this.page = 1; }
    clearTimeout(this.advancedTimer);
    this.advancedTimer = setTimeout(async _ => {
      if (!this.params.data) {
        const res: any = await this._crud.get(this.params.list.route + '?page=' + this.page + '&limit=' + this.itemsPerPage + '&order=' + this.order);
        this.arraySource = res.data || res;
        this.filterValues();
        this.dataSource = new MatTableDataSource(this.arraySource);
        this.total = res.total;
        this.lastPage = res.last_page;
        this.isLoading = false;
        this.setOutput('array', this.arraySource);
        this.isDynamicallyLoading = false;
      }

      this._crud.post(
        this.params.list.route,
        {
          'fields': this.advancedSearchForm.value,
          'dates': this.dates,
          'orderBy': this.orderBy,
          'where': this.params.data.search.where || {},
          'limit': this.itemsPerPage,
          'page': this.page,
          'event': this.params.data.search.event,
          'noRelationship': this.params.data.search.noRelationship,
          'sortBy': this.sortBy,
          'sortByDesc': this.sortByDesc
        }
      ).then((res: any) => {
        this.arraySource = res.data || res;
        this.filterValues();
        this.dataSource = new MatTableDataSource(this.arraySource);
        this.total = res.total;
        this.lastPage = res.last_page;
        this.isLoading = false;
        this.setOutput('array', this.arraySource);
        this.isDynamicallyLoading = false;
      }).catch((rej: any) => {
        this.isLoading = false;
        this.isDynamicallyLoading = false;
      });
    }, 300);
  }

  searchDate(field) {
    let numbers = 0;
    this.dates[field] = this.advancedSearchForm.controls[field].value;
    for (let lim = this.advancedSearchForm.get(field).value.length, i = 0; i < lim; i++) {
      if (!isNaN(this.advancedSearchForm.get(field).value[i])) { ++numbers; }
    }

    if (numbers == 8 || !numbers) { this.searchAdvance(); }
  }

  filterValues() {
    if (this.params.list.edit && this.params.list.edit.condition) {
      this.arraySource = this.arraySource.map((el, i) => {
        el.noEdit = eval(this.params.list.edit.condition) ? false : true;
        return el;
      });
    }

    if (this.params.list.version && this.params.list.version.condition) {
      this.arraySource = this.arraySource.map((el, i) => {
        el.noVersion = eval(this.params.list.version.condition) ? false : true;
        return el;
      });
    }
  }

  middleware() {
    if (this.params.toolbar) {
      if (this.cont < this.params.toolbar.search?.length - 1) {
        this.cont++;
        this.make();
      } else {
        this.searching = true;
      }
    }
  }

  make(): void {
    if (this.params.toolbar && !this.params.toolbar.search) {
      this.searching = true;
      return;
    }
    const element: any = this.params.toolbar ? this.params.toolbar.search[this.cont] : null;
    if (element && element.route) {
      this.pesquisa[element.field] = new UntypedFormControl();
      this._crud.get(element.route).then((res: any) => {
        this.pesquisaArray[element.field] = res.data || res;
        this.filtereds[element.field] = res.data || res;
        this.filteredOptions[element.field] = this.pesquisa[element.field].valueChanges
          .pipe(
            startWith('')
            , map(value => this.filter(value, this.filtereds[element.field], element.filter))
          );
        this.middleware();
      });
    } else {
      this.middleware();
    }
  }

  filter(name, param, filter) {
    return name ? param.filter(s => typeof s[filter] != 'object' ? s[filter].toLowerCase().indexOf(name.toLowerCase()) === 0 :
      s[filter]['description'].toLowerCase().indexOf(name.toLowerCase()) === 0) : param;
  }

  filterArray(param, event) {
    const reset = [];
    if (param.next_filter) {
      this.pesquisaArray[param.next_filter].map(item => {
        if (item[param.dataToFilter]['description'] === event.option.value) {
          if (param.dataToFilter === 'school' && !('description' in item)) {
            item['id'] = item['course']['id'];
            item['description'] = item['course']['description'];
          }
          reset.push(item);
        }
      });

      this.filtereds[param.next_filter] = reset;
      this.filteredOptions[param.next_filter] = this.pesquisa[param.next_filter].valueChanges
        .pipe(
          startWith('')
          , map(value => this.filter(value, this.filtereds[param.next_filter], param.filter))
        );
    }
  }

  applyFilterOnKeyUp(event: KeyboardEvent) {
    let input = (event.target as HTMLInputElement).value;
    input = input.trim();
    input = input.toLowerCase();
    sessionStorage.setItem('pesquisa', input);
    this.dataSource.filter = input;
  }

  searchInputToggle = () => {
    this.searchInput = !this.searchInput;
    this.searchKeyFocus.focus();
    if (!this.searchInput) {
      this.searchKeyFocus.value = '';
      this.searchAdvance();
    }
  }

  onClickEdit = (route, param) => {
    if (param.key === 'commitment_term') {
      this.router.navigate(['/main/commitment-term']);
    } else {
      let value = param[this.params.list.edit.param] ? param[this.params.list.edit.param] : '';
      if (this.params.list.edit.array_params) { value = eval('param.' + this.params.list.edit.array_params.join('.')); }
      const finalRoute = [route + ':' + value];
      if (this.params.list.edit.additional_routing) {
        const params: NavigationExtras = { queryParams: { ...this.params.list.edit.additional_routing, id: value } };
        this.router.navigate(finalRoute, params);
      } else {
        this.router.navigate(finalRoute);
      }
    }
  }

  openDialogToDelete = (element?) => {
    let dialogMessage = '', height = '250px';
    if (this.params.toolbar && this.params.toolbar.deleteMessage) {
      dialogMessage = this.params.toolbar.deleteMessage;
    }

    if (this.params.toolbar.delete[0].justify) {
      this.justify = this.params.toolbar.delete[0].justify;
      height = '303px';
    }

    const arrItems = [];
    if (element) {
      this.itensToDeleteIds.push(element.id);
    }


    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '450px',
      height: 'auto',
      // panelClass: "with-padding",
      data: {
        routeToApi: this.params.toolbar.delete[0].routeToApi,
        routeAfterDelete: this.params.toolbar.delete[0].routeAfterDelete,
        paramToDelete: this.itensToDeleteIds,
        justify: this.justify,
        dialogMessage: dialogMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let array: any;
      let string: string;

      // this.uncheckAll();
      this.itensToDeleteIds = [];
      this.searchAdvance();
      this.setOutput('toggle');

      this.checkAllController = false;
    });

  }


  setOutput: any = (value, data?) => {
    data = data || {};
    const response = {
      referenceToAction: value,
      data
    };
    this.actionToOutput.emit(response);
  }

  toggleAllSelections(event) {
    if (event.checked) {
      this.itensToDeleteIds = this.arraySource.map(i => i.id || i.matrix_id || i.order_id);
    } else {
      this.itensToDeleteIds = [];
    }
  }

  toggleChecked(event, item) {
    const id = item.id || item.matrix_id || item.order_id;
    if (this.params.toolbar && this.params.toolbar.deleteMessage) {
      this.params.toolbar.deleteMessage = this.params.toolbar.deleteMessage.replace('<description>', item.description);
    }
    if (event.checked) {
      this.itensToDeleteIds.push(id);
    } else {
      this.itensToDeleteIds.splice(this.itensToDeleteIds.indexOf(id), 1);
    }
  }

  isOnSelectedArray(item) {
    const id = item.id || item.matrix_id || item.order_id;
    return this.itensToDeleteIds.indexOf(id) != -1;
  }

  sortTable(event) {
    this.order = event.active + ',' + event.direction;
    this.orderBy = {};
    this.orderBy[event.active] = event.direction;
    if (!event.direction) { this.order = ''; }
    this.searchAdvance();
  }

  onClickPage(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.searchAdvance(false);
  }

  listCoursesStatus = () => {
    this._crud.get('studends-status').then(res => {
      this.statusArray = res['obj'];
    });
  }

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
      width: '520px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  openDialogToReSend = (route, invite) => {
    if (invite.invite_status_id == 1) {
      let dialogMessage = '', height = '250px';
      if (this.params.toolbar && this.params.toolbar.deleteMessage) {
        dialogMessage = this.params.toolbar.deleteMessage;
      }

      const dialogRef = this.dialog.open(DeleteConfirmComponent, {
        width: '450px',
        height: 'auto',
        data: {
          resend: true,
          routeToApi: 'invites/resend/' + invite.id,
          routeAfterDelete: '/main/invitation',
          paramToDelete: invite.id,
          justify: this.justify,
          dialogTitle: 'Re-enviar Convite',
          dialogMessage: 'Deseja re-enviar o convite selecionado?'
        }
      });
    }
  }

  openCoverSheet(coverSheet) {
    this.loadingMessage();

    this._crud.file('cover-sheet/preview', {
      'id': coverSheet.id
    }).then(res => {
      saveAs(res, 'folha_rosto_preview.pdf');
      this.dialog.closeAll();
    }).catch((err) => {
      this.dialog.closeAll();
    });
  }

  openEvaluatorManual(manual) {
    this.loadingMessage();

    this._crud.file('evaluators-manuals/preview', {
      'id': manual.id
    }).then(res => {
      let name = 'manual_do_avaliador';
      name += ' - ' + manual?.course?.description.replace(/ /g, '_').toLowerCase();
      name += ' - ' + manual?.year;

      saveAs(res, name + '.pdf');
      this.dialog.closeAll();
    }).catch((err) => {
      this.dialog.closeAll();
    });
  }

  openExam(exam) {
    this.loadingMessage();

    this._crud.file('exams/preview', {
      'id': exam.id
    }).then(res => {
      let name = 'prova_preview';
      name += ' - ' + exam?.course?.description?.replace(/ /g, '_').toLowerCase();
      name += ' - ' + exam?.particularity?.replace(/ /g, '_').toLowerCase();

      saveAs(res, name + '.pdf');
      this.dialog.closeAll();
    }).catch((err) => {
      this.dialog.closeAll();
    });
  }

  openListModal(route, storageRoute, manual) {
    this._crud.get(route + '/' + manual.id).then((res: any) => {
      const dialogRef = this.dialog.open(ListViewComponent, {
        minWidth: '500px',
        maxWidth: '90%',
        height: 'auto',
        minHeight: '200px',
        data: {},
        disableClose: true,
      });

      dialogRef.componentInstance.modalTitle = 'Anexos';
      dialogRef.componentInstance.downloadRoute = environment.urlToApiPreviewFiles + storageRoute;
      dialogRef.componentInstance.storageRoute = storageRoute;
      dialogRef.componentInstance.arrayList = res;
      dialogRef.componentInstance.showDownloadOption = true;

      if (!res.length) { dialogRef.componentInstance.message = 'Não existe anexo para esse exame.'; }

      dialogRef.afterClosed().subscribe(result => {
        const array = [];
        const string = '';
      });
    });
  }

  updateUserStatus(value) {
    if (this.params.list.route == 'users' && value.id == sessionStorage.getItem('user_id')) {
      this.snackBarService.add('Você não pode desativar o seu usuário.');

      // this.uncheckAll();
      this.itensToDeleteIds = [];
      this.searchAdvance();
      this.setOutput('toggle');
    } else {
      this.userRegisterForm = new UntypedFormGroup({
        'id': new UntypedFormControl(null),
        'is_active': new UntypedFormControl(null),
      });
      this.userRegisterForm.value.id = value.id;
      this.userRegisterForm.value.is_active = this.setUserStatus(value.is_active);

      const objUpdate = this.userRegisterForm.value;

      const objUser = Object.assign({}, objUpdate);

      const formData: any = new FormData();

      for (const key in objUser) {
        formData.append(key, objUser[key]);
      }

      formData.append('_method', 'PUT');

      this._crud.post(this.params.list.route + '/' + this.userRegisterForm.value.id, formData)
        .then(res => {
          const message = 'Status editado com sucesso.';

          this.userRegisterForm.controls['is_active'].setValue(true);
          this._snackbar.open(message, '', {
            duration: 2000,
            panelClass: 'success-snackbar'
          });
          // this.uncheckAll();
          this.itensToDeleteIds = [];
          this.searchAdvance();
          this.setOutput('toggle');
        }, rej => {
          for (let i = 0; i < rej['errors'].length; i++) {
          }
        });
    }
  }

  updateCourseStatus(studentId, courseId, statusId) {
    this.studentCourseStatusForm = new UntypedFormGroup({
      'id': new UntypedFormControl(null),
      'student_id': new UntypedFormControl(null),
      'courses': new UntypedFormControl(null),
      'status_id': new UntypedFormControl(null),
    });
    this.studentCourseStatusForm.value.id = studentId;
    this.studentCourseStatusForm.value.student_id = studentId;
    this.studentCourseStatusForm.value.courses = [{ course_id: courseId, student_id: studentId, status_id: statusId.value }];
    this.studentCourseStatusForm.value.status_id = statusId.value;

    const objUpdate = this.studentCourseStatusForm.value;


    this._crud.put('students/' + this.studentCourseStatusForm.value.id, objUpdate)
      .then(res => {
        const message = 'Status editado com sucesso.';

        this._snackbar.open(message, '', {
          duration: 2000,
          panelClass: 'success-snackbar'
        });
        // this.uncheckAll();
        this.itensToDeleteIds = [];
        this.searchAdvance();
        this.setOutput('toggle');
      }, rej => {
        for (let i = 0; i < rej['errors'].length; i++) {
        }
      });
  }

  onViewReferenceMatrix(route, matrix) {
    const dialogRef = this.dialog.open(MatrixSaepViewComponent, {
      width: '1300px',
      height: '95%',
      data: matrix
    });

  }

  onAssociateApplications = (user_id) => {
    const dialogRef = this.dialog.open(AssociateEventsComponent, {
      width: '600px',
      height: 'auto'
    });

    dialogRef.componentInstance.user_id = user_id;

    dialogRef.afterClosed().subscribe(result => {
      this.searchAdvance();
    });
  }

  setUserStatus(status) {
    if (status == true) {
      return '0';
    } else {
      return '1';
    }
  }


  checkException = (element, type) => {
    let exceptions, retorno;
    const aux = [];
    if (type === 'Delete') {
      this.params.toolbar.delete[0].exception ? exceptions = this.params.toolbar.delete[0].exception : exceptions = null;
    } else {
      this.params.list.edit.exception ? exceptions = this.params.list.edit.exception : exceptions = null;
    }

    if (exceptions !== null) {
      for (let x = 0; x < exceptions.length; x++) {
        if (exceptions[x].value.toString() === element[exceptions[x].field].toString()) {
          aux.push(element);
        }
      }

      (aux.length > 0) ? retorno = false : retorno = true;

      return retorno;
    } else {
      return true;
    }
  }
}
