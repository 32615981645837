<app-modal-header [hasClose]='true' [title]="'Clonagem de Matriz'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content">
  <p class="message primary-text">Escolha o evento e o curso para onde a matriz deve ser clonada.</p>

  <form [formGroup]="cloneMatrixForm">
    <div>
      <div class="form-pattern flex">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="application_id" placeholder="Aplicação" required (selectionChange)="changeApplications($event)">
            <mat-option *ngFor="let application of applicationArray" [value]="application.id">
              {{application.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cloneMatrixForm.get('application_id').errors && cloneMatrixForm.get('application_id').touched">
            {{cloneMatrixForm.get('application_id').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="course_id" placeholder="Curso" required (selectionChange)="changeCourses($event)">
            <mat-option *ngFor="let course of courseArray" [value]="course.id">
              {{course.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cloneMatrixForm.get('course_id').errors && cloneMatrixForm.get('course_id').touched">
            {{cloneMatrixForm.get('course_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <button id="modalApplyBtn" type="button" mat-raised-button color="primary" class="secondary primary-text" (click)="clone()" [disabled]="!cloneMatrixForm.valid">
      Clonar Matriz
    </button>

  </form>

</mat-dialog-content>
