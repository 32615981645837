import { Component, OnInit, Output, EventEmitter, Inject, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  id?: string;
  description?: string;
}

@Component({
  selector: "ntm-import-users-from-event",
  templateUrl: "./import-users-from-event.component.html",
  styleUrls: ["./import-users-from-event.component.css"],
})
export class ImportUsersFromEventComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren("profileCheckbox") private profileCheckbox: QueryList<any>;

  isLoading = true;
  public applicationsArray: any;
  public profileArray: any;
  public userArray: any = [];
  msg;
  importUsersForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<Event[]>;
  options: Event[] = [];
  application_id: any;
  public finalValue: boolean = true;

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportUsersFromEventComponent>,
    private router: Router,
    private _crud: CrudService,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
    this.importUsersForm = new FormGroup({
      event: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.listApplications();
    this.listProfiles();
  }

  listProfiles = () => {
    this.isLoading = true;
    this._crud.get("public/profile?order=description,asc").then((res) => {
      this.profileArray = res["data"];
      this.isLoading = false;
    });
  };

  listApplications = () => {
    this.isLoading = true;
    this._crud
      .post("events/general-search", {
        orderBy: {
          created_at: "desc",
        },
      })
      .then((res) => {
        this.applicationsArray = res["data"];

        for (let i = 0; i < this.applicationsArray.length; i++) {
          var user: Event = {
            id: this.applicationsArray[i].id.toString(),
            description: this.applicationsArray[i].description,
          };

          this.options.push(user);
        }

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith<string | Event>(""),
          map((value) =>
            typeof value === "string" ? value : value.description
          ),
          map((name) => (name ? this._filter(name) : this.options.slice()))
        );
        this.isLoading = false;
      });
  };

  displayFn(user?: Event): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): Event[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(
      (option) => option.description.toLowerCase().indexOf(filterValue) === 0
    );
  }

  close = () => {
    this.dialogRef.close();
  };

  setValidationForm = () => {
    this.finalValue = true;
    if (this.profileArray) {
      const profileCheckbox = this.profileCheckbox.toArray();
      for (let i = 0; i < profileCheckbox.length; i++) {
        if (profileCheckbox[i].checked && this.finalValue == true) {
          return false;
        }
      }
    }

    return this.finalValue;
  };

  importUsersFromEvent = () => {
    this.importUsersForm.disable();
    this.isLoading = true;
    let event = this.myControl.value;
    let profileIds = [];

    const profileCheckbox = this.profileCheckbox.toArray();

    for (let i = 0; i < profileCheckbox.length; i++) {
      if (profileCheckbox[i].checked) {
        profileIds.push(profileCheckbox[i].value.toString());
      }
    }

    this.addUsersForm = new UntypedFormGroup({
      profile_id: new UntypedFormControl(profileIds, [Validators.required]),
      source_event_id: new UntypedFormControl(this.importUsersForm.value.event.id, [
        Validators.required,
      ]),
      destination_event_id: new UntypedFormControl(this.application_id),
    });

    let params;
    params = {
      route: "events/users",
      objectToCreate: this.addUsersForm.value,
    };

    this._crud
      .post("events/import-users", this.addUsersForm.value)
      .then((res) => {
        this.toastr.success("Usuários adicionados com Sucesso!", "Sucesso!");
        this.dialogRef.close();
        this.isLoading = false;
      });
  };
}
