<ng-container *ngIf="!importing">

  <app-modal-header [hasClose]='true' [title]="'IMPORTAÇÃO DE MATRIZ'" (handleClose)="close()"></app-modal-header>

  <mat-dialog-content class="content-dialog">

    <form [formGroup]="searchMatrixForm" style="padding: 0;">

      <div class="form-inputs">

        <div class="form-pattern">
          <mat-form-field class="">
            <input matInput formControlName="courses.description" type="text" placeholder="Curso" (keyup)="loadMatrices()">
            <mat-error *ngIf="searchMatrixForm.controls['courses.description'].errors && searchMatrixForm.controls['courses.description'].touched">
              {{searchMatrixForm.controls['courses.description'].errors['message']}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-pattern flex">
          <mat-form-field class="half-input">
            <input matInput formControlName="matrices.version" type="text" placeholder="Versão da Matriz" (keyup)="loadMatrices()">
            <mat-error *ngIf="searchMatrixForm.controls['matrices.version'].errors && searchMatrixForm.controls['matrices.version'].touched">
              {{searchMatrixForm.controls['matrices.version'].errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field class="half-input">
            <input matInput formControlName="itineraries.version" type="text" placeholder="Versão do Itinerário" (keyup)="loadMatrices()">
            <mat-error *ngIf="searchMatrixForm.controls['itineraries.version'].errors && searchMatrixForm.controls['itineraries.version'].touched">
              {{searchMatrixForm.controls['itineraries.version'].errors['message']}}</mat-error>
          </mat-form-field>
        </div>

      </div>

    </form>

    <form [formGroup]="importMatrixForm" style="padding: 0;">
      <div class="form-inputs">

        <p class="founded-matrices-title primary-text">Matrizes Encontradas</p>
        <div class="scroll" *ngIf="!loading">
          <mat-radio-group formControlName="matrix_id">
            <ng-container *ngFor="let matrix of matrixArray">
              <mat-radio-button [value]="matrix.matrix_id" [disabled]="data.indexOf(matrix.matrix_id) > -1" (change)="changeMatrix()">
                {{matrix.course_name}} - VERSÃO {{matrix.matrix_version}}, IN {{matrix.itinerary_version}} {{data.indexOf(matrix.matrix_id) > -1 ? '[IMPORTADA]' : ''}}
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

        <div *ngIf="loading">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

        <p class="founded-matrices-title primary-text">Associar a</p>

        <div class="form-pattern flex">
          <mat-form-field class="half-input">
            <mat-select matSelect formControlName="application_id" placeholder="Aplicação" required (selectionChange)="changeApplications($event)">
              <mat-option *ngFor="let application of applicationArray" [value]="application.id">
                {{application.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="importMatrixForm.get('application_id').errors && importMatrixForm.get('application_id').touched">
              {{importMatrixForm.get('application_id').errors['message']}}</mat-error>
          </mat-form-field>

          <mat-form-field class="half-input">
            <mat-select matSelect formControlName="course_id" placeholder="Curso" required (selectionChange)="changeCourses($event)">
              <mat-option *ngFor="let course of courseArray" [value]="course.id">
                {{course.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="importMatrixForm.get('application_id').touched && courseArray && courseArray.length == 0">
              Nenhum Curso foi relacionado a Aplicação.</mat-error>
            <mat-error *ngIf="importMatrixForm.get('course_id').errors && importMatrixForm.get('course_id').touched">
              {{importMatrixForm.get('course_id').errors['message']}}</mat-error>
          </mat-form-field>
        </div>
      </div>

    </form>

  </mat-dialog-content>

  <div class="modal-footer">
    <button id="modalApplyBtn" type="button" mat-raised-button color="primary"
      class="far-left recover-btn secondary primary-text" (click)="importMatrix()" [disabled]="importMatrixForm.invalid || importMatrixForm.get('application_id').disabled ||
          importMatrixForm.get('course_id').disabled">
      Importar Matriz </button>
  </div>

</ng-container>

<ng-container *ngIf="importing">

  <app-modal-header [title]="'Importando...'"></app-modal-header>

  <mat-dialog-content class="content-dialog">
    <p class="message primary-text" *ngIf="!cancel">Importando Matriz de Referência. Caso você deseje interromper operação clique no botão "Cancelar Importação", todos os dados já importados serão descartados.</p>
    <p class="message primary-text" *ngIf="cancel">Cancelando importação, aguarde.</p>
    <div>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </mat-dialog-content>

  <div class="modal-footer">

    <button *ngIf="!cancel" type="button" mat-raised-button color="primary" class="far-left cancel-btn secondary primary-text" (click)="cancelImport()"> Cancelar Importação </button>

  </div>

</ng-container>

