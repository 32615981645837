<app-modal-header [hasClose]='true' [title]="'ASSOCIAR CURSOS'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="relateCourse">
    <div id="text" class="primary-text">
      Selecione um ou mais Cursos para associar à aplicação atual
    </div>

    <div class="profiles-check-area-container">
      <mat-checkbox class="profile-list-item" #profileCheckboxAll (click)="selectAllCourses(profileCheckboxAll)">Selecionar Todos</mat-checkbox><hr>
      <div class="profiles-check-area" *ngFor="let course of courseArray">
        <mat-checkbox class="profile-list-item" #profileCheckbox [value]="course.id" [checked]="checkCourse(course.id)">{{course.description}}</mat-checkbox>
      </div>
    </div>

  </form>

</mat-dialog-content>

<div class="modal-footer">

  <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" [disabled]="setValidationForm()" (click)="relateCourseSubmit()">
    Confirmar
  </button>
  <!-- <button type="button" mat-raised-button (click)="close()" class="far-left">
    Cancelar
  </button> -->

</div>
