<app-modal-header [hasClose]='true' [title]="dialogTitle" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">
    <p>{{dialogMessage}}</p>
</mat-dialog-content>

<div class="modal-footer">
    <button mat-raised-button color="primary" [ngClass]="resend ? '' : 'delete'" type="button" (click)="confirmAction()">Confirmar</button>
    <button mat-raised-button mat-dialog-close type="button" autofocus>Cancelar</button>
</div>
