<app-modal-header [hasClose]='true' (handleClose)="close()"></app-modal-header>

<h4 mat-dialog-title class="modal-toolbar-title primary">
  <button type="button" mat-raised-button color="primary" class="secondary primary-text">
    Salvar PDF
  </button>
</h4>

<div id="contentToConvert" class="cover-sheet-model">
  <img [src]="evaluatorManualArt.src">

  <form [formGroup]="evaluatorManualForm" #formDirective="ngForm">
    <div *ngIf="evaluatorManualData" [innerHtml]="evaluatorManualData.description"></div>
  </form>
</div>
