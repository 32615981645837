<app-modal-header
  [hasClose]="true"
  [title]="'Aplicação de Contexto'"
  (handleClose)="onDialogClose()"
>
</app-modal-header>

<mat-dialog-content class="content-dialog p-block-2">
  <div class="application-container">
    <form [formGroup]="applicationForm">
      <mat-radio-group class="">
        <mat-radio-button
          *ngFor="let application of applications"
          [checked]="application.checked"
          value="{{ application.id }}"
          (click)="onSelection(application)"
        >
          <div
            class="selected-application"
            [ngStyle]="{ 'border-color': application.markColor }"
          >
            <p class="application-description" title="{{ application.description }}">
              <mat-icon
                class="warning"
                *ngIf="application.is_expired"
                title="A aplicação {{ application.description }} foi encerrada"
                matTooltip="A aplicação {{ application.description }} foi encerrada"
                >event_busy</mat-icon>
              <mat-icon
                class="success"
                *ngIf="!application.is_expired && applicationIsRunning(application.initialDate)"
                matTooltip="Aplicação {{ application.description }} em andamento"
                >event_available</mat-icon>
              <mat-icon
                class="upcoming"
                *ngIf="!application.is_expired && !applicationIsRunning(application.initialDate)"
                matTooltip="Aplicação {{ application.description }} ainda não iniciada"
                >event</mat-icon>
              {{ application.description }}
            </p>
            <span class="application-dates">
              {{ application.initialDate | date: 'dd/MM/yyyy' }} à {{ application.endDate | date: 'dd/MM/yyyy' }}
              </span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </div>
</mat-dialog-content>

<div class="modal-footer">
  <button
    id="modalApplyBtn"
    class="primary-action-btn"
    type="button"
    mat-raised-button
    (click)="applyApplication()"
  >
    Selecionar
  </button>
</div>
