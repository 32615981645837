import { Component, OnInit, Output, EventEmitter, Inject, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { ToastrService } from 'ngx-toastr';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-import-cover-sheets',
  templateUrl: './import-cover-sheets.component.html',
  styleUrls: ['./import-cover-sheets.component.css']
})
export class ImportCoverSheets implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('coverSheetCheckbox') private coverSheetCheckbox: QueryList<any>;

  displayedColumns: string[] = ['select', 'type', 'particularity', 'preview'];

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = true;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  public applicationArray: any;
  public coverSheetArray: any;
  event_id: any;
  msg;
  importCoverSheetForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl();
  myControlApplication = new UntypedFormControl();
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];


  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportCoverSheets>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.formInit();
    this.listApplications();
    this.listCoverSheets();
  }

  listApplications = () => {
    this._crud.post('events/general-search', {
      'fields': {},
      'dates': {},
      'orderBy': { 'description': 'asc' },
      'limit': null,
      'noPaginate': true,
      'page': null
    }).then(res => {
      this.applicationArray = res['data'];
    });
  }

  listCoverSheets() {
    this.isLoading = true;
    this._crud.get('cover-sheet?where[]=event_id,' + this.importCoverSheetForm.value.source_event_id).then(res => {
      this.coverSheetArray = res['data'];
      this.isLoading = false;
    });
  }

  formInit() {
    this.importCoverSheetForm = new UntypedFormGroup({
      'source_event_id': new UntypedFormControl(null, [Validators.required]),
      'destination_event_id': new UntypedFormControl(this.event_id),
      'cover_sheet_ids': new UntypedFormControl(null)
    });
  }

  close = () => {
    this.dialogRef.close();
  }

  previewCoverSheet(coverSheet) {
    this.loadingMessage();

    this._crud.file('cover-sheet/preview', {
        'id': coverSheet.id
    }).then(res => {
      saveAs(res, 'folha_rosto_preview.pdf');
        this.dialog.closeAll();
    }, rej => {
      this.toastr.error('Falha do tentar gerar Preview', 'Erro!');
      this.dialog.closeAll();
    });
  }

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
        width: '520px',
        height: 'auto',
        disableClose: true
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  onSubmmit = () => {
    const coverSheetCheckbox = this.coverSheetCheckbox.toArray();
    const cover_sheet_ids = [];

    for (let i = 0; i < coverSheetCheckbox.length; i++) {
      if (coverSheetCheckbox[i].checked) {
        cover_sheet_ids.push(coverSheetCheckbox[i].value.toString());
      }
    }

    this.importCoverSheetForm.get('cover_sheet_ids').setValue(cover_sheet_ids);

    this._crud.post('evaluation-items/cover-sheet', this.importCoverSheetForm.value)
      .then(res => {
        this.toastr.success('Folhas de Rosto importadas com Sucesso!', 'Sucesso!');
        this.dialogRef.close();
      });
  }
}
