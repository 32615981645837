<app-modal-header [hasClose]='true' [title]="modalTitle" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <table mat-table [dataSource]="arrayList" class="mat-elevation-z1 table-100">

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Descrição </th>
      <td mat-cell class="text-left" *matCellDef="let element"> {{ element.description ? element.description : 'N/D'}} </td>
    </ng-container>

    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef> Anexo </th>
      <td mat-cell class="text-left" *matCellDef="let element" matTooltip="{{ element.filename }}"> {{ reduceToView(element.filename) }} </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element" style="vertical-align: middle; text-align: initial;">
          <span class="download" *ngIf="showDownloadOption && !element.downloading" (click)="downloadFile(element)">
            <mat-icon>get_app</mat-icon>
          </span>

          <div>
            <mat-spinner *ngIf="element.downloading" diameter="20"></mat-spinner>
          </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div style="margin-top: 10px;">
    {{message}}
  </div>

</mat-dialog-content>
