import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { environment } from '@env/environment';

/*Services*/
import { RegisterComponent } from '../../../../../app/components/register/register.component';
import { AuthenticationService } from '@services/laravel/authentication.service';
import { ValidateCpf } from '@shared/validators/cpf.validator';
import { ToastrService } from 'ngx-toastr';
import { ValidateRequired } from '@shared/validators/required.validator';

@Component({
  selector: 'ntm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(ReCaptchaComponent, { static: false }) captcha: ReCaptchaComponent;

  public user_email: any;
  public new_password: any;
  public cpf_number: any;
  public birth_date: any;
  public errorMsg: any;
  public mask: any;
  public generatedPassword: any = false;
  isLoading = false;

  forgotPasswordForm: UntypedFormGroup;
  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,

    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.forgotPasswordForm = new UntypedFormGroup({
      'field_0_cpf': new UntypedFormControl(null, [ValidateCpf, Validators.required]),
      'field_1_birthday': new UntypedFormControl(null, [Validators.required]),
      'captcha': new UntypedFormControl(null, ValidateRequired)
    });
  }

  ngOnInit() {
    this.mask = {
      cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
      date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      zip: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
      phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cell_phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    };

    this.removeCaptchaValidationWhenRunningUnderCI();
  }

  close = () => {
    this.dialogRef.close();
  }

  forgotPassword = () => {
    this.isLoading = true;
    this.cpf_number = this.forgotPasswordForm.controls['field_0_cpf'].value;
    this.birth_date = this.convertDateToUS(this.forgotPasswordForm.controls['field_1_birthday'].value);
    this.authentication.recoverPasswordEmail(this.cpf_number, this.birth_date)
      .then((res: any) => {
        let feedback;
        this.isLoading = false;
        feedback = res.return;

        if (feedback['finded']) {
          this.generatedPassword = true;

          if (feedback['send_email']) {
            this.generatedPassword = true;
            this.user_email = feedback['email'];
            this.toastr.success("Dados enviados para o email " + feedback['email'] + "!", 'Sucesso!', { timeOut: 7000, disableTimeOut: true, closeButton: true });
            this.toastr.warning("Atenção: Caso não receba o e-mail de recuperação de senha nos próximos 10 minutos, verifique o SPAM ou Lixo Eletrônico.", 'Sucesso!', { timeOut: 7000, disableTimeOut: true, closeButton: true });
            this.dialogRef.close();
          } else this.errorMsg = "Falha ao solicitar.";

        } else {
          this.errorMsg = "Não existe usuário cadastrado para este CPF. Deseja cadastrar?";
        }
      }, rej => {
        this.errorMsg = "Falha ao buscar usuário.";
      });
  }

  removeCaptchaValidationWhenRunningUnderCI() {
    if (environment.ci) {
      this.forgotPasswordForm.controls['captcha'].clearValidators();
      this.forgotPasswordForm.controls['captcha'].disable();
      this.forgotPasswordForm.updateValueAndValidity();
    }
  }

  handleCorrectCaptcha($event) {
    this.forgotPasswordForm.get('captcha').setValue($event);
  }

  selfRegister() {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '80%',
      height: 'auto',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      const array = [];
      const string = '';
    });
  }

  convertDateToUS(date) {
    var br_date = date;
    br_date = br_date.split('/');
    var us_date = br_date.reverse().join('-');
    return us_date
  }

  convertDateToBR(date) {
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date
  }

  isContinuosIntegration() {
    return environment.ci;
  }

  recaptchaExpired() {
    this.forgotPasswordForm.get('captcha').setValue(null);
  }

}
