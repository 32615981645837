<div id="close">
  <div class="modal-toolbar-system-name">
      <img class="logo-size" src="/assets/img/logos/logo-sap-interna.png">
  </div>
  <a (click)="close()" *ngIf="!importing">
    <mat-icon class="icon-pattern">close</mat-icon>
  </a>
</div>

<h4 mat-dialog-title class="modal-toolbar-title primary" *ngIf="!importing">IMPORTAR ESCOLAS</h4>
<h4 mat-dialog-title class="modal-toolbar-title primary" *ngIf="importing">IMPORTANDO ESCOLAS</h4>

<mat-dialog-content class="content-dialog" *ngIf="!importing">

  <form [formGroup]="importSchoolsForm">

    <div>
      <div id="text" class="primary-text">
        <p>Selecione o arquivo para importação e clique em IMPORTAR.</p>
        <p>Em caso de dúvida <a style="text-decoration: underline;  color: blue; "
          href="{{url}}/modelo_importacao_escolas.csv">baixe aqui</a> o modelo do arquivo de importação.</p>
      </div>
    
      <label for="fileInput">
    
        <input type="file" id="fileInput" (change)="getMedia($event)" *ngIf="!importSchoolsForm.get('import_file').value" accept=".csv">    
    
        <div ntmDnd id="fileArea" (ntmDnd)="!importSchoolsForm.get('import_file').value ? getMedia($event) : ''" accept=".csv"> 
          <div class="flex justify-center align-center">
    
            <p>{{ importSchoolsForm.get('import_file').value ? importSchoolsForm.get('import_file')?.value?.name ||
              importSchoolsForm.get('import_file')?.value : 'Arraste e solte o arquivo de documento
              aqui ou clique para selecionar o documento em seu computador.*' }}</p>

            <mat-icon *ngIf="importSchoolsForm.get('import_file').value" class="pointer" (click)="deleteFile()">delete</mat-icon>
    
          </div>
        </div>
  
      </label>

      <p class="extension-import">Arquivos apenas no formato CSV.</p>

      <div *ngIf="importErrors" class="div-error">
        <p>{{schoolsImported}} escola(s) importada(s) e {{importErrors.length}} erro(s) encontrado(s):</p>
        <ul>
          <li *ngFor="let importError of importErrors">
            Linha: {{importError.row}}
            <ul>
              <li *ngFor="let field of importError.fields">O valor da coluna {{field}} é inválido.</li>
            </ul>
          </li>
        </ul>
      </div>
    
    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer" *ngIf="!importing">
  <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" [disabled]="!importSchoolsForm.valid" (click)="importSchools()"> Importar </button>
</div>

<div *ngIf="importing">
  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>

