import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ntm-select-tests-to-application',
  templateUrl: './select-tests-to-application.component.html',
  styleUrls: ['./select-tests-to-application.component.css']
})
export class SelectTestsToApplicationComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  isLoading = true;
  validForm = false;

  public drArray: any;
  public schoolArray: any;
  public courseArray: any;
  public testArray: any;

  application_id: any;
  msg;
  importTestForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();


  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<SelectTestsToApplicationComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.formInit();
    this.listDrs();
    this.listSchools();
    this.listCourses();
  }

  formInit(){
    this.importTestForm = new UntypedFormGroup({
      'event_id': new UntypedFormControl(this.application_id),
      'regional_id': new UntypedFormControl(null, [ValidateRequired]),
      'school_id': new UntypedFormControl(null, [ValidateRequired]),
      'course_id': new UntypedFormControl(null, [ValidateRequired]),
      'exam_id': new UntypedFormControl(null, [ValidateRequired]),
    });
  }

  listDrs = () => {
    this._crud.get('regional').then(res => {
      this.drArray = res['obj'];
    });
  }

  listSchools = () => {
    this._crud.get('school').then(res => {
      this.schoolArray = res['data'];
    });
  }

  listCourses = () => {
    this._crud.get('courses?noRelationship=true').then(res => {
      this.courseArray = res['data'];
    });
  }

  close = () => {
    this.dialogRef.close();
  }

  importTest = () => {

    this._crud.post('events/exams', this.importTestForm.value)
      .then(res => {
        let snackClass, string;
        snackClass = 'success-snackbar';

        if (res && res['apiBody']['status'] === 'ERROR') {
          string = res['apiBody']['message'];
          this.toastr.success(string, 'Sucesso!');
        } else {
          this.toastr.success("Prova adicionada com Sucesso.", 'Sucesso!');
          this.dialogRef.close();
        }
      });
  }
}
