<mat-toolbar mat-dialog-title color="accent" class="primary flex justify-between align-center">

    <p>{{ test?.description || 'TÍTULO DO TESTE DE PREPARAÇÃO' }}</p>

    <div class="flex">

        <img class="logo-size" src="/assets/img/logos/logo-sap-interna.png">

        <mat-icon class="pointer" (click)="dialogRef.close()">close</mat-icon>

    </div>

</mat-toolbar>

<div mat-dialog-content class="mg1 h100" id="scrollWindow">

    <form [formGroup]="testForm">

        <div class="flex wd100 justify-between mt1">

            <p *ngIf="formState == 0"></p>
            <p *ngIf="formState != 0 && test?.result">{{ test.result }}</p>

            <p>{{ counter || 0 }} {{ counter == 1 ? 'minuto restante' : 'minutos restantes'}}</p>

        </div>

        <div class="mt1 text" *ngFor="let question of test?.questions; let x = index">

            <div formArrayName="questions">

                <div [formGroupName]="x">

                    <h1 class="bold">{{ x + 1 }} - QUESTÃO</h1>

                    <br>

                    <p>{{ question?.description }}</p>

                    <br>

                    <h1 class="bold">ALTERNATIVAS</h1>

                    <mat-radio-group formControlName="selected" [disabled]="data.view || formState != 0" required>

                        <div class="flex align-center mt1" *ngFor="let alt of question?.alternatives; let v = index"
                            [style.background-color]="formState == 0 ? '' : isCorrect(letters[v], x) && isSelected(letters[v], x, v) ? '#7dff7d' : isSelected(letters[v], x, v) ? '#ffa8a8' : ''">

                            <p *ngIf="question?.alternative_type_id != 6">{{ letters[v] + ')'}} &nbsp; </p>

                            <mat-radio-button [style.margin-left]="question?.alternative_type_id == 6 ? '24.89px': ''"
                                [value]="letters[v]">
                                {{ alt.description }}
                            </mat-radio-button>

                        </div>

                    </mat-radio-group>

                    <mat-divider class="mt1"></mat-divider>

                </div>

            </div>

        </div>

    </form>

</div>

<div mat-dialog-actions class="ml1 margin-footer">

    <button mat-raised-button color="primary" class="primary-action-btn mr1" (click)="tryAgain()" *ngIf="formState != 0">Refazer
        teste</button>

    <button id="modalApplyBtn" mat-raised-button color="primary" class="secondary mr1" (click)="completeTest()" [disabled]="testForm.invalid"
        *ngIf="!data?.view && formState == 0">Finalizar</button>

    <button mat-raised-button mat-dialog-close>Fechar</button>

</div>