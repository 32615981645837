<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <span *ngIf="data.action != 2">{{dialogMessage}}</span>

  <mat-list>
    <div mat-subheader>Aluno(s) selecionado(s):</div>
    <mat-list-item *ngFor="let item of data.itens">
      <mat-icon mat-list-icon>person</mat-icon>
      <div mat-line>{{ item.name }}</div>
      <div mat-line>CPF: {{ item.cpf_number }} </div>
    </mat-list-item>
  </mat-list>

  <div *ngIf="data.action == 2">
    <form [formGroup]="homologForm">

      <mat-form-field class="half-input">
        <input type="text" [formControl]="myReasonControl" placeholder="Justificativa" aria-label="Justificativa" matInput
          [matAutocomplete]="autoReason" (keyup)="validControl()">
        <mat-autocomplete #autoReason="matAutocomplete" [displayWith]="displayFnReason" matSelect placeholder="Escolha o motivo" (optionSelected)="validControl()">
          <mat-option *ngFor="let item of filteredOptionsReason | async" [value]="item">
            {{item.description}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint *ngIf="homologForm.get('reason_not_homologated_id').errors && homologForm.get('reason_not_homologated_id').touched">
            {{homologForm.get('reason_not_homologated_id').errors['message']}}</mat-hint>
      </mat-form-field>

    </form>
  </div>

</mat-dialog-content>

<div class="modal-footer">

  <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button>
  <button *ngIf="data.action != 2" mat-raised-button class="primary-action-btn" type="submit" (click)="homologation()">Confirmar</button>
  <button id="modalApplyBtn" *ngIf="data.action == 2" mat-raised-button class="primary-action-btn" type="submit" [disabled]="homologForm.invalid" (click)="homologation()">Confirmar</button>

</div>
