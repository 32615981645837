<div class="header">
  <span class="section-title bold-text" *ngIf="showSender">
    <i [ngClass]="{'preview-messages': isParent}">Remetente(s):&nbsp;</i>
    <i
      [ngClass]="{'preview-messages colapse-text not-bold': isParent, 'not-bold colapse-text': true}">{{message.user.name}}</i>
  </span>

  <span class="row-flex section-title bold-text" *ngIf="!showSender">
    <i [ngClass]="{'preview-messages bold-text icon-section-title': isParent, 'icon-section-title': true}">Destinatário(s):&nbsp;</i>
    <i [ngClass]="{'preview-messages colapse-text not-bold row-flex icon-section-title': isParent, 'not-bold colapse-text row-flex icon-section-title': true}"
      [innerHtml]="recipients" (click)='viewRecipientList()'></i>
  </span>

  <span class="section-title bold-text">
    <i [ngClass]="{'preview-messages bold-text': isParent}">Assunto:&nbsp;</i>
    <i
      [ngClass]="{'preview-messages colapse-text not-bold': isParent, 'not-bold colapse-text': true}">{{message.description}}</i>
  </span>

  <span class="section-title bold-text">
    <i [ngClass]="{'preview-messages bold-text': isParent}">Data:&nbsp;</i>
    <i
      [ngClass]="{'preview-messages colapse-text not-bold': isParent, 'not-bold colapse-text': true}">{{message.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</i>
  </span>
</div>

<div class="section-title bold-text">
  <mat-accordion>
    <mat-expansion-panel *ngIf="message?.attachments?.length">
      <mat-expansion-panel-header>
        <mat-panel-title [ngClass]="{'primary-text bold-text ': !isParent,'preview-messages bold-text': isParent}">
          Anexos ({{message.attachments.length}}):
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="message.attachments">
        <ng-container matColumnDef="description">
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <td mat-cell *matCellDef="let element" (click)="downloadFile(element)">
            <mat-icon class="pointer">cloud_download</mat-icon>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-expansion-panel>

    <i [ngClass]="{'preview-messages bold-text': isParent}">Mensagem:&nbsp;</i>
    <mat-expansion-panel expanded="true" #msg>
      <mat-expansion-panel-header *ngIf="!msg.expanded">

        <mat-panel-description *ngIf="!msg.expanded" [innerHtml]="message.message">

        </mat-panel-description>
      </mat-expansion-panel-header>

      <p [ngClass]="{'primary-text': !isParent,'preview-messages': isParent}" [innerHtml]="message.message"></p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
