<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content *ngIf="!loading" class="content-dialog">

  <mat-expansion-panel class="m20" hideToggle>
    <mat-expansion-panel-header>

      <div class="flex">

        <div>
          <mat-icon style="margin-right: 5px;">person</mat-icon> <span> {{data.itens[0]?.name}} </span> - <span>
            {{data.itens[0]?.cpf_number}} </span>
        </div>
        <mat-icon>add</mat-icon>

      </div>

    </mat-expansion-panel-header>

    <div>

      <div class="div-info" *ngFor="let item of data.itens | slice:1">
        <mat-icon style="margin-right: 5px;">person</mat-icon> <span> {{item.name}} </span> - <span>
          {{item.cpf_number}} </span>
      </div>

    </div>

  </mat-expansion-panel>

  <mat-card class="m-b20">
    <form [formGroup]="examGeneratedForm" class="pd0">

      <p>Prova Atual (Particularidade): <strong>{{data.itens[0]?.exam_particularity}}</strong></p>

      <mat-form-field *ngIf="data.action == 2">
        <textarea matInput placeholder="Justificativa" rows="3" formControlName="reason" required></textarea>
        <mat-error *ngIf="examGeneratedForm.get('reason').errors && examGeneratedForm.get('reason').touched">
          {{examGeneratedForm.get('reason').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select matSelect formControlName="evaluator_manual_id" placeholder="Manual do avaliador" required>
          <mat-option *ngFor="let item of evaluatorManual" [value]="item.id">
            {{item.course.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="examGeneratedForm.get('evaluator_manual_id').errors && examGeneratedForm.get('evaluator_manual_id').touched">
          {{examGeneratedForm.get('evaluator_manual_id').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select matSelect formControlName="exam_id" placeholder="Prova prática" required>
          <mat-option *ngFor="let item of exams" [value]="item.id">
            {{item.particularity}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="examGeneratedForm.get('exam_id').errors && examGeneratedForm.get('exam_id').touched">
          {{examGeneratedForm.get('exam_id').errors['message']}}</mat-error>
      </mat-form-field>

    </form>
  </mat-card>

</mat-dialog-content>

<div class="modal-footer" *ngIf="!loading">
  <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button>
  <button id="modalApplyBtn" class="primary-action-btn" mat-raised-button type="submit" (click)="examGenerated()" [disabled]="examGeneratedForm.invalid">{{btn}}</button>
</div>

<div *ngIf="loading" class="center-loading">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
