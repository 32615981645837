import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CkeditorService{
    ckeConfig: any;
    constructor(public snackBar: MatSnackBar) {
        this.ckeConfig = {
            language: "pt-br",
            font_defaultLabel: "Arial",
            fontSize_defaultLabel : "12px",
            lineHeight_style : "1em",
            line_height: "1em;1.1em;1.2em;1.3em;1.4em;1.5em",
            enterMode: 2,
            tabSpaces: 4,
            toolbar: [
              {
                name: 'document',
                groups: [ 'mode', 'document', 'doctools' ],
                items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ]
              },
              {
                name: 'clipboard',
                groups: [ 'clipboard', 'undo' ],
                items: [ 'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo' ]
              },
              {
                name: 'basicstyles',
                groups: [ 'basicstyles', 'cleanup' ],
                items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ]
              },
              {
                name: 'styles',
                items: [ 'Format', 'Styles', 'Font', 'FontSize' ]
              },
              {
                name: 'colors',
                items: [ 'TextColor', 'BGColor' ]
              },
              {
                name: 'paragraph',
                groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ],
                items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ]
              },
              {
                name: 'insert',
                items: [ 'base64image', 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe', 'addsection' ]
              },
              {
                name: 'tools',
                items: [ 'Maximize', 'ShowBlocks' ]
              },
            ],
            extraPlugins : 'base64image, pagebreak, colorbutton, addsection, font, justify, fakeobjects, lineheight',
            // extraPlugins: 'language, image2',
            coreStyles_bold: { element: 'b', overrides: 'strong' },
            coreStyles_italic: { element: 'i', overrides: 'em' },
            scayt_sLang: 'pt-BR',
            disableNativeSpellChecker: false,
            // height: 852,
            removeButtons: 'Paste, PasteFromWord, richcombo, listblock, indentlist, menubutton, indent,image',
            resize_enabled: true,
            removePlugins:
              'bidi, indentblock, link, magicline, horizontalrule, iframe, forms,' +
              'maximize, newpage, pastetext, preview, print, specialchar, div, blockquote,' +
              'pastefromword, removeformat, copyformatting, save, showblocks, language, smiley,' +
              'filebrowser, find, flash, floatingspace, templates, about, a11yhelp, wsc, image2'
          };
    }

    newEditor(){
      return this.ckeConfig;
    }
}
