import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
/*Laravel*/
import { environment } from '@env/environment';
import { HttpServiceUtil } from './http-service-util';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CrudService {
  public url = environment.urlToApi;
  public setMenu = new EventEmitter();

  constructor(
    private _http: HttpClient,
    public toastr: ToastrService
  ) { }

  file = (route, data) => new Promise((resolve, reject) => {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Authorization': sessionStorage.getItem('access_token')
    });

    if (sessionStorage.getItem('application') && Boolean(sessionStorage.getItem('application'))) { //Um salve ao lib.dom.d.ts
      headers = headers.append('event', String(JSON.parse(sessionStorage.getItem('application')).id));
    }

    this._http.post(this.url + route, data, { headers, responseType: 'blob' })
      .subscribe(res => resolve(res), (rej: any) => {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
      });
  })

  getData(route) {
    const httpOptions = HttpServiceUtil.setHeaders();
    return this._http.get<any>(this.url + route, httpOptions);
  }

  get = (route) => new Promise((resolve, reject) => {
    this._http.get(this.url + route, HttpServiceUtil.setHeaders())
      .subscribe(res => {
        resolve(res);
      }, (rej: any) => {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
      });
  })

  delete = (route) => new Promise((resolve, reject) => {
    this.disableButtons();
    this._http.delete(this.url + route, HttpServiceUtil.setHeaders())
      .subscribe(res => {
        resolve(res);
        this.enableButtons();
      }, (rej: any) => {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
        this.enableButtons();
      });
  })

  post = async (route: string, data: any) => {
    try {
      this.disableButtons();
      const res = await this._http.post(this.url + route, data, HttpServiceUtil.setHeaders()).toPromise();
      this.enableButtons();
      return res;
    } catch (error) {
      this.enableButtons();
      if (error.status === 403) {
        throw { status: 'ERRO', message: 'Não autorizado.' };
      }
      HttpServiceUtil.errorHandling(error, this.toastr);
      throw error;
    }
  };


  patch = (route, data) => new Promise((resolve, reject) => {
    this.disableButtons();
    this._http.patch(this.url + route, data, HttpServiceUtil.setHeaders())
      .subscribe(res => {
        resolve(res);
        this.enableButtons();
      }, (rej: any) => {
        if (rej.status == 403) { reject({ 'status': 'ERRO', 'message': 'Não autorizado.' }); }
        {
          HttpServiceUtil.errorHandling(rej, this.toastr);
          reject(rej);
          this.enableButtons();
        }
      });
  })

  put = (route, data) => new Promise((resolve, reject) => {
    this.disableButtons();
    this._http.put(this.url + route, data, HttpServiceUtil.setHeaders())
      .subscribe(res => {
        resolve(res);
        this.enableButtons();
      }, (rej: any) => {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
        this.enableButtons();
      });
  })

  disableButtons() {
    if (document.getElementById('applyBtn')) { document.getElementById('applyBtn').setAttribute('disabled', 'disabled'); }
    if (document.getElementById('modalApplyBtn')) { document.getElementById('modalApplyBtn').setAttribute('disabled', 'disabled'); }
  }

  enableButtons() {
    if (document.getElementById('applyBtn')) { document.getElementById('applyBtn').removeAttribute('disabled'); }
    if (document.getElementById('modalApplyBtn')) { document.getElementById('modalApplyBtn').removeAttribute('disabled'); }
  }

  downloadFileFromUrl = (filePath, filename) => new Promise((resolve, reject) => {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Max-Age': '600',
      'Authorization': sessionStorage.getItem('access_token'),
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    this._http.get(this.url + 'public/download?filePath=' + filePath + '&filename=' + filename, { headers, responseType: 'blob' })
      .subscribe(response => {

        resolve(response);
      }, rej => {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
      });
  })

  postToCompressFile = (route, data) => new Promise((resolve, reject) => {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Max-Age': '600',
      'Authorization': sessionStorage.getItem('access_token'),
      // 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    this.disableButtons();
    this._http.post(this.url + route, data, { headers, responseType: 'blob' })
      .subscribe(res => {
        resolve(res);
        this.enableButtons();
      }, (rej) => {
        if (rej.status == 403) { reject({ 'status': 'ERRO', 'message': 'Não autorizado.' }); }
        {
          HttpServiceUtil.errorHandling(rej, this.toastr);
          reject(rej);
          this.enableButtons();
        }
      });
  })
}
