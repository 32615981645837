<div> {{openClose}}
  <div id="sidenav" *ngIf="openClose" [@myAnimation]>
    <div id="menu-top" class="primary">
      <div class="system-name">
        <h1>
          <img id="application-logo" title="SAP - Sistema de Avaliação Prática - Área Restrita" class="logo-size" src="/assets/img/logos/logo-sap-interna.png">
        </h1>
      </div>
    </div>

    <mat-card class="flex user-section">
      <div class="profile-spot" *ngIf="user">
        <img id="profile-image" [src]="user?.avatar_path" (error)="fixAvatar()">
      </div>
      <div>
        <p class="user-name">{{user?.name}}</p>
        <div class="profile-list" *ngIf="user?.application">
          <p class="user-title " *ngFor="let profile of user?.application?.profiles; let i = index;"
            matTooltip="{{profile?.access_profile?.tip}}">{{profile?.access_profile?.description}}</p>
        </div>
        <div class="no-profile" *ngIf="user?.application?.profiles?.length == 0">
          <p class="user-title" matTooltip="Procure seu Interlocutor ou Coordenador para que seja providenciado o seu Perfil de Acesso.">
            Perfil de Acesso não definido para a Aplicação ativa
          </p>
        </div>

        <div class="session-options">
          <a  mat-button mat-icon-button
              matTooltip="Perfil"
              matTooltipPosition="below"
              *ngIf="user && !mobileQuery.matches"
              routerLink='/main/user/profile'
              routerLinkActive="active-link">

            <mat-icon mat-list-icon>person</mat-icon>

          </a>
          <a  mat-button
              mat-icon-button
              matTooltip="Mensagens"
              matTooltipPosition="below"
              *ngIf="applicationsArray"
              routerLink='/main/communication-center'
              routerLinkActive="active-link">

            <mat-icon mat-list-icon>email</mat-icon>

          </a>

          <a  mat-button mat-icon-button
              matTooltip="FAQ"
              matTooltipPosition="below"
              *ngIf="applicationsArray"
              routerLink='/main/faq-view'
              routerLinkActive="active-link">

            <mat-icon mat-list-icon>contact_support</mat-icon>

          </a>

          <button mat-button mat-icon-button (click)="logout()" matTooltip="Encerrar sessão" matTooltipPosition="below">
            <mat-icon mat-list-icon>exit_to_app</mat-icon>
          </button>

        </div>
      </div>
    </mat-card>

    <div class="scroll side-menu-background" *ngIf="mainComponent.getTermConfirmation()">
      <a routerLink='/main/dashboard' mat-button id="menu-button" routerLinkActive="active-link">
        Monitoramento do Sistema
      </a>

      <div class="primary-text group" *ngFor="let tool of params.menuSettings | groupBy:'categoria' | pairs ">
        <div class="section">
          {{tool[0]}}
        </div>
        <div class="group-section" >
          <ng-container *ngFor="let val of tool[1]">
            <button class="menu-item" (click)="onMenuRoute(val.route)" mat-button id="menu-button" [routerLink]="val.route" routerLinkActive="active-link">{{val.description}}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <span *ngIf="errors.length > 0">
    <mat-error *ngFor="let error of errors">{{error.cod}} - {{error.message}}</mat-error>
  </span>
</div>
