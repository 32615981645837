<h6 mat-dialog-title>{{dialogMessage}}</h6>

<form [formGroup]="dataForm">
    <div class="form-pattern flex">
        <mat-form-field class="half-input user-name">
            <input matInput formControlName="new_name" type="text" placeholder="Novo nome" required>
            <mat-error *ngIf="dataForm.get('new_name').errors && dataForm.get('new_name').touched">
                {{dataForm.get('new_name').errors['message']}}</mat-error>
        </mat-form-field>
    </div>
</form>

<mat-dialog-actions>
    <button id="modalApplyBtn" class="cad-btn" mat-raised-button color="primary" (click)="continue()" [disabled]="!dataForm.valid">Salvar</button>
    <button mat-raised-button mat-dialog-close type="button">Cancelar</button>
</mat-dialog-actions>
