import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ntm-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})

export class InputDialogComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  public dataForm: UntypedFormGroup;
  dataToDelete: any;
  dialogMessage: string;
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (!this.data.dialogMessage) {
      this.dialogMessage = 'Tem certeza que deseja apagar?';
    } else {
      this.dialogMessage = this.data.dialogMessage;
    }

    this.dataForm = new UntypedFormGroup({
      'new_name': new UntypedFormControl(null, ValidateRequired),
    });
  }
  delete() {
    this.crud.delete(this.data.routeToApi + '/' + this.data.paramToDelete)
    .then(() => {
      this.router.navigate([this.data.routeAfterDelete]);

      const string = 'Item(ns) excluído(s) com sucesso.';

      // this.matsnackbar.open(string, '', {
      //   duration: 3000
      // });
      this.toastr.success(string, 'Sucesso!');
    });
    this.dialogRef.close(true);
  }

  continue() {
    this.dialogRef.close(this.dataForm.get('new_name').value);
  }
}
