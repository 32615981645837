<app-modal-header [hasClose]='true' (handleClose)="close()"></app-modal-header>

<div mat-dialog-title class="modal-toolbar-title primary">
  <div style="display: flex;">
    <h4 style="margin-right: 20px;">Matriz de Referência</h4>
    <a
      id="print-btn"
      class="secondary primary-text"
      mat-raised-button
      (click)="printMatrix()"
      color="accent"
    > Imprimir </a>
  </div>
</div>

<div class="info">

  <div>
    <span>CURSO: </span> {{matrix?.course?.description}}
  </div>

  <div>
    <span>VERSÃO DA MATRIZ: </span> {{matrix?.version}}
  </div>

  <div>
    <span>ITINERÁRIO: </span> {{matrix?.itinerary_version}}
  </div>

</div>
<mat-progress-bar mode="indeterminate" *ngIf="loading" style="width: inherit;"></mat-progress-bar>

<mat-dialog-content *ngIf="!loading">

  <mat-tab-group>

    <mat-tab label="PROVA PRÁTICA">

      <div class="table-scroll">

        <table style="color: grey;" class="view-matrix">

          <thead>
            <tr>
              <th [attr.colspan]="2" class="general-competence">
                <p> <span>COMPETÊNCIA GERAL: </span> {{matrix?.competence_description}} </p>
              </th>
              <th [attr.colspan]="(matrix?.capacities.length)">
                <p> CAPACIDADES </p>
              </th>
            </tr>
            <tr>
              <th rowspan="3" class="head-competence">
                <p> UNIDADES DE COMPETÊNCIA </p>
              </th>
              <th rowspan="3" class="head-competence">
                <p> ELEMENTOS DE COMPETÊNCIA </p>
              </th>
              <th [attr.colspan]="(numberBasics.length)">
                <p>BÁSICAS</p>
              </th>
              <th [attr.colspan]="(numberTecnic.length)">
                <p>TÉCNICAS</p>
              </th>
              <th *ngIf="numberManagement.length > 0" [attr.colspan]="(numberManagement.length)">
                <p>GESTÃO</p>
              </th>
              <th *ngIf="numberPsicomotor.length > 0" [attr.colspan]="(numberPsicomotor.length)">
                <p>PSICOMOTOR</p>
              </th>
            </tr>
            <tr>
              <th *ngFor="let item of matrix?.capacities">
                C{{item.code}}
              </th>
            </tr>
            <tr>
              <th *ngFor="let item of matrix?.capacities">
                {{item.description}}
              </th>
            </tr>
          </thead>

          <tbody>

            <tr *ngFor="let item of competence_units">
              <td class="friendly" *ngIf="item.competency_unity_description" [attr.rowspan]="item.competency_unity_span">
                <p>{{item?.competency_unity_description}}</p>
              </td>
              <td>{{item?.description}}</td>
              <td *ngFor="let know of item.evidences_knowledges">
                {{know}}
              </td>
            </tr>

          </tbody>

        </table>

      </div>

      <div class="div-container">

        <h1 style="width: 100%; margin-bottom: 10px; color: #450A6D;">OBJETOS DE CONHECIMENTO</h1>

        <div class="div-object">

          <span *ngFor="let item of matrix?.knowledges" class="span-object">
            {{sigla(item.code)}} - {{item.description}}
          </span>

        </div>
      </div>
    </mat-tab>

    <mat-tab label="PROVA OBJETIVA">
      <div class="table-scroll">

        <table style="color: grey;" class="view-matrix">

          <thead>
            <tr>
              <th [attr.colspan]="2" class="general-competence">
                <p> <span>COMPETÊNCIA GERAL: </span> {{matrix?.competence_description}} </p>
              </th>
              <th [attr.colspan]="(matrix?.capacities.length)">
                <p> CAPACIDADES </p>
              </th>
            </tr>
            <tr>
              <th rowspan="3" class="head-competence">
                <p> UNIDADES DE COMPETÊNCIA </p>
              </th>
              <th rowspan="3" class="head-competence">
                <p> ELEMENTOS DE COMPETÊNCIA </p>
              </th>
              <th [attr.colspan]="(numberBasics.length)">
                <p>BÁSICAS</p>
              </th>
              <th [attr.colspan]="(numberTecnic.length)">
                <p>TÉCNICAS</p>
              </th>
              <th *ngIf="numberManagement.length > 0" [attr.colspan]="(numberManagement.length)">
                <p>GESTÃO</p>
              </th>
              <th *ngIf="numberPsicomotor.length > 0" [attr.colspan]="(numberPsicomotor.length)">
                <p>PSICOMOTOR</p>
              </th>
            </tr>
            <tr>
              <th *ngFor="let item of matrix?.capacities">
                C{{item.code}}
              </th>
            </tr>
            <tr>
              <th *ngFor="let item of matrix?.capacities">
                {{item.description}}
              </th>
            </tr>
          </thead>

          <tbody>

            <tr *ngFor="let item of competence_units">
              <td class="friendly" *ngIf="item.competency_unity_description" [attr.rowspan]="item.competency_unity_span">
                <p>{{item?.competency_unity_description}}</p>
              </td>
              <td>{{item?.description}}</td>
              <td *ngFor="let know of item.competences_elements_capacities">
                {{know}}
              </td>
            </tr>

          </tbody>

        </table>

      </div>

      <div class="div-container">

        <h1 style="width: 100%; margin-bottom: 10px; color: #450A6D;">OBJETOS DE CONHECIMENTO</h1>

        <div class="div-object">

          <span *ngFor="let item of matrix?.knowledges" class="span-object">
            {{sigla(item.code)}} - {{item.description}}
          </span>

        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>

<div class="content-copy-to-print">
  <table id="document-header">
    <tbody>
      <tr>
        <td><span>CURSO: </span> {{matrix?.course.description}}</td>
        <td><span>VERSÃO DA MATRIZ: </span> {{matrix?.version}}</td>
        <td><span>ITINERÁRIO: </span> {{matrix?.itinerary_version}}</td>
      </tr>
    </tbody>
  </table>

  <table id="table1" style="color: grey;" class="view-matrix">

    <thead>
      <tr>
        <th [attr.colspan]="(matrix?.capacities.length) + 2">PROVA PRÁTICA</th>
      </tr>
      <tr>
        <th [attr.colspan]="2" class="general-competence">
          <p> <span>COMPETÊNCIA GERAL: </span> {{matrix?.competence_description}} </p>
        </th>
        <th [attr.colspan]="(matrix?.capacities.length)">
          <p> CAPACIDADES </p>
        </th>
      </tr>
      <tr>
        <th rowspan="3" class="head-competence">
          <p> UNIDADES DE COMPETÊNCIA </p>
        </th>
        <th rowspan="3" class="head-competence">
          <p> ELEMENTOS DE COMPETÊNCIA </p>
        </th>
        <th [attr.colspan]="(numberBasics.length)">
          <p>BÁSICAS</p>
        </th>
        <th [attr.colspan]="(numberTecnic.length)">
          <p>TÉCNICAS</p>
        </th>
        <th *ngIf="numberManagement.length > 0" [attr.colspan]="(numberManagement.length)">
          <p>GESTÃO</p>
        </th>
        <th *ngIf="numberPsicomotor.length > 0" [attr.colspan]="(numberPsicomotor.length)">
          <p>PSICOMOTOR</p>
        </th>
      </tr>
      <tr>
        <th *ngFor="let item of matrix?.capacities">
          C{{item.code}}
        </th>
      </tr>
      <tr>
        <th *ngFor="let item of matrix?.capacities">
          {{item.description}}
        </th>
      </tr>
    </thead>

    <tbody>

      <tr *ngFor="let item of competence_units">
        <td class="friendly" *ngIf="item.competency_unity_description" [attr.rowspan]="item.competency_unity_span">
          <p>{{item?.competency_unity_description}}</p>
        </td>
        <td>{{item?.description}}</td>
        <td *ngFor="let know of item.evidences_knowledges">
          {{know}}
        </td>
      </tr>

    </tbody>

  </table>

  <table id="OC1">
    <thead>
      <tr>
        <th colspan="4">OBJETOS DE CONHECIMENTO</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of matrix?.knowledges">
        <td colspan="4">{{sigla(item.code)}} - {{item.description}}</td>
      </tr>
    </tbody>
  </table>

  <table id="table2" style="color: grey;" class="view-matrix">

    <thead>
      <tr>
        <th [attr.colspan]="(matrix?.capacities.length) + 2">PROVA OBJETIVA</th>
      </tr>
      <tr>
        <th [attr.colspan]="2" class="general-competence">
          <p> <span>COMPETÊNCIA GERAL: </span> {{matrix?.competence_description}} </p>
        </th>
        <th [attr.colspan]="(matrix?.capacities.length)">
          <p> CAPACIDADES </p>
        </th>
      </tr>
      <tr>
        <th rowspan="3" class="head-competence">
          <p> UNIDADES DE COMPETÊNCIA </p>
        </th>
        <th rowspan="3" class="head-competence">
          <p> ELEMENTOS DE COMPETÊNCIA </p>
        </th>
        <th [attr.colspan]="(numberBasics.length)">
          <p>BÁSICAS</p>
        </th>
        <th [attr.colspan]="(numberTecnic.length)">
          <p>TÉCNICAS</p>
        </th>
        <th *ngIf="numberManagement.length > 0" [attr.colspan]="(numberManagement.length)">
          <p>GESTÃO</p>
        </th>
        <th *ngIf="numberPsicomotor.length > 0" [attr.colspan]="(numberPsicomotor.length)">
          <p>PSICOMOTOR</p>
        </th>
      </tr>
      <tr>
        <th *ngFor="let item of matrix?.capacities">
          C{{item.code}}
        </th>
      </tr>
      <tr>
        <th *ngFor="let item of matrix?.capacities">
          {{item.description}}
        </th>
      </tr>
    </thead>

    <tbody>

      <tr *ngFor="let item of competence_units">
        <td class="friendly" *ngIf="item.competency_unity_description" [attr.rowspan]="item.competency_unity_span">
          <p>{{item?.competency_unity_description}}</p>
        </td>
        <td>{{item?.description}}</td>
        <td *ngFor="let know of item.competences_elements_capacities">
          {{know}}
        </td>
      </tr>

    </tbody>

  </table>
</div>
