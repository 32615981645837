import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CrudService } from '@services/laravel/crud.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ValidateValueComparison } from '@shared/validators/value-comparison.validator';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  passwordErrors: any;
  checkCurrentPassword: any;
  resetPassForm: UntypedFormGroup;
  user: any;

  constructor(
    private _crud: CrudService,
    private _router: Router,
    private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.resetPassForm = new UntypedFormGroup({
      'newPassword': new UntypedFormControl(null),
      'repeatPassword': new UntypedFormControl(null),
    });

    this.resetPassForm.get('repeatPassword').setValidators([ValidateValueComparison(this.resetPassForm.get('newPassword'),
      'Campos nova senha e confirme nova senha precisam ter o mesmo valor'), ValidateRequired]);
  }

  onResetPassSubmit = () => {
    this._crud.post(
    'password/reset',
    {
      password: this.resetPassForm.get('newPassword').value,
      password_confirmation: this.resetPassForm.get('repeatPassword').value,
      email: this.data.email,
      token: this.data.token,
    },
  ).then(resp => {
    const msg = resp['obj'].message;
    this.toastr.success(msg, 'Sucesso!');
    // this._snackbar.open(msg, '', {
    //   duration: 5000,
    //   panelClass: ['success-snackbar']
    // });
    this.dialogRef.close();
    this._router.navigate(['/']);
  });
  }

  onPasswordChange = () => {
    const newpass = this.resetPassForm.value.newpassword;
    const repeatpass = this.resetPassForm.value.repeatPassword;
    if ( (newpass && repeatpass) && ( newpass.value !== '' && repeatpass.value !== '')) {
      this.resetPassForm.get('password').setErrors(null);
      this.resetPassForm.get('newPassword').setErrors(null);
      this.passwordErrors = null;
    }
  }
  onDialogClose = () => {
    this.dialogRef.close();
  }

}
