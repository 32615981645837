import { Component, OnInit, Output, EventEmitter, Inject, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

export interface User {
  id?: string;
  description?: string;
}

export interface Application {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-import-exam',
  templateUrl: './import-exam.component.html',
  styleUrls: ['./import-exam.component.css']
})
export class ImportExam implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('examCheckbox') private examCheckbox: QueryList<any>;

  displayedColumns: string[] = ['select', 'type', 'particularity', 'preview'];

  optionsUser: User[] = [];
  optionsApplication: Application[] = [];

  isLoading = false;
  validForm = false;
  public userArray: any;
  public eventArray: any;
  public applicationArray: any;
  public examArray: any = [];
  public courseArray: any;
  event_id: any;
  msg;
  importExamForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControlUser = new UntypedFormControl();
  myControlApplication = new UntypedFormControl();
  filteredOptionsUser: Observable<User[]>;
  filteredOptionsApplication: Observable<Application[]>;
  public associatedUsers: any = [];


  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ImportExam>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.formInit();
    this.listApplications();
    this.listCourses();
  }

  listApplications = () => {
    this.isLoading = true;
    this._crud.post('events/general-search', {
      'fields': {},
      'dates': {},
      'orderBy': { 'description': 'asc' },
      'limit': null,
      'noPaginate': true,
      'page': null
    }).then(res => {
      this.applicationArray = res['data'];
      this.isLoading = false;
    });
  }

  listCourses = () => {
    this._crud.post('course-event/general-search', {
      'fields': {},
      'dates': {},
      // "orderBy": { 'description': 'asc' },
      'where': {
        'event_id': this.importExamForm.value.source_event_id
      },
      'limit': null,
      'noPaginate': true,
      'page': null
    }).then(res => {
      this.courseArray = res['data'];
      this.importExamForm.get('course_id').enable();
    });
  }

  listExams() {
    this.isLoading = true;
    this._crud.get('exams?where[]=event_id,' + this.importExamForm.value.source_event_id + '&where[]=course_id,' + this.importExamForm.value.course_id).then(res => {
      this.examArray = res['data'];
      this.isLoading = false;
    });
  }

  formInit() {
    this.importExamForm = new UntypedFormGroup({
      'source_event_id': new UntypedFormControl(null, [Validators.required]),
      'course_id': new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
      'destination_event_id': new UntypedFormControl(this.event_id),
      'exams_ids': new UntypedFormControl(null)
    });
  }

  close = () => {
    this.dialogRef.close();
  }

  previewExam(exam) {
    this.loadingMessage();

    this._crud.file('exams/preview', {
        'id': exam.id
    }).then(res => {
      saveAs(res, 'prova_preview.pdf');
        this.dialog.closeAll();
    }, rej => {
      this.toastr.error('Falha do tentar gerar Preview', 'Erro!');
      this.dialog.closeAll();
    });
  }

  loadingMessage() {
    const array = [];
    const string = '';

    const dialogRef = this.dialog.open(LoadingModalComponent, {
        width: '520px',
        height: 'auto',
        disableClose: true
    });
    dialogRef.componentInstance.title = 'Aguarde...';
    dialogRef.componentInstance.mensagem = 'Carregando dados...';
    dialogRef.componentInstance.showBtn = false;
  }

  submitForm() {
    const examCheckbox = this.examCheckbox.toArray();
    const exams_ids = [];

    for (let i = 0; i < examCheckbox.length; i++) {
      if (examCheckbox[i].checked) {
        exams_ids.push(examCheckbox[i].value.toString());
      }
    }

    this.importExamForm.get('exams_ids').setValue(exams_ids);

    this._crud.post('evaluation-items/exam', this.importExamForm.value)
      .then(res => {
        this.toastr.success('Provas importadas com Sucesso!', 'Sucesso!');
        this.dialogRef.close();
      });
  }
}
