<app-modal-header [hasClose]='true' [title]="'RELACIONAR TURMA'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">
  <mat-error *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </mat-error>
  <form [formGroup]="classForm" #formDirective="ngForm">
    <div class="student-form-content">
      <div class="form-pattern flex">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="regional_id" placeholder="Regional" required (selectionChange)="filterCity2($event)">
            <mat-option *ngFor="let dr of drsArray" [value]="dr.id">
              {{dr.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('regional_id').errors && classForm.get('regional_id').touched">
            {{classForm.get('regional_id').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="municipio" placeholder="Município" (selectionChange)="filterSchools($event)" required [disabled]="!classForm.get('regional_id').value">
            <mat-option *ngFor="let city of cityArrayFiltered2" [value]="city.id">
              {{city.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('municipio').errors && classForm.get('municipio').touched">
            {{classForm.get('municipio').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-pattern flex">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="school_id" placeholder="Escola" required (selectionChange)="filterCourses($event)" [disabled]="!classForm.get('municipio').value || schoolArrayFiltered.length == 0">
            <mat-option *ngFor="let school of schoolArrayFiltered" [value]="school.id">
              {{school.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('school_id').errors && classForm.get('school_id').touched">
            {{classForm.get('school_id').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="course_id" placeholder="Curso" required (selectionChange)="filterClasses($event)" [disabled]="!classForm.get('school_id').value  || courseArray.length == 0">
            <mat-option *ngFor="let course of courseArray" [value]="course.id">
              {{course.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('course_id').errors && classForm.get('course_id').touched">
            {{classForm.get('course_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-pattern flex">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="status_id" placeholder="Status no Curso" required [disabled]="courseArray.length == 0">
            <mat-option *ngFor="let status of coursesStatusArray" [value]="status.id">
              {{status.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('status_id').errors && classForm.get('status_id').touched">
            {{classForm.get('status_id').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="class_id" placeholder="Turma" required [disabled]="!classForm.get('school_id').value || !classForm.get('course_id').value">
            <mat-option *ngFor="let class of classesArray" [value]="class.id">
              {{class.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="classForm.get('class_id').errors && classForm.get('class_id').touched">
            {{classForm.get('class_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-pattern flex">
        <div class="form-pattern-check-area">
          <mat-checkbox class="list-item" #requireCheckbox [value]="" formControlName="pronatec">Pronatec</mat-checkbox>
          <mat-checkbox class="list-item" #requireCheckbox [value]="" formControlName="ebep">EBEP</mat-checkbox>
          <mat-checkbox class="list-item" #requireCheckbox [value]="" formControlName="ead">EAD</mat-checkbox>
        </div>
      </div>
    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer">

  <button id="modalApplyBtn" type="button" mat-raised-button (click)="addClass()" color="accent" [disabled]="classForm.invalid">Incluir</button>

</div>
