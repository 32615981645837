import { HttpClient } from '@angular/common/http';
import { HttpServiceUtil } from './http-service-util';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FeedService {

  public url = environment.urlToApi;

  constructor(public http: HttpClient, public toastr: ToastrService) {}

  get = (route) => new Promise((resolve, reject) => {

    this.http.get<any>(this.url + route, HttpServiceUtil.setHeaders())
    .subscribe(res => resolve(res), (rej: Response) => {
      HttpServiceUtil.errorHandling(rej, this.toastr);
      reject(rej);
    });

  });

  post = (route, data) => new Promise((resolve, reject) => {

    let http: HttpClient;
    let url = environment.urlToApi;

    http.post(url + route, data, HttpServiceUtil.setHeaders())
    .subscribe(res => resolve(res), (rej: any) => {
      if (rej.status == 403) { reject({ 'message': 'Não autorizado.' }); }
      {
        HttpServiceUtil.errorHandling(rej, this.toastr);
        reject(rej);
      };
    });

  });

  listPermissions(route) {
    return this.get('access-profile-user?route=' + route).then(res => res);
  }

  listCategoryParameter() {
    return this.get('public/menu-categories?noPaginate=true&order=description,asc').then(res => res);
  }

  listSettingsCategoryParameter = () => {
    return this.get('parameters/categories?noPaginate=true&order=description,asc').then(res => res);
  }

  listMenus() {
    return this.get('menu/order?noPaginate=true').then(res => res);
  }

  listThemes() {
    return this.get('faq-subjects?noPaginate=true&order=description,asc').then(res => res['data']);
  }

  listMatrices() {
    return this.get('public/matrices').then((res: any) => res.data || res);
  }

  listProfiles() {
    return this.get('public/profile?order=description,asc').then(res => res['data']);
  }

  listApplications() {
    return this.get('public/events?order=description,asc').then(res => res['data']);
  }

  listCities(regionalId: any = null) {
    return this.get('public/cities?noPaginate=true&order=description,asc'+(regionalId ? '&where=regional_id,'+regionalId : '')).then(res => res['data']);
  }

  listDrs() {
    return this.get('public/regional?noPaginate=true&order=description,asc').then(res => res['data']);
  }

  listCourses() {
    return this.get('public/courses?noPaginate=true&order=description,asc&noRelationship=true') .then(res => res['data']);
  }

  listValidCourses() {
    return this.get('public/valid-courses').then(res => res);
  }

  listCoursesSchools(schoolId: any = null) {
    return this.get('public/coursesSchool/school/'+(schoolId ? schoolId : '')) .then(res => res);
  }

  listSchools(regionalId: any = null, cityId: any = null) {
    return this.get('public/school?order=description,asc&noPaginate=true&noRelationship=true'+(regionalId ? '&where=regional_id,'+regionalId : '')+(cityId ? '&where=city_id,'+cityId : '')).then(res => res['data']);
  }

  listAreas() {
    return this.get('public/areas?noPaginate=true&order=description,asc').then(res => res);
  }

  listAxis() {
    return this.get('public/axis?noPaginate=true&order=description,asc').then(res => res);
  }

  listFaqs(order) {
    return this.get('public/faq?noPaginate=true&order=description,' + order) .then((res: any) => res.data || res);
  }

  listCoverSheets(type: any = null) {
    return this.get('public/cover-sheet'+(type?'?where=type,'+type:'')) .then((res: any) => res.data || res);
  }

  listEvaluatorManuals() {
    return this.get('public/evaluators-manuals') .then((res: any) => res.data || res);
  }

  listExams() {
    return this.get('public/exams') .then((res: any) => res.data || res);
  }

  listVerificationLists(matrix) {
    return this.post('list-verification/general-search', { where: { 'matrix:course_id': matrix }, noPaginate: true }) .then((res: any) => { res; });
  }

  listCoursesByMatrices() {
    return this.get('public/matrices/courses').then((res: any) => res.data || res);
  }
}
