<app-modal-header [hasClose]='true' [title]="'ADICIONAR EVIDÊNCIA'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="evidencesForm" *ngIf="evidencesForm">
    <!-- <h4 style="text-align: left;">{{performance_standard_description}}</h4><br> -->
    <mat-form-field>
      <mat-select formControlName="performance_standard_id" placeholder="Padrão de Desempenho">
        <mat-option *ngFor="let item of performance_standards" [value]="item.id">
          {{competence_element_code}}.{{item.code}} - {{item.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input matInput formControlName="description" type="text" placeholder="Evidência" required>
        <mat-error *ngIf="evidencesForm.get('description').errors && evidencesForm.get('description').touched">
            {{evidencesForm.get('description').errors['message']}}</mat-error>
    </mat-form-field>

    <div class='flex'>
      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="capacity_id" placeholder="Capacidade" required>
          <mat-option *ngFor="let capacity of capacities" [value]="capacity.id">
            C{{capacity.code}} - {{capacity.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="evidencesForm.get('capacity_id').errors && evidencesForm.get('capacity_id').touched">
          {{evidencesForm.get('capacity_id').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field class="half-input">
        <!-- <input matInput formControlName="difficulty" type="number" placeholder="Peso" required> -->
        <mat-select matSelect formControlName="difficulty" placeholder="Peso" required>
          <mat-option *ngFor="let item of difficultys" [value]="item.value"> {{item.description}} </mat-option>
        </mat-select>
        <mat-error *ngIf="evidencesForm.get('difficulty').errors && evidencesForm.get('difficulty').touched">
          {{evidencesForm.get('difficulty').errors['message']}}</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field class="half-input">
      <input type="text" [formControl]="myActivityControl" placeholder="Atividade" aria-label="Atividade" matInput
        [matAutocomplete]="autoActivity" (keyup)="validControl()" [disabled]="!filteredOptionsActivity">
      <mat-autocomplete #autoActivity="matAutocomplete" [displayWith]="displayFnActivity" matSelect placeholder="Escolha a Atividade" (optionSelected)="validControl()">
        <mat-option *ngFor="let activity of filteredOptionsActivity | async" [value]="activity">
          {{activity?.description}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint *ngIf="evidencesForm.get('activity_evidence_id').errors && evidencesForm.get('activity_evidence_id').touched">
          {{evidencesForm.get('activity_evidence_id').errors['message']}}</mat-hint>
    </mat-form-field>

    <div class="profiles-check-area-container">
      <p class="primary-text">Objetos de Conhecimento:</p><br>

      <div class="profiles-check-block">
          <div class="profiles-check-area" *ngFor="let knowledge of knowledges">
            <mat-checkbox class="profile-list-item" #knowledgeCheckbox [value]="knowledge.id" [disabled]="disbledCheck"
            [checked]="checkKnowledgeObject(knowledge.id)"><span [title]="knowledge.code">{{sigla(knowledge.code)}}</span> - {{knowledge.description}}</mat-checkbox>
          </div>
      </div>
    </div>

  </form>

</mat-dialog-content>

<div class="modal-footer">
  <button type="button" id="modalApplyBtn" mat-raised-button color="accent" [disabled]="evidencesForm.invalid" (click)="onSubmit()">Salvar</button>
  <!-- <button type="button" mat-raised-button (click)="close()" class="far-left form-btn"></button>
    Cancelar
  </button> -->
</div>
