<app-modal-header [hasClose]='true' [title]="title" (handleClose)="onDialogClose()"></app-modal-header>

<mat-dialog-content class="content-dialog">
  <app-loading *ngIf="!message"></app-loading>

  <div class="mg1" *ngIf="message" style="text-align: left;">
    <div *ngIf="message.message_pai" class="preview-messages mgb1">
      <mat-expansion-panel #msg>
        <mat-expansion-panel-header>
          <mat-panel-title class="bold-text preview-messages" *ngIf="msg.expanded">
            Mensagem Original
          </mat-panel-title>

          <mat-panel-description *ngIf="!msg.expanded" [innerHtml]="message.message_pai.message">

          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-message [isParent]="true" [showSender]="showSender" [recipients]="recipients"
          [message]="message.message_pai" (openRecipientList)="viewRecipientList()"></app-message>
      </mat-expansion-panel>
    </div>


    <div>
      <app-message [isParent]="false" [showSender]="showSender" [recipients]="recipients" [message]="message"
        (openRecipientList)="viewRecipientList()"></app-message>
    </div>

  </div>

</mat-dialog-content>
