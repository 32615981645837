<app-modal-header [hasClose]='true' [title]="'IMPORTAR FOLHA DE ROSTO'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="importCoverSheetForm" *ngIf="!isLoading">
    <mat-form-field class="mg1 matrix-select" *ngIf="applicationArray.length > 0">
      <mat-select matSelect formControlName="source_event_id" placeholder="Aplicação" (selectionChange)="listCoverSheets()">
        <mat-option *ngFor="let application of applicationArray" [value]="application.id">
          {{application.description}}
        </mat-option>
      </mat-select>
    </mat-form-field><br>

    <h1 class="primary-text">FOLHAS DE ROSTO ENCONTRADAS:</h1>

    <div *ngIf="!isLoading && coverSheetArray.length > 0">
      <table mat-table [dataSource]="coverSheetArray" class="mat-elevation-z8 data-table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> * </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox #coverSheetCheckbox [value]="element.id"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="preview">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Visualizar </th>
          <td mat-cell *matCellDef="let element">
            <a mat-button mat-icon-button class="delete-evidence-btn" (click)="previewCoverSheet(element)">
              <mat-icon mat-list-icon>zoom_in</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="particularity">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Particularidade </th>
          <td mat-cell *matCellDef="let element"> {{element.particularity}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="primary-text"> Tipo </th>
          <td mat-cell *matCellDef="let element"> {{element.type ? 'Manual do Avaliador' : 'Prova Prática'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </form>

  <div *ngIf="isLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</mat-dialog-content>

<div class="modal-footer">

  <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" [disabled]="!importCoverSheetForm.valid" (click)="onSubmmit()">
    Importar
  </button>
  <!-- <button type="button" mat-raised-button class="far-left" (click)="close()">
    Cancelar
  </button> -->

</div>
