<app-modal-header [hasClose]='true' (handleClose)="close()"></app-modal-header>

<h4 mat-dialog-title class="modal-toolbar-title primary">
  <button *ngIf="showApplyBtn" type="button" mat-raised-button color="primary" class="secondary primary-text" (click)="close()">
    Aplicar
  </button>
</h4>

<div id="contentToConvert" class="cover-sheet-model example-boundary">
  <img [src]="coverSheetArt.src">
  <form class="" [formGroup]="coverSheetForm" #formDirective="ngForm">
    <div [ngStyle]="contentStyle" class="content-box" cdkDrag (cdkDragEnded)="dragEnded($event)" cdkDragBoundary=".example-boundary" *ngIf="coverSheetData"
      [innerHtml]="coverSheetData.description"></div>
  </form>
</div>
