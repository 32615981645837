import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { FeedService } from '@services/laravel/feed.service';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  value: string;
  viewValue: string;
}

export interface User {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  events: Event[] = [
    {value: '1', viewValue: 'Evento 1'},
    {value: '2', viewValue: 'Evento 2'},
    {value: '3', viewValue: 'Evento 3'},
    {value: '4', viewValue: 'Evento 4'},
    {value: '5', viewValue: 'Evento 5'},
  ];

  options: User[] = [];

  isLoading = true;
  validForm = false;

  public school_id: any;
  public userArray: any;
  public axisArray: any;
  public areasArray: any;
  public areasArrayFiltered: any;
  public coursesArray: any;
  public coursesArrayFiltered: any;

  application_id: any;
  msg;
  addCourseForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  public associatedUsers: any = [];


  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<AddCourseComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    private feedService: FeedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.formInit();
    this.listCourses();
  }

  formInit(){
    this.addCourseForm = new UntypedFormGroup({
      'deleted_at': new UntypedFormControl(null),
      'axis_id': new UntypedFormControl(null),
      'area_id': new UntypedFormControl(null),
      'school_id': new UntypedFormControl(this.school_id),
      'course_id': new UntypedFormControl(null, [Validators.required]),
      'event_id': new UntypedFormControl(JSON.parse(sessionStorage.getItem('application')).id)
    });
  }

  userFormInit(){
    this.addUsersForm = new UntypedFormGroup({
      'user_id': new UntypedFormControl([], [Validators.required]),
      'event_id': new UntypedFormControl(this.application_id, [Validators.required])
    });
  }

  displayFn(user?: User): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): User[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0);
  }

  listCourses = () => {
    this._crud.get('course-event/with-context?noPaginate=1').then(res => {
      this.coursesArrayFiltered = res;
    });
  }

  close = () => {
    this.dialogRef.close();
  }

  addUser(){
    let user = this.myControl.value;
    this.myControl = new UntypedFormControl();

    this.addUsersForm.value['user_id'].push({id: user.id});
    this.associatedUsers.push({id: user.id, description: user.description});

    this.options = [];

    this.formInit();

    this.validForm = true;
  }

  removeUser(user){
    let user_id = user.id;
    let index = this.addUsersForm.value['user_id'].indexOf(user);
    let index2 = this.associatedUsers.indexOf(user);
    this.addUsersForm.value['user_id'].splice(index, 1);
    this.associatedUsers.splice(index2, 1);
  }

  addCourse = () => {
    this._crud.post('relate-course', this.addCourseForm.value)
      .then(res => {
        this.toastr.success('Curso relacionado com Sucesso!', 'Sucesso!');
        this.dialogRef.close();
      });
  }
}
