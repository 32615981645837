<app-modal-header [hasClose]='true' [title]="'Adicionar Perfil'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog" *ngIf="!loading">

  <form [formGroup]="profileForm" #formDirective="ngForm">
    <div class="options">
      <div class="form-pattern">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="access_profile_id" placeholder="Perfis de Acesso"
            (selectionChange)="verifyRequeriments($event)">
            <mat-option *ngFor="let profile of profileArray" [value]="profile.invite_profile_id">
              {{profile.description}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="profileForm.get('access_profile_id').errors && profileForm.get('access_profile_id').touched">
            {{profileForm.get('access_profile_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <mat-error *ngIf="showSchoolInputErrorMessage()">
        Nenhuma escola está disponível no momento para este DR.
        <br>Entre em contato com o(a) interlocutor(a).
      </mat-error>

      <div class="form-pattern" *ngIf="showSchoolInput()">
        <mat-form-field class="half-input">
          <input type="text" placeholder="Escolha uma escola" aria-label="Escola" matInput
            [formControl]="mySchoolControl" [matAutocomplete]="autoSchool">
          <!-- <mat-select matSelect formControlName="school_id" placeholder="Escola" required (selectionChange)="filterCourse($event)"> -->
          <mat-autocomplete #autoSchool="matAutocomplete" [displayWith]="displayFnSchool" matSelect
            placeholder="Escolha uma escola" (optionSelected)="validControl()">
            <mat-option *ngFor="let school of filteredSchoolArray | async" [value]="school">
              {{school.description}}
            </mat-option>
          </mat-autocomplete>
          <!-- </mat-select> -->
          <mat-error *ngIf="profileForm.get('school_id').errors && profileForm.get('school_id').touched">
            {{profileForm.get('school_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-pattern" *ngIf="showCourseInput()">
        <mat-form-field class="half-input">
          <mat-select matSelect formControlName="course_id" placeholder="Curso">
            <mat-option *ngFor="let course of coursesArray" [value]="course.id">
              {{course?.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="profileForm.get('course_id').errors && profileForm.get('course_id').touched">
            {{profileForm.get('course_id').errors['message']}}</mat-error>
        </mat-form-field>
      </div>

      <mat-error *ngIf="showCourseInputErrorMessage()">
        Nenhum curso está disponível no momento para esta aplicação.
      </mat-error>

    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="primary"
    [disabled]="profileForm.invalid || loadingList" color="accent" (click)="addProfile()">
    Aplicar Perfil
  </button>
</div>
