<app-modal-header [hasClose]='true' [title]="'ASSOCIAR LOGIN COM O GOOGLE'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="associationForm" class="pd0">

    <mat-form-field class="half-input">
      <input [textMask]="{mask: mask.cpf}" matInput formControlName="cpf_number" type="text" placeholder="CPF" required>
      <mat-error *ngIf="associationForm.get('cpf_number').errors && associationForm.get('cpf_number').touched">
        {{associationForm.get('cpf_number').errors['message']}}</mat-error>
    </mat-form-field>

    <mat-form-field class="half-input">
      <input matInput formControlName="password" type="password" placeholder="Senha" required>
      <mat-error *ngIf="associationForm.get('password').errors && associationForm.get('password').touched">
        {{associationForm.get('password').errors['message']}}</mat-error>
    </mat-form-field>

  </form>

</mat-dialog-content>

<div class="modal-footer">
  <!-- <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button> -->
  <button id="modalApplyBtn" class="primary-action-btn" mat-raised-button type="submit" (click)="association()" [disabled]="associationForm.invalid">Confirmar</button>
</div>
