import { AbstractControl } from '@angular/forms';

export function ValidateTime(control: AbstractControl) {

  let value = control.value;

  if (value) {

    let numbers = 0;
    for(let lim = value.length, i = 0; i < lim; i++) {
      if(!isNaN(value[i])) {
        numbers ++;
      }
    }

    if (numbers == 4) {

      if ((parseInt(value.substr(0, value.indexOf(':'))) >= 0 && parseInt(value.substr(0, value.indexOf(':'))) <= 23)
      && (parseInt(value.substr((value.indexOf(':')+1), value.length)) >= 0 && parseInt(value.substr((value.indexOf(':')+1), value.length)) <= 59)) {

        return null;

      } else {

        return {
          validate: false,
          message: 'Hora inválida'
        };

      }

    } else {

      if (numbers > 0)
        return {
          validate: false,
          message: 'Hora inválida'
        };

      return null;

    }

  }

  return null;
}
