<app-modal-header [hasClose]='true' [title]="'Recuperar Senha'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form class="not-apply-padding" [formGroup]="forgotPasswordForm">
    <div id="text" class="primary-text">
      Para realizar a alteração de senha e voltar a ter acesso, preencha os campos abaixo:
    </div>

    <mat-form-field>
      <input type="text" [textMask]="{mask: mask.cpf}" formControlName="field_0_cpf" matInput placeholder="CPF">

      <mat-error *ngIf="forgotPasswordForm.controls['field_0_cpf'].hasError('required') &&
        forgotPasswordForm.controls['field_0_cpf'].touched">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="!forgotPasswordForm.controls['field_0_cpf'].hasError('required') &&
        !forgotPasswordForm.controls['field_0_cpf'].valid &&
        forgotPasswordForm.controls['field_0_cpf'].touched">
        Cpf inválido
      </mat-error>
    </mat-form-field>



    <mat-form-field>
      <input type="text" [textMask]="{mask: mask.date}" formControlName="field_1_birthday" matInput placeholder="Data de Nascimento">

      <mat-error *ngIf="forgotPasswordForm.controls['field_1_birthday'].hasError('required') && forgotPasswordForm.controls['field_1_birthday'].touched">
        Campo obrigatório
      </mat-error>
    </mat-form-field>


    <div *ngIf="!isContinuosIntegration()">
      <re-captcha class="captcha" language="pt-BR" (captchaResponse)="handleCorrectCaptcha($event)" (captchaExpired)="recaptchaExpired()"
        site_key="6Le7EXMUAAAAAEvNpc6qtRMbccxWB2rTGIvPytoz"></re-captcha>
    </div>

    <div style="margin: 10px 0px;">
      <p style="color: red;">
        Atenção: Caso não receba o e-mail de recuperação de senha nos próximos 10 minutos, verifique o SPAM ou Lixo Eletrônico.
      </p>
    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer">
  <div *ngIf="isLoading">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
  <button type="button" mat-raised-button color="accent" [disabled]="!forgotPasswordForm.valid" (click)="forgotPassword()" *ngIf="!generatedPassword && !errorMsg && !isLoading">
    Recuperar Senha
  </button>

  <div *ngIf="errorMsg" class="flex">
    <p class="new-password-failed">{{errorMsg}}<i class="password"></i></p>
    <button id="modalApplyBtn" type="button" mat-raised-button color="accent" (click)="selfRegister()">
      AUTOCADASTRO
    </button>
  </div>
</div>
