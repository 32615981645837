import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { SnackBarService } from '@services/snackbar.service';
import { ValidateRequired } from '../../validators/required.validator';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  value: string;
  viewValue: string;
}

export interface User {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.css']
})
export class AttachFileComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  options: User[] = [];

  isLoading = true;
  validForm = false;

  public userArray: any;
  public axisArray: any;
  public areasArray: any;
  public areasArrayFiltered: any;
  public coursesArray: any;
  public coursesArrayFiltered: any;

  application_id: any;
  msg;
  addCourseForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  public attachmentForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  public associatedUsers: any = [];

  public student_id: any;
  public schoolArray: any;
  public schoolArrayFiltered: any;
  public bkpSchoolArray: any;
  public drsArray: any;
  public bkpCourseArray: any;
  public courseArray: any;
  public cityArray: any;
  public cityArrayFiltered: any;
  public cityArrayFiltered2: any;
  public classesArray: any;
  public coursesStatusArray: any;

  public filesToAttach: any = [];
  public filesToAttachMeta: any = [];
  public attachedFiles: any = [];
  public tempBase64: any;

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<AttachFileComponent>,
    private router: Router,
    private _crud: CrudService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.newForm();
  }

  newForm = () => {
    this.attachmentForm = new UntypedFormGroup({
      'selected_file': new UntypedFormControl(null, [ValidateRequired]),
      'file_description': new UntypedFormControl(null, [ValidateRequired]),
      'filename': new UntypedFormControl(null),
    });
  }

  async onUploadMedia(event){
    let files = event.target.files;
    let base64;

    if(files.length > 0 && files){
      this.cleanUpload();

      await this.getBase64(event.target.files[0]).then(
        data => {
          this.tempBase64 = data;
        }
      );

      this.filesToAttach.push(this.tempBase64);

      Array.from(files).forEach((f, index)=>{
        let file: any = f;
        file.description = this.attachmentForm.value['file_description'];

        this.filesToAttachMeta.push({'filename': file.name, 'lastModified': file.lastModified, 'file_description': file.description});
      })
    }
  }

  attachFiles() {
    if(this.filesToAttach && this.filesToAttach.length > 0){
      this.filesToAttachMeta[0].file_description = this.attachmentForm.value['file_description'];

      this.dialogRef.close({'fileData' : this.filesToAttachMeta[0], 'file' : this.filesToAttach[0]});
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  cleanUpload() {
    this.filesToAttach = [];
    this.filesToAttachMeta = [];
  }

  isAttachmentFilledUp() {
    return !(this.attachmentForm.value['file_description'] && this.filesToAttach &&
            this.attachmentForm.value['file_description'].trim().length > 0 && this.filesToAttach && this.filesToAttach.length > 0);
  }

  onClose(){
    this.dialogRef.close();
  }

  reduceToView(textToReduce) {
    if (textToReduce.length > 20) {
      return textToReduce.substring(0, 9) + '...' + textToReduce.substring(textToReduce.length - 8, textToReduce.length);
    }
    return textToReduce;
  }
}
