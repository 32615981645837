import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent, asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-commitment-term-modal',
  templateUrl: './commitment-term-modal.component.html',
  styleUrls: ['./commitment-term-modal.component.css']
})
export class CommitmentTermModalComponent implements OnInit {

  public term: any;
  public blockBtn = true;
  loading = false;

  @ViewChild('termContent', {static: false}) termContent;
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (event.target.scrollTop === (event.target.scrollHeight - event.target.offsetHeight)) {
      this.blockBtn = false;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CommitmentTermModalComponent>,
    private crud: CrudService,
    private toastr: ToastrService
  ) {

  }

  async ngOnInit() {
    await this.loadCommitmentTerm();

    setTimeout(() => {
      if (this.termContent.nativeElement.scrollHeight < 540) {
        this.blockBtn = false;
      }
    }, 1000);
  }

  loadCommitmentTerm() {
    this.loading = true;
    this.crud
      .get('commitment-term').then(res => {
        this.term = res;
        this.loading = false;
      });
  }

  acceptTerm() {
    this.crud.put('commitment-term/accept', {}).then((res: any) => {
      this.dialogRef.close(true);
    }).catch(rej => {
      this.toastr.error('Falha ao aceitar o termo.', 'Erro!');
    });

  }

  onDialogClose = () => {
    this.dialogRef.close();
  }
}
