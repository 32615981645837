<app-modal-header [hasClose]='true' [title]="'ADICIONAR PROVAS'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="importTestForm">
    <div id="text" class="primary-text">
      Selecione a prova que deseja adicionar nessa aplicação.
    </div>

    <div class="flex">
      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="regional_id" placeholder="DR" required>
          <mat-option *ngFor="let dr of drArray" [value]="dr.id">
            {{dr.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="importTestForm.get('regional_id').errors && importTestForm.get('regional_id').touched">
              {{importTestForm.get('regional_id').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="school_id" placeholder="Escola" required>
          <mat-option *ngFor="let school of schoolArray" [value]="school.id">
            {{school.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="importTestForm.get('school_id').errors && importTestForm.get('school_id').touched">
              {{importTestForm.get('school_id').errors['message']}}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex">
      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="course_id" placeholder="Curso" required>
          <mat-option *ngFor="let course of courseArray" [value]="course.id">
            {{course.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="importTestForm.get('course_id').errors && importTestForm.get('course_id').touched">
              {{importTestForm.get('course_id').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="exam_id" placeholder="Prova" required>
          <mat-option *ngFor="let school of courseArray" [value]="school.id">
            {{school.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="importTestForm.get('exam_id').errors && importTestForm.get('exam_id').touched">
              {{importTestForm.get('exam_id').errors['message']}}</mat-error>
      </mat-form-field>
    </div>

  </form>

</mat-dialog-content>


<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="accent" [disabled]="!importTestForm.valid" (click)="importTest()">
    Aplicar
  </button>
</div>
