import { Component, OnInit, Output, EventEmitter, Inject, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { ModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';
import { ValidateFields } from '@shared/validators/required.validator';
import { SnackBarService } from '@services/snackbar.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { FeedService } from '@services/laravel/feed.service';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  id?: string;
  description?: string;
}

export interface Activity {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-add-evidence-to-lv',
  templateUrl: './add-evidence-to-lv.component.html',
  styleUrls: ['./add-evidence-to-lv.component.css']
})
export class AddEvidenceToLvComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('knowledgeCheckbox') private knowledgeCheckbox: QueryList<any>;

  isLoading = true;
  public applicationsArray: any;
  public performance_standards = [];
  public profileArray: any;
  public knowledges: any;
  public capacities: any;
  public evidenceOrder: any;
  public userArray: any = [];
  public difficultys = [
    { value: 1, description: '1 - Fácil' },
    { value: 2, description: '2 - Médio' },
    { value: 3, description: '3 - Difícil' }
  ];
  msg;
  evidencesForm: UntypedFormGroup;
  public activityForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<Event[]>;
  options: Event[] = [];

  order: any;
  evidence: any;
  disbledCheck = false;
  lvId: any;
  performance_standard_description: any;
  public competence_element_code: String;
  performance_standard_id: any;
  public finalValue: boolean = true;

  optionsActivity: Activity[] = [];
  myActivityControl = new UntypedFormControl();
  filteredOptionsActivity: Observable<Activity[]>;

  public activityArray: any;
  public activityArrayFiltered: any = [];

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<AddEvidenceToLvComponent>,
    private router: Router,
    private _crud: CrudService,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    private feedService: FeedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.loadEvidences();

  }

  loadEvidences = () => {
    let lv_id;
    let evidence_id;

    if (this.performance_standard_id) {
      evidence_id = this.performance_standard_id;
      this.listActivity();
    } else if (this.evidence) {
      evidence_id = this.evidence.performance_standard_id;
      this.listActivity({ 'id': this.evidence.activity_evidence.id, 'description': this.evidence.activity_evidence.description });
    }

    if (this.lvId) {
      lv_id = this.lvId;
    } else if (this.evidence) {
      lv_id = this.evidence.list_verification_id;
      if (this.evidence.list_verification_generated_count) { this.disbledCheck = true; }
    }

    this.newForm();

  }

  newForm() {

    if (this.evidence) {
      this.evidencesForm = new UntypedFormGroup({
        'id': new UntypedFormControl(this.evidence.id),
        'performance_standard_id': new UntypedFormControl(this.evidence.performance_standard_id, [ValidateRequired]),
        'list_verification_id': new UntypedFormControl(this.evidence.list_verification_id, [ValidateRequired]),
        'description': new UntypedFormControl(this.evidence.description, [ValidateRequired]),
        'activity_evidence_id': new UntypedFormControl(this.evidence.activity_evidence_id, [ValidateRequired]),
        'capacity_id': new UntypedFormControl(this.evidence.capacity_id, [ValidateRequired]),
        'difficulty': new UntypedFormControl(this.evidence.difficulty, [ValidateRequired]),
        'obj_knowledges': new UntypedFormControl(null),
        'order': new UntypedFormControl(this.evidence.order)
      });
      if (this.evidence.list_verification_generated_count) {

        this.evidencesForm.controls['activity_evidence_id'].disable();
        this.evidencesForm.controls['capacity_id'].disable();
        this.evidencesForm.controls['difficulty'].disable();

      }
    } else {

      this.evidencesForm = new UntypedFormGroup({
        'performance_standard_id': new UntypedFormControl(this.performance_standard_id, [ValidateRequired]),
        'list_verification_id': new UntypedFormControl(this.lvId, [ValidateRequired]),
        'description': new UntypedFormControl(null, [ValidateRequired]),
        'capacity_id': new UntypedFormControl(null, [ValidateRequired]),
        'difficulty': new UntypedFormControl(null, [ValidateRequired]),
        'activity_evidence_id': new UntypedFormControl(null, [ValidateRequired]),
        'obj_knowledges': new UntypedFormControl(null)
      });

      this.evidencesForm.get('performance_standard_id').disable();
    }

    this.evidencesForm = (new ValidateFields).thisUpdateValidators(this.evidencesForm);
  }

  displayFn(user?: Event): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): Event[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0);
  }

  close = () => {
    this.dialogRef.close();
  }

  onSubmit = () => {
    let event = this.myControl.value;
    let obj_knowledges = [];

    const knowledgeCheckbox = this.knowledgeCheckbox.toArray();
    for (let i = 0; i < knowledgeCheckbox.length; i++) {
      if (knowledgeCheckbox[i].checked) {
        obj_knowledges.push(knowledgeCheckbox[i].value.toString());
      }
    }

    if (obj_knowledges.length === 0) {
      this.toastr.error('Você precisa selecionar os objetos de conhecimento.', 'Erro!');
    } else {
      this.evidencesForm.get('obj_knowledges').setValue(obj_knowledges);

      if (this.evidence) {
        let obj = this.evidencesForm.value;
        if (this.disbledCheck) {
          obj = {
            description: this.evidencesForm.value.description
          }
          this._crud.patch('evidences/' + this.evidence.id, obj)
            .then(res => {
              this.toastr.success('Evidência atualizada com Sucesso!', 'Sucesso!');
              this.dialogRef.close(true);
            }).catch((err: any) => {
              this.toastr.success(err.message, 'Erro!');
            });
        } else {
          this._crud.put('evidences/' + this.evidence.id, obj)
            .then(res => {
              this.toastr.success('Evidência atualizada com Sucesso!', 'Sucesso!');
              this.dialogRef.close(true);
            }).catch((err: any) => {
              this.toastr.success(err.message, 'Erro!');
            });
        }

      } else {
        this._crud.post('evidences', { ...this.evidencesForm.value, performance_standard_id: this.performance_standard_id })
          .then(res => {
            this.toastr.success('Evidência cadastrada com Sucesso!', 'Sucesso!');
            this.dialogRef.close(true);
          });
      }
    }
  }

  sigla(param) {
    let ordem = parseInt(param);
    let cod2, cod;

    if (ordem < 27) {
      return String.fromCharCode(ordem + 64);
    } else {
      cod2 = ordem % 26;
      if (cod2 == 0) cod2 = 26;

      cod = parseInt((ordem / 26).toString().split('.')[0]);

      if (ordem % 26 == 0) cod = cod - 1;

      return (
        String.fromCharCode(cod + 64) + '' + String.fromCharCode(cod2 + 64)
      );
    }
  }

  checkKnowledgeObject = (knowledgeId) => {
    if (this.evidence) {
      let result = false;
      for (let j = 0; j < this.evidence.evidences_knowledges.length; j++) {
        if (this.evidence.evidences_knowledges[j].knowledge_id == knowledgeId) {
          result = true;
        } else {
          if (result != true) {
            result = false;
          }
        }
      }
      return result;
    }
    return false;
  }

  listActivity(preVal = null) {
    this.optionsActivity = [];

    this._crud.get(`activities/application?course_id=${this.data.course_id}`).then((res: any) => {
      this.activityArray = res.data || res;
      this.activityArrayFiltered = res.data || res;

      for (let i = 0; i < this.activityArray.length; i++) {
        var activity: Activity = { id: this.activityArray[i].id.toString(), description: this.activityArray[i].description };

        this.optionsActivity.push(activity);
      }

      if (preVal) {
        this.myActivityControl.setValue(preVal);
        this.validControl();
      }

      if (this.disbledCheck) this.myActivityControl.disable();

      this.filteredOptionsActivity = this.myActivityControl.valueChanges
        .pipe(
          startWith<string | Activity>(''),
          map(value => typeof value === 'string' ? value : value.description),
          map(name => name ? this._filterActivity(name) : this.optionsActivity.slice())
        );

    });
  }

  displayFnActivity(activity?: Activity): string | undefined {
    return activity ? activity.description : undefined;
  }

  private _filterActivity(description: string): Activity[] {
    const filterValueActivity = description.toLowerCase();

    if (this.optionsActivity.filter(option => option.description.toLowerCase().indexOf(filterValueActivity) === 0).length > 0) {
      return this.optionsActivity.filter(option => option.description.toLowerCase().indexOf(filterValueActivity) === 0);
    } else {
      return [{ 'id': description, 'description': description + ' não é uma justificativa válida. Deseja Cadastrar?' }];
    }

  }

  validControl() {

    this.evidencesForm.get('activity_evidence_id').markAsTouched();

    if (typeof this.myActivityControl.value == 'object') {
      if (this.myActivityControl.value.description.indexOf('Cadastrar') == -1) {
        this.evidencesForm.get('activity_evidence_id').setValue(this.myActivityControl.value.id);
      } else {
        this.onAddNewValue();
      }
    } else {
      this.evidencesForm.get('activity_evidence_id').setValue(null);
    }
  }

  onAddNewValue() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      height: '250px',
      width: 'auto'
    });

    dialogRef.componentInstance.dialogTitle = 'Confirmação';
    dialogRef.componentInstance.dialogMessage = 'Deseja cadastrar a Atividade <i class=\'focus\'>'
      + this.myActivityControl.value.id
      + '</i>?';

    dialogRef.afterClosed().subscribe(result => {
      this.activityForm = new UntypedFormGroup({
        'description': new UntypedFormControl(this.myActivityControl.value.id, [ValidateRequired]),
        'event_id': new UntypedFormControl(JSON.parse(sessionStorage.getItem('application')).id),
        'course_id': new UntypedFormControl(this.data.course_id)
      });

      if (result) {
        this._crud.post('activities', this.activityForm.value)
          .then(res => {
            this.listActivity({ 'id': res['id'], 'description': res['description'] });

            this.toastr.success('Atividade cadastrada com Sucesso!', 'Sucesso!');
          });
      } else {
        this.myActivityControl.setValue('');
      }
    });
  }
}
