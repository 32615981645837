import { Component, OnInit, Inject, ViewChildren, QueryList} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormArray, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Services
 */
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ToastrService } from 'ngx-toastr';
import { JwtServiceUtil } from '@services/laravel/jwt-service-util.';

export interface Gender {
  value: string;
  viewValue: string;
}

export interface Detail {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-set-user-profile-modal',
  templateUrl: './set-user-profile-modal.component.html',
  styleUrls: ['./set-user-profile-modal.component.css']
})

export class SetUserProfileModalComponent implements OnInit {
  @ViewChildren('optionCheckbox') private optionCheckboxes: QueryList<any>;
  public applicationProfileForm: UntypedFormGroup;
  public loading: boolean;
  public user_id: any;

  public title: string;
  public optionsToSelect: any;
  public selected: any = [];

  public applicationsArray: any;
  public profileArray: any;
  public schoolArray: any;
  public coursesArray: any;

  public profilePosition: string = "profile-access-margin half-input";
  public profileBtnPosition: string = "profile-access-margin2 add-profile secondary primary-text";
  public profilesPosition: string = "profile-access-margin2 profile-check-box-area";

  showCourses: any = false;
  showSchools: any = false;
  constructor(
    private _fb: UntypedFormBuilder,
    private _crud: CrudService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _snackbar: MatSnackBar,
    private dialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SetUserProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.listApplications();
    this.listSchool();
    this.listCourses();

    this.newForm();
    this.listProfiles();
  }

  newForm(){
    this.applicationProfileForm = new UntypedFormGroup({
      'id': new UntypedFormControl(this.user_id),
      'event_id': new UntypedFormControl(null, [ValidateRequired]),
      'access_profile_id': new UntypedFormControl(null, [ValidateRequired]),
      'school_id': new UntypedFormControl(null),
      'course_id': new UntypedFormControl(null),
    });
  }

  initInvitationForm(res){

  }

  listApplications = () => {
    this._crud.get('public/my-events').then((res: any) => this.applicationsArray = res).finally(() => this.loading = false);
  }

  listProfiles = () => {
    const current_application = JSON.parse(sessionStorage.getItem('application')).id;
    this._crud.get('public/my-profiles-for-invitation/' + current_application).then((res: any) => {
      this.profileArray = res;
      this.verifyRequeriments();
    });
  }

  listSchool = () => {
    this._crud.get('school/homologated/'+this.data.regional_id).then(res => {
      this.schoolArray = res['data'];
    });
  }

  listCourses = () => {
    this._crud.get('public/courses?order=description,asc&noPaginate=true&noRelationship=true').then(res => {
      this.coursesArray = res['data'];
    });
  }

  close = () => {
    this.dialogRef.close(false);
  }

  onApply = () => {
    this._crud.post('access-profile-user/user/' + this.user_id, this.applicationProfileForm.value)
      .then(res => {
        this.toastr.success('Perfil adicionado com Sucesso!', 'Sucesso!');
        this.dialogRef.close(true);
      });
  }

  convertDate(date){
    var br_date = date;
    br_date = br_date.split('/');
    var us_date = br_date.reverse().join('-');
    return us_date
  }

  convertDateToBR(date){
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date
  }

  verifyRequeriments(){
    this.applicationProfileForm.get("school_id").setValue(null);
    this.applicationProfileForm.get("course_id").setValue(null);

    let profile_id = this.applicationProfileForm.value['access_profile_id'];
    let school = false;
    let course = false;

    this.profileArray.forEach((value) => {
      if(profile_id == value.invite_profile_id){
        if(value.require_course == 1 && value.require_school == 1){
          course = true;
          school = true;
        }else if(value.require_course == 1){
          course = true;
        }else if(value.require_school == 1){
          school = true;
        }
      }
    });

    if(course && school){
      this.applicationProfileForm.controls['school_id'].setValidators([ValidateRequired]);
      this.applicationProfileForm.controls['school_id'].updateValueAndValidity();

      this.applicationProfileForm.controls['course_id'].setValidators([ValidateRequired]);
      this.applicationProfileForm.controls['course_id'].updateValueAndValidity();

      this.profilePosition = "half-input";
      this.profileBtnPosition = "add-profile";
      this.profilesPosition = "profile-check-box-area";
    }else if(course){
      this.applicationProfileForm.controls['school_id'].setValidators(null);
      this.applicationProfileForm.controls['school_id'].updateValueAndValidity();

      this.applicationProfileForm.controls['course_id'].setValidators([ValidateRequired]);
      this.applicationProfileForm.controls['course_id'].updateValueAndValidity();

      this.profilePosition = "profile-access-margin half-input";
      this.profileBtnPosition = "profile-access-margin add-profile";
      this.profilesPosition = "profile-access-margin profile-check-box-area";
    }else if(school){
      this.applicationProfileForm.controls['school_id'].setValidators([ValidateRequired]);
      this.applicationProfileForm.controls['school_id'].updateValueAndValidity();

      this.applicationProfileForm.controls['course_id'].setValidators(null);
      this.applicationProfileForm.controls['course_id'].updateValueAndValidity();

      this.profilePosition = "half-input";
      this.profileBtnPosition = "profile-access-margin add-profile";
      this.profilesPosition = "profile-access-margin profile-check-box-area";
    }else{
      this.applicationProfileForm.controls['school_id'].setValidators(null);
      this.applicationProfileForm.controls['school_id'].updateValueAndValidity();

      this.applicationProfileForm.controls['course_id'].setValidators(null);
      this.applicationProfileForm.controls['course_id'].updateValueAndValidity();
    }

    this.showCourses = course;
    this.showSchools = school;
  }

  filterCourse(event){
    this._crud.get('public/coursesSchool/school/' + event.value).then((res: any) => {
      this.coursesArray = res.data || res;
    });
  }
}
