import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';

export interface Recipient {
  description: string;
  read_at: string;
  status: string;
}

const ELEMENT_DATA: Recipient[] = [];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ntm-view-recipient-list',
  templateUrl: './view-recipient-list.component.html',
  styleUrls: ['./view-recipient-list.component.css']
})

export class ViewRecipientList implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns: string[] = ['description', 'read_at', 'status'];
  dataSource = [];

  messageId: any;
  message: any;
  groups: any;
  users: any;

  constructor(
    public dialogRef: MatDialogRef<ViewRecipientList>,
    private crud: CrudService,
    private router: Router,
    private matsnackbar: MatSnackBar,
  ) {
    
  }

  ngOnInit() {
    this.organizeData();
  }

  organizeData(){
    for (let g = 0; g < this.message['message_receivers'].length; g++) {
      this.dataSource.push({
        'description': this.message['message_receivers'][g]['user']['name'],
        'read_at': this.message['message_receivers'][g]['read_at'],
        'status': '',
      });
    }

  }

  onDialogClose = () => {
    this.dialogRef.close();
  }
}
