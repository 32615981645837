import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*Laravel*/
import { environment } from '@env/environment';
import { HttpServiceUtil } from './http-service-util';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ImportService {
  url = environment.urlToApi;
  headersToAuth: HttpHeaders;

  constructor(private _http: HttpClient, private toastr: ToastrService) {
    this.setHeaders();
  }

  setHeaders() {
    const application = sessionStorage.getItem('application');
    const accessToken = sessionStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    if (application && JSON.parse(application) != null) {
      this.headersToAuth = headers
        .set('Authorization', String(accessToken))
        .set('event', String(JSON.parse(application).id));
    } else if (accessToken && JSON.parse(accessToken) != null) {
      this.headersToAuth = headers.set('Authorization', String(accessToken));
    } else {
      this.headersToAuth = headers;
    }
  }

  post = (route, data) =>
    new Promise((resolve, reject) => {
      this.setHeaders();

      this._http
        .post(this.url + route, data, { headers: this.headersToAuth })
        .subscribe(
          (res) => resolve(res),
          (rej: any) => {
            if (rej.status == 403)
              reject({ status: 'ERRO', message: 'Não autorizado.' });
            HttpServiceUtil.errorHandling(rej, this.toastr);
            reject(rej);
          }
        );
      // return this.http.put(contentItem._links.template.href, template._links.self.href, {headers: headers});
    });
}
