import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';

export class HttpServiceUtil {

  constructor(public toastr: ToastrService) { }

  static setHeadersWithAuth() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', sessionStorage.getItem('access_token'));
  }

  static setHeadersWithAuthAndEvent() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', sessionStorage.getItem('access_token'))
      .set('event', String(JSON.parse(sessionStorage.getItem('application'))?.id));
  }

  static setHeaders() {
    let headers: HttpHeaders;

    if (sessionStorage.getItem('access_token') && Boolean(sessionStorage.getItem('access_token'))) { //Um salve ao lib.dom.d.ts
      if (sessionStorage.getItem('application') && Boolean(sessionStorage.getItem('application'))) {
        headers = this.setHeadersWithAuthAndEvent();
      } else {
        headers = this.setHeadersWithAuth();
      }
    } else {
      headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    }

    const options = {
      headers: headers
    }

    return options;
  }

  static errorsHandler(errors: Array<any>, toastr: ToastrService) {
    errors = Object.values(errors);
    console.log(errors);

    for (let i = 0; i < errors.length; i++) {
      toastr.warning(errors[i]);
      console.log(errors[i]);
    }
  }

  static errorHandling(rej, toastr) {
    if (!rej || !toastr) return;
    if (environment.production === false) console.log('errorHandling > rej', rej);

    if (rej?.status === 404) {
      toastr.warning(rej.url.includes('download') ? "Arquivo não encontrado" : "Nenhum resultado encontrado");
    } else if (rej?.status === 0) {
      toastr.error("Não foi possível processar sua solicitação neste momento. O servidor não está respondendo. Por favor, tente novamente mais tarde.", "API inacessível", { progressBar: true, tapToDismiss: false, timeOut: 10000 });
    } else {
      if (rej?.error?.errors) {
        this.errorsHandler(rej.error.errors, toastr);
      } else {
        let title = '';
        if (rej.error?.code) {
          title += 'ERRO ' + rej.error?.code
        }
        toastr.error(rej.error?.message || rej.message || rej.exception, title, { progressBar: true, tapToDismiss: false, timeOut: 10000 });
      }
    }
  }

}
