import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/*Services*/
import { AuthenticationService } from '@services/laravel/authentication.service';
import { CrudService } from '@services/laravel/crud.service';
import { FeedService } from '@services/laravel/feed.service';
import { SnackBarService } from '@services/snackbar.service';
import { ValidateRequired } from '../../validators/required.validator';
import { AlertConfirmComponent } from '../alert-confirm/alert-confirm.component';
import { ToastrService } from 'ngx-toastr';

export interface Event {
  value: string;
  viewValue: string;
}

export interface User {
  id?: string;
  description?: string;
}

@Component({
  selector: 'ntm-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.css']
})
export class AddClassComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  options: User[] = [];

  isLoading = true;
  validForm = false;

  public userArray: any;
  public axisArray: any;
  public areasArray: any;
  public areasArrayFiltered: any;
  public coursesArray: any = [];
  public coursesArrayFiltered: any;

  application_id: any;
  msg;
  addCourseForm: UntypedFormGroup;
  addUsersForm: UntypedFormGroup;
  public classForm: UntypedFormGroup;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  public associatedUsers: any = [];

  public student_id: any;
  public schoolArray: any;
  public schoolArrayFiltered: any = [];
  public bkpSchoolArray: any;
  public drsArray: any;
  public bkpCourseArray: any;
  public courseArray: any = [];
  public cityArray: any = [];
  public cityArrayFiltered: any = [];
  public cityArrayFiltered2: any = [];
  public classesArray: any;
  public coursesStatusArray: any;
  public errorMessage: string = null;

  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<AddClassComponent>,
    private router: Router,
    private _crud: CrudService,
    private feedService: FeedService,
    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    public _snackbar: MatSnackBar,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.newForm();
    this.listCourseStatus();
    this.feedService.listDrs().then((res: any) => { this.drsArray = res; });
  }

  filterCity2(event){
    this.feedService.listCities(event.value).then((res: any) => {
      this.cityArrayFiltered2 = res;

      if (this.cityArrayFiltered2.length == 1) {
        this.classForm.controls['municipio'].setValue(this.cityArrayFiltered2[0].id);
        this.filterSchools(this.classForm.controls['municipio']);
      }
    });

    this.schoolArrayFiltered = [];
    this.courseArray = [];
    this.classesArray = [];
  }

  listCourseStatus = () => {
    this._crud.get('students-status').then(res => {
      this.coursesStatusArray = res;
    });
  }

  filterSchools = (event) => {
    this.feedService.listSchools(this.classForm.controls['regional_id'].value, event.value).then((res: any) => {

      if (res.length == 0) {
        this.errorMessage = 'Nenhuma escola disponível para este município';
      } else {
        this.errorMessage = null;
      }

      this.schoolArrayFiltered = res;

      if (res.length == 1) {
        this.classForm.controls['school_id'].setValue(res[0].id);
        this.filterCourses(this.classForm.controls['school_id']);
      }
    });

    this.courseArray = [];
    this.classesArray = [];
  }

  filterCourses = (event) => {
    this.toastr.clear();
    this.feedService.listCoursesSchools(event.value).then((res: any) => {
      this.courseArray = res;
      if (res.length == 0) {
        this.errorMessage = 'Nenhum curso disponível para esta escola';
      } else {
        this.errorMessage = null;
      }

      if (res.length == 1) {
        this.classForm.controls['course_id'].setValue(res[0].id);
        this.filterClasses(this.classForm.controls['course_id']);
      }
    });

    this.classesArray = [];
  }

  filterClasses = (event) => {
    this.classesArray = [];
    this.toastr.clear();

    this._crud.get('classes/school/'+this.classForm.controls['school_id'].value+'/course/'+this.classForm.controls['course_id'].value).then((res: any) => {
      if (res.length == 0) {
        this.errorMessage = 'Nenhuma turma disponível para este curso';
      } else {
        this.errorMessage = null;
      }

      this.classesArray = res;

      if (this.classesArray.length == 1) {
        this.classForm.controls['class_id'].setValue(this.classesArray[0].id);
      }
    });
  }

  formInit(){
    this.addCourseForm = new UntypedFormGroup({
      'axis_id': new UntypedFormControl(null),
      'area_id': new UntypedFormControl(null),
      'course_id': new UntypedFormControl(null, [Validators.required])
    });
  }

  newForm = () => {
    this.classForm = new UntypedFormGroup({
      'deleted_at': new UntypedFormControl(null),
      'municipio': new UntypedFormControl(null),
      'school_id': new UntypedFormControl(null, [ValidateRequired]),
      'course_id': new UntypedFormControl(null, [ValidateRequired]),
      'status_id': new UntypedFormControl(null, [ValidateRequired]),
      'class_id': new UntypedFormControl(null),
      'pronatec': new UntypedFormControl(null),
      'ebep': new UntypedFormControl(null),
      'ead': new UntypedFormControl(null),
      'student_id': new UntypedFormControl(this.student_id),
      'event_id': new UntypedFormControl(JSON.parse(sessionStorage.getItem('application')).id),
      'regional_id': new UntypedFormControl(null)
    });
  }

  filterArea(event){
    this.areasArrayFiltered = this.areasArray.filter(el => el.axis_id == event.value);
  }

  filterCourse(event){
    this.coursesArrayFiltered = this.coursesArray.filter(el => el.area_id == event.value);
  }

  displayFn(user?: User): string | undefined {
    return user ? user.description : undefined;
  }

  private _filter(description: string): User[] {
    const filterValue = description.toLowerCase();

    return this.options.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0);
  }

  close = () => {
    this.dialogRef.close();
  }

  addUser(){
    let user = this.myControl.value;
    this.myControl = new UntypedFormControl();

    this.addUsersForm.value["user_id"].push({id: user.id});
    this.associatedUsers.push({id: user.id, description: user.description});

    this.options = [];

    this.formInit();

    this.validForm = true;
  }

  removeUser(user){
    let user_id = user.id;
    let index = this.addUsersForm.value["user_id"].indexOf(user);
    let index2 = this.associatedUsers.indexOf(user);
    this.addUsersForm.value["user_id"].splice(index, 1);
    this.associatedUsers.splice(index2, 1);
  }

  addClass = () => {
    if(this.classForm.value['ebep'] != true){
      this.classForm.value['ebep'] = 0;
    }

    if(this.classForm.value['ead'] != true){
      this.classForm.value['ead'] = 0;
    }

    if(this.classForm.value['pronatec'] != true){
      this.classForm.value['pronatec'] = 0;
    }

    this._crud.post('relate-student', {
        ...this.classForm.value,
        status_id_import: this.classForm.get('status_id')?.value
      })
      .then(res => {
        this.toastr.success('Turma relacionada com Sucesso!', 'Sucesso!');
        this.dialogRef.close(res);
      });
  }
}
