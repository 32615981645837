<div id="close">
  <a (click)="dialogRef.close()">
    <mat-icon class="mat-18">close</mat-icon>
  </a>
</div>

<h4 mat-dialog-title>Alterar senha</h4>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="resetPassForm">

    <div class="form-pattern">
      <mat-icon class="mat-18 icon-pattern">lock_open</mat-icon>

      <mat-form-field>
        <input matInput formControlName="newPassword"
        type="password" placeholder="Nova Senha"
        required (focusout)="onPasswordChange()">
      </mat-form-field>
      <mat-error *ngIf="resetPassForm.get('newPassword').errors && resetPassForm.get('newPassword').touched">
          {{resetPassForm.get('newPassword').errors['message']}}
        </mat-error>
    </div>

    <div class="form-pattern">
      <mat-icon class="mat-18 icon-pattern">lock_open</mat-icon>

      <mat-form-field>
        <input matInput formControlName="repeatPassword"
        type="password" placeholder="Confirmar nova senha"
        required>
        <mat-error *ngIf="resetPassForm.get('repeatPassword').errors && resetPassForm.get('repeatPassword').touched">
          {{resetPassForm.get('repeatPassword').errors['message']}}
        </mat-error>
      </mat-form-field>
    </div>

  </form>

</mat-dialog-content>


<div class="modal-footer">

  <button id="modalApplyBtn" mat-raised-button type="button" color="accent" [disabled]="resetPassForm.invalid" (click)="onResetPassSubmit()">Atualizar</button>

</div>
