import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/*Services*/
import { CrudService } from '@services/laravel/crud.service';
import { ValidateRequired } from '@shared/validators/required.validator';

export interface Event {
  id?: string;
  description?: string;
}

export class Constantes {
  static readonly PROFILE_TITLE: string = 'Perfil';
  static readonly USER_TITLE: string = 'Usuário';
}

@Component({
  selector: 'app-add-recipients',
  templateUrl: './add-recipients.component.html',
  styleUrls: ['./add-recipients.component.css']
})
export class AddRecipientsComponent implements OnInit {
  public loading = true;
  public users = [];
  public profiles = [];
  public drs = [];
  public courses = [];
  public schools = [];
  public form: UntypedFormGroup;
  public myControl = new UntypedFormControl();
  public filteredOptions: Observable<any[]>;
  public type: '1' | '2' = '1';

  constructor(
    public dialogRef: MatDialogRef<AddRecipientsComponent>,
    private service: CrudService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = new UntypedFormGroup({
      'course_id': new UntypedFormControl({ value: '', disabled: true }),
      'profile_id': new UntypedFormControl('', [ValidateRequired]),
      'regional_id': new UntypedFormControl({ value: '', disabled: true }),
      'school_id': new UntypedFormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.listProfiles().then((res: any) => {
      this.profiles = res.data;
      this.uniqueValue('profile_id', this.profiles);
      this.listDrs().then((response: any) => {
        this.drs = response.data;
        this.uniqueValue('regional_id', this.drs);
        this.listUsers();
      }).catch(err => this.loading = false);
    }).catch(err => this.loading = false);
  }

  resetForm() {
    this.form.reset();
    this.myControl.reset();
  }

  generateForm(event) {
    if (event.value === '1') {
      this.myControl.setValidators(null);
      this.myControl.updateValueAndValidity();
      this.form.get('profile_id').setValidators([ValidateRequired]);
      this.form.get('profile_id').updateValueAndValidity();
    }

    if (event.value === '2') {
      this.myControl.setValidators([ValidateRequired]);
      this.form.get('profile_id').setValidators(null);
      this.form.get('profile_id').updateValueAndValidity();
      this.form.get('profile_id').reset();
      ['course_id', 'regional_id', 'school_id'].forEach(el => {
        this.form.get(el).reset();
        this.form.get(el).disable();
      });
    }
  }

  listProfiles = () => {
    return this.service.get('messages/profiles');
  }

  listUsers = () => {
    this.service.get('users/by-event')
      .then((res: any) => {
        this.users = res;

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        this.loading = false;
      }).catch(err => this.loading = false);
  }

  listCourse() {
    this.service.get('public/courses?noPaginate=true&order=description,asc&noRelationship=true').then((response: any) => {
      this.courses = response.data || response;
      this.uniqueValue('course_id', this.courses);
    });
  }

  listDrs = () => {
    return this.service.get('regional?noPaginate=true&order=description,asc');
  }

  private _filter(value: any) {
    if (value === null) { value = ''; }
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();

    return this.users.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(item?: any) {
    return item ? item.name : undefined;
  }

  close = () => {
    this.dialogRef.close();
  }

  addRecipient = () => {
    const profile_id = this.form.value.profile_id ? this.form.value.profile_id.id : null;
    const regional = this.drs.find(el => el.id === +this.form.value.regional_id);
    const school = this.schools.find(el => el.id === +this.form.value.school_id);
    const course = this.courses.find(el => el.id === +this.form.value.course_id);
    const profile = this.profiles.find(el => el.id === +profile_id);
    this.form.get('profile_id').setValue(profile_id);
    const obj = {
      access_profile_user_id: this.myControl.value ? this.myControl.value.id : null,
      description: this.myControl.value ? this.myControl.value.name : profile.description,
      regional: regional ? regional.description : null,
      school: school ? school.description : null,
      course: course ? course.description : null
    };
    this.dialogRef.close({ ...obj, ...this.form.value, type: this.type });
  }

  filterSchool(event) {
    ['school_id', 'course_id'].forEach(el => { this.form.get(el).reset(); });
    this.service.get('school/with-students?show=id,description&order=description,asc&where=regional_id,' + event.value + '&noPaginate=true')
      .then((res: any) => {
        this.schools = res.data || res;
        this.uniqueValue('school_id', this.schools);
      });
  }

  filterCourse(event) {
    ['course_id'].forEach(el => { this.form.get(el).reset(); });
    this.service.get('public/course-with-students?where=school_id,' + event.value + '&noPaginate=true&noRelationship=true')
      .then((res: any) => {
        this.courses = res.data || res;
        this.courses.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
        this.uniqueValue('course_id', this.courses);
      });
  }

  verifyRequeriments(event) {
    ['regional_id', 'school_id', 'course_id'].forEach(el => {
      if (this.form.get(el).value !== '') { this.form.get(el).reset(); }
      this.form.get(el).disable();
    });

    if (event.value.require_regional) {
      this.form.get('regional_id').enable();
    }

    if (event.value.require_school) {
      this.form.get('school_id').enable();
    }

    if (event.value.require_course) {
      this.form.get('course_id').enable();
      this.listCourse();
    }
  }

  uniqueValue(attr, data) {
    if (data.length === 1) {
      const value = data[0].id;
      this.form.get(attr).setValue(value);
    }
  }
}
