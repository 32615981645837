<div *ngIf="alert_type_1" id="close">
  <a (click)="dialogRef.close()">
    <mat-icon class="icon-pattern">close</mat-icon>
  </a>
</div>

<h4 *ngIf="alert_type_1" mat-dialog-title [style.background-color]="color" class="title">{{status}}</h4>

<p class="message2 primary-text" *ngIf="alert_type_1">{{mensagem}}</p>
<mat-checkbox *ngIf="alert_type_2" checked="true" disabled="true"><br><br>
<p class="message primary-text" *ngIf="alert_type_2">{{mensagem}}</p>