import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { saveAs } from 'file-saver';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnChanges {
  @Input() isParent;
  @Input() showSender;
  @Input() recipients;
  @Input() message;
  @Output() openRecipientList: EventEmitter<any> = new EventEmitter();
  public storageRoute: any = 'storage/message-attachments';
  displayedColumns: string[] = ['description', 'actions'];
  constructor(private _crud: CrudService, public toastr: ToastrService, ) { }

  ngOnChanges() {

  }

  downloadFile(attachment) {
    attachment.downloading = true;
    this._crud.downloadFileFromUrl(this.storageRoute + '/' + attachment.hashname, attachment.filename).then(response => {
      saveAs(response, attachment.filename);
      attachment.downloading = false;
    }).catch(err => {
      attachment.downloading = false;
      this.toastr.error('Erro ao baixar anexo.', 'Erro!');
    });
  }

  viewRecipientList() {
    this.openRecipientList.emit();
  }
}
