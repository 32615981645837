import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ValidateFields } from '@shared/validators/required.validator';

interface Student {
  appointment_id: number;
  exam_status_id: number;
  exams_generated_id: number;
}
interface Item {
  name: string;
  cpf_number: string;
}
interface Data {
  id: number;
  ids: any;
  title: string;
  student: Student;
  itens: Item[];
  action: number;
}

export interface Reason {
  id?: string;
  description?: string;
}

@Component({
  selector: 'app-homologation',
  templateUrl: './homologation.component.html',
  styleUrls: ['./homologation.component.css']
})
export class HomologationComponent implements OnInit {

  public dialogMessage = 'Você deseja mesmo homologar este(s) aluno(s)?';
  public title = 'HOMOLOGAR';
  public justificativas = [];
  public homologForm: UntypedFormGroup;
  public reasonForm: UntypedFormGroup;

  optionsReason: Reason[] = [];
  myReasonControl = new UntypedFormControl();
  filteredOptionsReason: Observable<Reason[]>;

  public reasonArray: any;
  public reasonArrayFiltered: any = [];

  constructor(
    public dialogRef: MatDialogRef<HomologationComponent>,
    private crud: CrudService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    this.homologForm = new UntypedFormGroup({
      'ids': new UntypedFormControl([]),
      'homologated': new UntypedFormControl(this.data.action),
      'exam_status_id': new UntypedFormControl(this.data.student.exam_status_id),
      'reason_not_homologated_id': new UntypedFormControl(null, [ValidateRequired])
    });

    this.loadReasons();
    this.homologForm = (new ValidateFields).thisUpdateValidators(this.homologForm);
  }

  ngOnInit() {
    if (this.data.action == 2) {
      this.title = 'NÃO HOMOLOGAR';
    }

    if (!this.data.action) {
      this.dialogMessage = 'Você deseja mesmo remover ' + this.data.title + ' deste aluno?';
    }
  }

  close() {
    this.dialogRef.close();
  }

  homologation() {

    if (!this.data.id) { return this.loteHomologation(); }

    const obj = {
      appointment_id: this.data.student.appointment_id,
      exam_status_id: this.data.student.exam_status_id,
      exams_generated_id: this.data.student.exams_generated_id
    };

    this.crud.patch('homologation/' + this.data.id, Object.assign({}, this.homologForm.value, obj))
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
      }).catch((err: any) => {
        this.toastr.warning('Ação não realizada.', 'Atenção!');
      });
  }

  loteHomologation() {
    this.homologForm.controls['ids'].setValue(this.data.ids);
    this.crud.post('homologation', this.homologForm.value)
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
      });
  }

  loadReasons() {
    this.optionsReason = [];

    this.crud.get('reason-not-homologated?order=description,asc')
      .then((res: any) => {
        this.justificativas = res.data || res;
        this.reasonArray = res.data || res;
        this.reasonArrayFiltered = res.data || res;

        for (let i = 0; i < this.reasonArray.length; i++) {
          const reason: Reason = { id: this.reasonArray[i].id.toString(), description: this.reasonArray[i].description };

          this.optionsReason.push(reason);
        }

        this.filteredOptionsReason = this.myReasonControl.valueChanges
          .pipe(
            startWith<string | Reason>(''),
            map(value => typeof value === 'string' ? value : value.description),
            map(name => name ? this._filterReason(name) : this.optionsReason.slice())
          );
      });
  }

  displayFnReason(reason?: Reason): string | undefined {
    return reason ? reason.description : undefined;
  }

  private _filterReason(description: string): Reason[] {
    const filterValueReason = description.toLowerCase();

    if (this.optionsReason.filter(option => option.description.toLowerCase().indexOf(filterValueReason) === 0).length > 0) {
      return this.optionsReason.filter(option => option.description.toLowerCase().indexOf(filterValueReason) === 0);
    } else {
      return [{ 'id': description, 'description': description + ' não é uma atividade válida. Deseja Cadastrar?' }];
    }
  }

  validControl() {
    this.homologForm.get('reason_not_homologated_id').markAsTouched();

    if (typeof this.myReasonControl.value == 'object') {
      if (this.myReasonControl.value.description.indexOf('Cadastrar') == -1) {
        this.homologForm.get('reason_not_homologated_id').setValue(this.myReasonControl.value.id);
      } else {
        this.onAddNewValue();
      }
    } else {
      this.homologForm.get('reason_not_homologated_id').setValue(null);
    }
  }

  onAddNewValue() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      height: '250px',
      width: 'auto'
    });

    dialogRef.componentInstance.dialogTitle = 'Confirmação';
    dialogRef.componentInstance.dialogMessage = 'Deseja cadastrar o motivo <i class=\'focus\'>'
      + this.myReasonControl.value.id
      + '</i>?';

    dialogRef.afterClosed().subscribe(result => {
      this.reasonForm = new UntypedFormGroup({
        'description': new UntypedFormControl(this.myReasonControl.value.id, [ValidateRequired])
      });

      if (result) {
        this.crud.post('reasons-not-homologated', this.reasonForm.value)
          .then(res => {
            this.loadReasons();

            this.toastr.success('Motivo cadastrado com Sucesso!', 'Sucesso!');
          });
      } else {
        this.myReasonControl.setValue('');
      }
    });
  }
}
