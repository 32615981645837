<app-modal-header [hasClose]='true' [title]="'RELACIONAR CURSO'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <form [formGroup]="addCourseForm">
    <div id="text" class="primary-text">
      Selecione o Curso e clique em RELACIONAR
    </div>

    <div class="flex">
      <mat-form-field class="half-input">
        <mat-select matSelect formControlName="course_id" placeholder="Curso" required>
          <mat-option *ngFor="let course of coursesArrayFiltered" [value]="course.id">
            {{course.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addCourseForm.get('course_id').errors && addCourseForm.get('course_id').touched">
          {{addCourseForm.get('course_id').errors['message']}}</mat-error>
      </mat-form-field>
    </div>
  </form>

</mat-dialog-content>

<div class="modal-footer">
  <button id="modalApplyBtn" type="button" mat-raised-button color="accent" [disabled]="!addCourseForm.valid" (click)="addCourse()">
    Relacionar
  </button>
</div>
