import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class MatrixService { 
  url = environment.urlToSisbiaApi;
  sisbiaKey = environment.sisbiaKey;
  headersToAuth: HttpHeaders;

  constructor(private http: HttpClient) {
    this.setHeaders();
  }

  setHeaders(){
    if(sessionStorage.getItem('application') && JSON.parse(sessionStorage.getItem('application')) != null){
      this.headersToAuth = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', String(sessionStorage.getItem('access_token')))
      .set('event', String(JSON.parse(sessionStorage.getItem('application')).id));
    } else if(sessionStorage.getItem('access_token') && JSON.parse(sessionStorage.getItem('access_token')) != null){
      this.headersToAuth = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', String(sessionStorage.getItem('access_token')));
    } else {
      this.headersToAuth = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
    }
  }

  get = (route) => new Promise((resolve, reject) => {
    this.setHeaders();

    this.http.get(this.url + route + '?' + this.sisbiaKey, { 'headers': this.headersToAuth })
    .subscribe(res => resolve(res), (rej: any) => reject(rej));
  });

  post = (route, data) => new Promise((resolve, reject) => {
    this.setHeaders();

    this.http.post(this.url + route + '?' + this.sisbiaKey, data, { 'headers': this.headersToAuth })
    .subscribe(res => resolve(res), (rej: any) => reject(rej));
  });
}
