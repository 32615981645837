<app-modal-header [hasClose]='true' [title]="'IMPORTAR USUÁRIOS'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">
  <form [formGroup]="importUsersForm" *ngIf="!isLoading">
    <div id="text" class="primary-text">
      Você deseja fazer a importação de usuários com seus devidos perfis, escolas, cursos de qual aplicação anterior?
    </div>

    <mat-form-field class="half-input">
      <input type="text" formControlName="event" placeholder="Aplicações" aria-label="Aplicações" matInput [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" matSelect placeholder="Escolha o Usuário">
        <mat-option *ngFor="let event of filteredOptions | async" [value]="event">
          {{event.description}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="profiles-check-area-container">
      <div class="profiles-check-area" *ngFor="let profile of profileArray">
          <mat-checkbox class="profile-list-item" #profileCheckbox [value]="profile.id">{{profile.description}}</mat-checkbox>
      </div>
    </div>

  </form>

  <div *ngIf="isLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>

<div class="modal-footer" *ngIf="!isLoading">
  <!-- <button type="button" mat-raised-button (click)="close()" class="far-left">
    Cancelar
  </button> -->
  <button id="modalApplyBtn" type="submit" mat-raised-button color="accent" [disabled]="setValidationForm()"
    class="far-left " (click)="importUsersFromEvent()">
    Importar usuários
  </button>
</div>
